import { API_BULK_CUSTOMER_VALIDATE } from "../../../api/customer.services";
import CSVupload from "../../../components/CSVupload";
import { customerDefaultColumns } from "../../../utils/csvUtils";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { bulkAddCustomers } from "../../../reducers/customerTableSlice";
import { ReactComponent as ExcelIcon } from "../../../assets/svgIcons/excelIcon.svg";
import { CustomButton } from "../../../components/CustomButton";
import { removePlusFormPhoneNumber } from "../../../utils/commonUtils";
function ImportCustomersFromSheet({ toast = null }) {
  const [newCustomerList, setNewCustomerList] = useState([]);
  const [visiable, setVisiable] = useState(false);

  const dispatch = useDispatch();

  const handleValidateData = async () => {
    const configData = {
      customers: newCustomerList?.map((item) => {
        return { ...item, phone: removePlusFormPhoneNumber(item.phone) };
      }),
    };
    try {
      let { data } = await API_BULK_CUSTOMER_VALIDATE(configData);
      const { newCustomers, noChangedCustomers, changedCustomers } = data;

      return {
        new: newCustomers?.length,
        update: noChangedCustomers?.length + changedCustomers?.length,
      };
    } catch (error) {
      throw error;
    }
  };
  function refreshPage() {
    setTimeout(() => {
      window.location.reload(false);
    }, 500);
  }

  const handleSaveNewContacts = () => {
    const configData = {
      customers: newCustomerList?.map((item) => {
        return { ...item, phone: removePlusFormPhoneNumber(item.phone) };
      }),
    };
    dispatch(bulkAddCustomers(configData))
      .unwrap()
      .then((res) => {
        handleHide();
        /// let Message_Success = Messag.Add_Cust_ToastSuccessMessage;
        toast.current.show({
          severity: "success",
          detail: `${newCustomerList.length} Customers got added success.`,
        });
        setNewCustomerList([]);
        refreshPage();
      })
      .catch((err) => {
        toast.current.show({ severity: "error", detail: err?.message });
      });
  };

  const handleHide = () => {
    setVisiable((prev) => !prev);
  };
  return (
    <>
      {/* {isValidating && <Loader visible={isValidating} />} */}
      <CustomButton
        varient="icon-button"
        onClick={handleHide}
        icon={<ExcelIcon />}
      />

      {visiable && (
        <CSVupload
          showDialog={visiable}
          hideDialog={handleHide}
          toast={toast}
          headerTitle="Import customers from excel"
          targetColumns={customerDefaultColumns}
          validateData={handleValidateData}
          dataToAdd={newCustomerList}
          handleDataChange={(value) => setNewCustomerList(value)}
          handleSaveData={handleSaveNewContacts}
          sampleFileUrl="/assets/sample_contact.xlsx"
        />
      )}
    </>
  );
}

export default ImportCustomersFromSheet;
