import { Controller } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { useUtility } from "../../../context/UtilityContext";
function CustomDropdown({
  label = "",
  control = null,
  placeholder = "",
  name = "",
  rules = {},
  optionLabel = "",
  optionValue = "",
  options = [],
  disabled=false,
  isMandatory = false,
  showClear = true,
  onChangealert=false,
}) {
  const { setAlert }=useUtility()

  return (
    <div className="flex flex-column gap-2 flex-grow-1">
      <label htmlFor="categoryId">
        {label} {isMandatory && <span>*</span>}
      </label>
      <Controller
        name={name}
        control={control}
        rules={{ required: isMandatory && `${label} is mandatory to select` }}
        render={({ field, fieldState }) => (
          <Dropdown
            id={field.name}
            options={options}
            value={field.value}
            onChange={(e) => {onChangealert&&setAlert(true);field.onChange(e.value)}}
            optionLabel={optionLabel}
            optionValue={optionValue}
            placeholder={placeholder}
            disabled={disabled}
            className={classNames({ "p-invalid": fieldState.invalid })}
            showClear={field.value && showClear}
          />
        )}
      />
    </div>
  );
}

export default CustomDropdown;
