import { useEffect } from "react";
import "./App.css";
import "./global/table.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { Login } from "./view/Login";
import { ResetPass } from "./view/ResetPass";
import { Home } from "./view/Home";
import { Dashboard } from "./view/Dashboard";
import { ProtectedRoute } from "./components/ProtectedRoutes";
import { useDispatch, useSelector } from "react-redux";
import { roleAccess, setUser, setUserSub } from "./reducers/authSlice";
import ProductList from "./view/Products/ProductList";
import Orders from "./view/Orders";
import Enquiries from "./view/Enquiries";
import CategoryList from "./view/Categories/CategoryList";
import CustomerList from "./view/Customer/CustomerList";
import UserProfile from "./view/UserProfile";
import PaymentHistory from "./view/PaymentHistory";
import Campaigns from "./view/Campaigns";
import ScheduleCampaigns from "./view/ScheduleCampaigns";
import parseJwt from "./utils/authUtils";
import SignUp from "./view/SignUp";
import MessageTemplates from "./view/MessageTemplates";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import CampaignDetails from "./view/CampaignDetails";
import RunCampaigns from "./view/RunCampaigns";
import { CampaignCustomer } from "./view/RunCampaigns/Customers";
import ScheduleCamp from "./view/RunCampaigns/ScheduleCamp";
import { ProductSelect } from "./view/RunCampaigns/ProductSelect";
import { SelectTemplate } from "./view/RunCampaigns/SelectTemplate";
import AccountSettings from "./view/AccountSettings";
import User from "./view/AccountSettings/User";
import NewProduct from "./view/Products/NewProduct";
import Products from "./view/Products";
import Categories from "./view/Categories";
import NewCategory from "./view/Categories/NewCategory";
import NewTemplate from "./view/MessageTemplates/NewTemplate";
import TemplateList from "./view/MessageTemplates/TemplateList";
import ChatEnq from "./view/ChatEnq/Index";
import NewCustomer from "./view/Customer/NewCustomer";
import Customer from "./view/Customer";
import NewGroup from "./view/Customer/NewGroup";
import RoleBasedRoute from "./components/ProtectedRoutes/RoleBasedRoute";
import Unauthorized from "./view/Unauthorized";
import GroupList from "./view/Customer/GroupList";
import axiosInstance from "./api/axios.instance";
import Recharge from "./view/Recharge";
import QuickNoteForm from "./view/AccountSettings/TextAutomation/QuickNoteForm";
import Tickets from "./view/Tickets";
import ImageInventory from "./view/ImageInventory";
import PageNotFound from "./view/PageNotFound";
import permissions from "./roles/permissions";
import modulesName from "./roles/modulesName";
import OrderDetails from "./view/Orders/OrderDetails";

import Bots from "./view/Bots";
import BotBuilder from "./view/Bots/BotBuilder";
import BotList from "./view/Bots/BotList";
import { subscription } from "./reducers/authSlice";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const auth = getAuth();
  const { user } = useSelector((state) => state.authenticate);

  const token = localStorage.getItem("token");
  useEffect(async () => {
    const currentPath = location.pathname;
    const userSubscription = JSON.parse(localStorage.getItem("userSub"));
    if (userSubscription) {
      dispatch(
        setUserSub({
          subscription: userSubscription,
        })
      );
    } else {
      if (user) dispatch(subscription(user?.companyId));
    }
    if (token) {
      const user = parseJwt(token);

      dispatch(setUser(user));

      dispatch(roleAccess())
        .unwrap()
        .then((res) => {
          let accesskey = res.roleDefinition;

          if (
            currentPath == "/" &&
            accesskey[modulesName.DASHBOARD]?.includes(permissions.READ)
          ) {
            navigate("/dashboard");
          } else if (
            currentPath == "/" &&
            accesskey[modulesName.SALES]?.includes(permissions.READ)
          ) {
            navigate("/chat");
          } else {
            navigate(currentPath);
          }
        });

      const resp = await axiosInstance.get("/firebase/token");
      const firebaseToken = resp.data.token;
      await signInWithCustomToken(auth, firebaseToken)
        .then((userCredential) => {
          // const user = userCredential?.user;
        })
        .catch((error) => {
          console.log("Error signing in with custom token: ", error);
        });
    }
  }, [token]);

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        >
          <Route
            path="dashboard"
            element={
              <RoleBasedRoute location={"dashboard"} permission={"read"}>
                <Dashboard />
              </RoleBasedRoute>
            }
          />
          <Route
            path="campaigns"
            element={
              <RoleBasedRoute location={"campaign"} permission={"read"}>
                <Campaigns />
              </RoleBasedRoute>
            }
          >
            <Route
              path=":id"
              element={
                <RoleBasedRoute location={"campaign"} permission={"read"}>
                  <CampaignDetails />
                </RoleBasedRoute>
              }
            ></Route>
          </Route>
          <Route
            path="schedule"
            element={
              <RoleBasedRoute location={"campaign"} permission={"read"}>
                <ScheduleCampaigns />
              </RoleBasedRoute>
            }
          />
          <Route
            path="chat"
            element={
              <RoleBasedRoute location={"sales"} permission={"read"}>
                <ChatEnq />
              </RoleBasedRoute>
            }
          />

          <Route
            path="chat/:mob"
            element={
              <RoleBasedRoute location={"sales"} permission={"read"}>
                <ChatEnq />
              </RoleBasedRoute>
            }
          />

          <Route path="products" element={<Products />}>
            <Route
              path="list"
              element={
                <RoleBasedRoute location={"product"} permission={"read"}>
                  <ProductList />
                </RoleBasedRoute>
              }
            />
            <Route
              path="new"
              element={
                <RoleBasedRoute location={"product"} permission={"create"}>
                  <NewProduct />
                </RoleBasedRoute>
              }
            />
            <Route
              path=":id/update"
              element={
                <RoleBasedRoute location={"product"} permission={"update"}>
                  <NewProduct />
                </RoleBasedRoute>
              }
            />
          </Route>
          <Route
            path="orders"
            element={
              <RoleBasedRoute location={"order"} permission={"read"}>
                <Orders />
              </RoleBasedRoute>
            }
          >
            <Route
              path=":id"
              element={
                <RoleBasedRoute location={"order"} permission={"update"}>
                  <OrderDetails />
                </RoleBasedRoute>
              }
            />
          </Route>

          <Route
            path="mediaInventory"
            element={
              <RoleBasedRoute location={"product"} permission={"read"}>
                <ImageInventory />
              </RoleBasedRoute>
            }
          />
          <Route
            path="ticket"
            element={
              <RoleBasedRoute location={"ticket"} permission={"read"}>
                <Tickets />
              </RoleBasedRoute>
            }
          />

          <Route
            path="runcampaign/new"
            element={
              <RoleBasedRoute location={"campaign"} permission={"create"}>
                <SelectTemplate />
              </RoleBasedRoute>
            }
          />
          <Route
            path="runcampaign/selecttemplate/new"
            element={
              <RoleBasedRoute location={"campaign"} permission={"create"}>
                <RunCampaigns />
              </RoleBasedRoute>
            }
          />
          <Route
            path="runcampaign/selectproducts/new"
            element={
              <RoleBasedRoute location={"campaign"} permission={"create"}>
                <ProductSelect />
              </RoleBasedRoute>
            }
          />
          <Route
            path="runcampaign/selectcustomer/new"
            element={
              <RoleBasedRoute location={"campaign"} permission={"create"}>
                <CampaignCustomer />
              </RoleBasedRoute>
            }
          />
          <Route
            path="runcampaign/schedule/new"
            element={
              <RoleBasedRoute location={"campaign"} permission={"create"}>
                <ScheduleCamp />
              </RoleBasedRoute>
            }
          />
          <Route
            path="runcampaign/:id"
            element={
              <RoleBasedRoute location={"campaign"} permission={"create"}>
                <SelectTemplate />
              </RoleBasedRoute>
            }
          />
          <Route
            path="runcampaign/selecttemplate/:id"
            element={
              <RoleBasedRoute location={"campaign"} permission={"create"}>
                <RunCampaigns />
              </RoleBasedRoute>
            }
          />
          <Route
            path="runcampaign/selectproducts/:id"
            element={
              <RoleBasedRoute location={"campaign"} permission={"create"}>
                <ProductSelect />
              </RoleBasedRoute>
            }
          />
          <Route
            path="runcampaign/selectcustomer/:id"
            element={
              <RoleBasedRoute location={"campaign"} permission={"create"}>
                <CampaignCustomer />
              </RoleBasedRoute>
            }
          />
          <Route
            path="runcampaign/schedule/:id"
            element={
              <RoleBasedRoute location={"campaign"} permission={"create"}>
                <ScheduleCamp />
              </RoleBasedRoute>
            }
          />
          <Route
            path="runcampaign/:id/:mode"
            element={
              <RoleBasedRoute location={"campaign"} permission={"create"}>
                <SelectTemplate />
              </RoleBasedRoute>
            }
          />
          <Route
            path="runcampaign/selecttemplate/:id/:mode"
            element={
              <RoleBasedRoute location={"campaign"} permission={"create"}>
                <RunCampaigns />
              </RoleBasedRoute>
            }
          />
          <Route
            path="runcampaign/selectproducts/:id/:mode"
            element={
              <RoleBasedRoute location={"campaign"} permission={"create"}>
                <ProductSelect />
              </RoleBasedRoute>
            }
          />
          <Route
            path="runcampaign/selectcustomer/:id/:mode"
            element={
              <RoleBasedRoute location={"campaign"} permission={"create"}>
                <CampaignCustomer />
              </RoleBasedRoute>
            }
          />
          <Route
            path="runcampaign/schedule/:id"
            element={
              <RoleBasedRoute location={"campaign"} permission={"create"}>
                <ScheduleCamp />
              </RoleBasedRoute>
            }
          />
          <Route
            path="enquries"
            element={
              <RoleBasedRoute location={"enquires"} permission={"read"}>
                <Enquiries />
              </RoleBasedRoute>
            }
          />
          <Route
            path="categories"
            element={
              <RoleBasedRoute location={"category"} permission={"read"}>
                <Categories />
              </RoleBasedRoute>
            }
          >
            <Route
              path="list"
              element={
                <RoleBasedRoute location={"category"} permission={"read"}>
                  <CategoryList />
                </RoleBasedRoute>
              }
            />
            <Route
              path="new"
              element={
                <RoleBasedRoute location={"category"} permission={"create"}>
                  <NewCategory />
                </RoleBasedRoute>
              }
            />
            <Route
              path=":id/update"
              element={
                <RoleBasedRoute location={"category"} permission={"update"}>
                  <NewCategory />
                </RoleBasedRoute>
              }
            />
          </Route>

          <Route
            path="userprofile"
            element={
              <RoleBasedRoute location={"user"} permission={"read"}>
                <UserProfile />
              </RoleBasedRoute>
            }
          />
          <Route
            path="paymenthistory"
            element={
              <RoleBasedRoute location={"payment"} permission={"read"}>
                <PaymentHistory />
              </RoleBasedRoute>
            }
          />
          <Route
            path="account"
            element={
              <RoleBasedRoute location={"account"} permission={"read"}>
                <AccountSettings />
              </RoleBasedRoute>
            }
          />
          <Route
            path="account/new/:id"
            element={
              <RoleBasedRoute location={"account"} permission={"read"}>
                <User />
              </RoleBasedRoute>
            }
          />
          <Route
            path="account/:id"
            element={
              <RoleBasedRoute location={"account"} permission={"read"}>
                <User />
              </RoleBasedRoute>
            }
          />
          <Route
            path="account/quick-notes/new"
            element={
              <RoleBasedRoute location={"textAutomation"} permission={"read"}>
                <QuickNoteForm />
              </RoleBasedRoute>
            }
          />
          <Route
            path="account/quick-notes/:id"
            element={
              <RoleBasedRoute location={"textAutomation"} permission={"read"}>
                <QuickNoteForm />
              </RoleBasedRoute>
            }
          />
          <Route
            path="customers"
            element={
              <RoleBasedRoute location={"customer"} permission={"read"}>
                <Customer />
              </RoleBasedRoute>
            }
          >
            <Route
              path="list"
              element={
                <RoleBasedRoute location={"customer"} permission={"read"}>
                  <CustomerList />
                </RoleBasedRoute>
              }
            />
            <Route
              path="new"
              element={
                <RoleBasedRoute location={"customer"} permission={"create"}>
                  <NewCustomer />
                </RoleBasedRoute>
              }
            />
            <Route
              path=":id/update"
              element={
                <RoleBasedRoute location={"customer"} permission={"update"}>
                  <NewCustomer />
                </RoleBasedRoute>
              }
            />
            <Route
              path="gnew"
              element={
                <RoleBasedRoute
                  location={"customerGroup"}
                  permission={"create"}
                >
                  <NewGroup />
                </RoleBasedRoute>
              }
            />
            <Route
              path=":id/gupdate"
              element={
                <RoleBasedRoute
                  location={"customerGroup"}
                  permission={"update"}
                >
                  <NewGroup />
                </RoleBasedRoute>
              }
            />
          </Route>
          <Route
            path="customers/groups"
            element={
              <RoleBasedRoute location={"customerGroup"} permission={"read"}>
                <GroupList />
              </RoleBasedRoute>
            }
          />
          <Route
            path="customers/groups/:id"
            element={
              <RoleBasedRoute location={"customerGroup"} permission={"read"}>
                <GroupList />
              </RoleBasedRoute>
            }
          />

          <Route
            path="campaign-templates"
            element={
              <RoleBasedRoute location={"template"} permission={"read"}>
                <MessageTemplates />
              </RoleBasedRoute>
            }
          >
            <Route
              path="list"
              element={
                <RoleBasedRoute location={"template"} permission={"read"}>
                  <TemplateList />
                </RoleBasedRoute>
              }
            />
            <Route
              path="new"
              element={
                <RoleBasedRoute location={"template"} permission={"create"}>
                  <NewTemplate />
                </RoleBasedRoute>
              }
            />
            <Route
              path=":id/update"
              element={
                <RoleBasedRoute location={"template"} permission={"update"}>
                  <NewTemplate />
                </RoleBasedRoute>
              }
            />
            <Route
              path=":id/clone"
              element={
                <RoleBasedRoute location={"template"} permission={"create"}>
                  <NewTemplate />
                </RoleBasedRoute>
              }
            />
          </Route>
          <Route
            path="dashboard/recharge"
            element={
              <RoleBasedRoute location={"payment"} permission={"create"}>
                <Recharge />
              </RoleBasedRoute>
            }
          />
          <Route path="bots" element={<Bots />}>
            <Route
              path="list"
              element={
                <RoleBasedRoute location={"template"} permission={"read"}>
                  <BotList />
                </RoleBasedRoute>
              }
            />
            <Route
              path="new"
              element={
                <RoleBasedRoute location={"template"} permission={"create"}>
                  <BotBuilder />
                </RoleBasedRoute>
              }
            />
            <Route
              path=":id/update"
              element={
                <RoleBasedRoute location={"template"} permission={"update"}>
                  <BotBuilder />
                </RoleBasedRoute>
              }
            />
          </Route>
          <Route
            path="/dashboard/:transactionid"
            element={
              <RoleBasedRoute location={"dashboard"} permission={"read"}>
                <Dashboard />
              </RoleBasedRoute>
            }
          />
        </Route>
        <Route index path="login" element={<Login />} />
        <Route index path="signup" element={<Login />} />
        {/* <Route index path="signup" element={<SignUp />} /> */}
        <Route path="/resetpass" element={<ResetPass />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
