import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allotedAgent: {
    mob: null,
    displayName: "",
    agentId: "",
    role: "",
  },
  chatId: null,
  user: {},
  loading: false,
  isImageSelect: false,
  isProductSelect: false,
  isFileSelect: false,
  image: null,
  isVideoSelect: false,
  isRecAudioSelect: false,
  video: null,
  agentSearchItem: null,
  forwardModalAgentSearchItem: null,
  document: [],
  fileSending: false,
  isUserSelected: false,
  ticketStatusFilter: "",
  forwardModalTicketStatusFilter: "",
  showQuickNotes: false,
  filesLength: 0,
  filesLengthWarning: false,
  quickNotes: [
    {
      id: 1001,
      title: "Welcome message",
      content:
        " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      id: 1002,
      title: "Welcome message",
      content:
        "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      id: 1003,
      title: "Welcome message",
      content:
        "Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      id: 1004,
      title: "Welcome message",
      content:
        "Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      id: 1005,
      title: "Welcome message",
      content:
        "Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      id: 1006,
      title: "Welcome message",
      content:
        "Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  ],
  forwardMessagesFlag: false,
  forwardMessages: {},
  selectedForwardMessagesContent: [],
  clickedForwardFirstMessageId: null,
  files: [],
  uploadlock: false,
};

const enqChatSlice = createSlice({
  name: "enqChat",
  initialState,
  reducers: {
    changeAllotedAgent(state, action) {
      state.allotedAgent = action.payload;
    },
    changeUser(state, action) {
      state.user = action.payload;
      state.chatId = state.allotedAgent.mob + "-" + action.payload.mob;
    },
    resetChatUser(state) {
      state.user = initialState.user;
      state.chatId = initialState.chatId;
    },
    setImage(state, action) {
      state.image = action.payload;
    },
    setFiles(state, action) {
      state.files = action.payload;
    },
    showFilelimitWarning(state, action) {
      state.filesLength = action.payload.filesLength;
      state.filesLengthWarning = action.payload.filesLengthWarning;
    },
    deleteFiles(state, action) {
      state.files = state.files.filter((x) => x?.id !== action.payload);
      if (state.files.length === 0) {
        state.isFileSelect = false;
      }
    },
    setIsImageSelected(state, action) {
      state.isImageSelect = action.payload;
    },
    setIsFileSelect(state, action) {
      state.isFileSelect = action.payload;
    },
    setVideo(state, action) {
      state.video = action.payload;
    },
    setDocument(state, action) {
      state.document = action.payload;
    },
    setFileSending(state, action) {
      state.fileSending = action.payload;
    },
    clearfiles(state) {
      state.files = initialState.files;
    },
    setIsVideoSelected(state, action) {
      state.isVideoSelect = action.payload;
    },
    setIsProductSelected(state, action) {
      state.isProductSelect = action.payload;
    },
    changeAgentSearchtUser(state, action) {
      state.agentSearchItem = action.payload?.toLowerCase();
    },
    changeForwardModalAgentSearchtUser(state, action) {
      state.forwardModalAgentSearchItem = action.payload?.toLowerCase();
    },
    changeIsUserSelected(state, action) {
      state.isUserSelected = action.payload;
    },
    changeTicketStatusFilter(state, action) {
      state.ticketStatusFilter = action.payload;
    },
    changeForwardModalTicketStatusFilter(state, action) {
      state.forwardModalTicketStatusFilter = action.payload;
    },
    setlockUpload(state, action) {
      state.uploadlock = action.payload;
    },
    changeTicketID(state, action) {
      state.user.ticketId = action.payload;
    },
    updateUserStatus(state, { payload }) {
      state.user = { ...state.user, ...payload };
    },
    switchShowQuickNote(state) {
      state.showQuickNotes = !state.showQuickNotes;
    },
    changeIsRecAudioSelect(state, action) {
      state.isRecAudioSelect = action.payload;
    },

    setForwardMessage(state, action) {
      state.forwardMessagesFlag = action.payload.forwardMessagesFlag;
      state.forwardMessages = action.payload.forwardMessages;
      state.selectedForwardMessagesContent =
        action.payload.selectedForwardMessagesContent;
    },
    clearForwardMessage(state) {
      state.forwardMessagesFlag = false;
      state.forwardMessages = {};
      state.selectedForwardMessagesContent = [];
      state.clickedForwardFirstMessageId = null;
    },
    setForwardFirstMessageId(state, action) {
      state.clickedForwardFirstMessageId =
        action.payload.clickedForwardFirstMessageId;
    },
    clearForwardFirstMessageId(state, action) {
      state.clickedForwardFirstMessageId = null;
    },
  },
});

export const {
  changeUser,
  setDocument,
  setFileSending,
  clearfiles,
  setIsFileSelect,
  setIsImageSelected,
  setIsProductSelected,
  setVideo,
  setIsVideoSelected,
  setImage,
  setFiles,
  showFilelimitWarning,
  deleteFiles,
  setlockUpload,
  changeAllotedAgent,
  changeAgentSearchtUser,
  changeForwardModalAgentSearchtUser,
  changeIsUserSelected,
  changeTicketStatusFilter,
  changeForwardModalTicketStatusFilter,
  updateUserStatus,
  changeTicketID,
  resetChatUser,
  switchShowQuickNote,
  changeIsRecAudioSelect,
  setForwardMessage,
  clearForwardMessage,
  setForwardFirstMessageId,
  clearForwardFirstMessageId,
} = enqChatSlice.actions;

export default enqChatSlice.reducer;
