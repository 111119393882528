module.exports ={
    "DASHBOARD": "dashboard",
    "CAMPAIGN": "campaign",
    "PRODUCT": "product",
    "ORDER": "order",
    "ENQUIRES": "enquires",
    "CUSTOMER": "customer",
    "TEMPLATE": "template",
    "PROFILE": "profile",
    "CATEGORY": "category",
    "USER": 'user',
    "SALES": 'sales',
    "PAYMENT": 'payment',
    "TEXTAUTOMATION": 'textAutomation',
    "CUSTOMERGROUP": 'customerGroup',
    'NOTIFICATION': 'notification',
    'TICKET': 'ticket',
    "AGENT": "agent",
    "ACCOUNT": 'account',
    "MEDIA":'product',
    "BOTS":"template"
};


