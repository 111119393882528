import React from 'react'; 
import { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useDispatch, useSelector } from "react-redux";
import { createGroup,updateGroup, resetMode } from "../../reducers/customerTableSlice";
import CustomDialog from "../CustomDialog";
import { Text } from "../Text";
import axiosInstance from "../../api/axios.instance";
import {SelectableCustomers} from "../SelectableCustomers"
import * as Messag from '../../config/ToastMessage';

function CreateGroup({customersFrom="customers",campCustomerStatus="all", createGrpModule, setCreateGrpModule, toast }) {
  const { mode , selectedGroup, customersInSelectedGroup} = useSelector(state=> state.customerTable);

  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [nameCust, setNameCust] = useState("");
  const dispatch = useDispatch();

  const hideGroupModule = () =>{
    dispatch(resetMode());
    setCreateGrpModule(false)
  }

  const onSubmit = () => {

    if (nameCust.length === 0) {
      toast.current.show({
        severity: "error",
        detail: Messag.Name_Group_ToastFailMessage,
      });
      return;
    }
    const data = {
      groupName: nameCust,
      customerIds: Array.from(selectedCustomer, (x) => {
        return x["id"];
      }),
    };
    if(mode === "update"){
        const groupId = selectedGroup.id;
        dispatch(updateGroup({groupId,data}))
        .unwrap()
        .then(res=>{
         hideGroupModule();
          toast.current.show({ severity: "success", detail: Messag.Update_Group_ToastSuccessMessage });
        })
        .catch(errResponse=>{
          toast.current.show({ severity: "error", detail: errResponse.message});
        })
    }else{
      dispatch(createGroup(data)).then((res) => {
        if (res.error) {
          toast.current.show({ severity: "error", detail: res.error.message });
          hideGroupModule();
          return;
        }
        if (res.payload) {
          toast.current.show({ severity: "success", detail: Messag.Add_Group_ToastSuccessMessage });
          hideGroupModule();
          return;
        }
      });

      hideGroupModule();
    } 
  };


  useEffect(() => {
    if(mode === "update"){
      setNameCust(selectedGroup.groupName)
      axiosInstance.get(`/customer-group/${selectedGroup.id}/customer?page=0&limit=100000`)
      .then(res=>{
        setSelectedCustomer(res.data.rows)
      })
      .catch(err=>console.log(err))
    }
  }, []);
  return (
    <CustomDialog
      header={mode==="update"?"Update Group":`Create New Group`}
      visible={createGrpModule}
      onHide={hideGroupModule}
    >
      <div className="grid w-full h-full m-0">
        <div
          className={`col-12 xl:col-6 lg:col-6 md:col-12 sm:col-12 mt-5 flex flex-column justify-content-between`}
        >
          <div>
            <div className="card">
              <div
                className="card-container m-2 flex align-items-center justify-content-start border-1 border-round-sm border-dashed border-grey-500"
                style={{ backgroundColor: "#1C738E1A" }}
              >
                <div className="flex-1 m-2 px-3 py-2 flex align-items-center">
                  <Text type={"heading"}>
                    <span style={{ fontSize: "1.6rem" }}>
                      {selectedCustomer.length}{" "}
                    </span>
                  </Text>
                  <Text type={"heading"}>&nbsp;Selected</Text>
                </div>
              </div>
            </div>
            <div className="card">
              <h5 className="m-2">Name of the Group</h5>
              <div className="card-container m-2 flex align-items-center justify-content-start">
                <InputText
                  value={nameCust}
                  onChange={(e) => setNameCust(e.target.value)}
                  placeholder="Enter group name"
                  className="w-12"
                ></InputText>
              </div>
            </div>
          </div>

          <div className="hidden sm:hidden md:hidden xl:flex lg:flex">
            <div className="flex w-4 align-items-center justify-content-center ">
              <Button
                varient="filled"
                label={"Cancel"}
                className="skalebot-button flex w-12 mx-2 mt-2"
                onClick={() => hideGroupModule()}
                style={{ background: "#868686", border: "none" }}
              />
            </div>
            <div className=" flex w-8 align-items-center justify-content-center">
              <Button
                varient="filled"
                label={mode==="update"?"Update":"Save"}
                className="skalebot-button flex w-12 mx-2 mt-2 "
                style={{ background: "#005068", border: "none" }}
                onClick={onSubmit}
              />
            </div>
          </div>
        </div>
        <div className="col-12 xl:col-6 lg:col-6 md:col-12 sm:col-12">
          <div className="flex-1 m-2 text-base mb-2 font-bold pb-1">
            Contacts
          </div>
          <SelectableCustomers selectedCustomer={selectedCustomer} setSelectedCustomer={setSelectedCustomer} customersFrom={customersFrom} campCustomerStatus={campCustomerStatus} />
          {/* <div
            className={`card mt-2 overflow-y-auto `}
            ref={listInnerRef}
            onScroll={onScroll}
            style={{height:"530px"}}
          >
            <DataTable
              value={customers}
              selection={selectedCustomer}
              headerColumnGroup={headerGroup}
              onSelectionChange={(e) => setSelectedCustomer(e.value)}
              dataKey="id"
              responsiveLayout="scroll"
              scrollable
              className="skalebot-table2 border-round-left-xs"
              loading={isLoading}
            >
              <Column selectionMode="multiple" header={false}  style={{ width: "20%",flex: "none" }}></Column>

              <Column field="name"  style={{ width: "40%" }}></Column>
              <Column field="phone"  style={{ width: "40%" }}></Column>
            </DataTable>
          </div> */}
          <div className="flex sm:flex md:flex xl:hidden lg:hidden gap-3 sm:flex-nowrap md:flex-nowrap mt-5">
            <div className="w-4">
              <Button
                varient="filled"
                label={"Cancel"}
                className="skalebot-button w-full"
                onClick={() => setCreateGrpModule(false)}
                style={{ background: "#868686", border: "none" }}
              />
            </div>
            <div className="w-8 flex-grow-1">
              <Button
                varient="filled"
                label={mode==="update"?"Update":"Save"}
                className="skalebot-button w-full"
                style={{ background: "#005068", border: "none" }}
                onClick={onSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </CustomDialog>
  );
}

export default CreateGroup;
