import {
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import { formatMobNumber } from "./commonUtils";

const importSheetStemps = [
  {
    key: "Import",
    value: "import",
  },
  {
    key: "Map column headers",
    value: "map",
  },
  {
    key: "Format cell",
    value: "format_cell",
  },
  {
    key: "Validate",
    value: "validate",
  },
];
const prodctDefaultColumns = [
  {
    label: "Product name",
    value: "",
    field: "productName",
    isRequired: true,
    spreadSheetColumn: null,
    validate: (value) => {
      let prod = value ? value.toString().trim() : "";
      if (prod.length == 0 || prod.length > 2000) return false;
      else return true;
    },
  },
  {
    label: "Category",
    value: "",
    field: "categoryName",
    isRequired: true,
    spreadSheetColumn: null,
    validate: (value) => {
      let category = value ? value.toString().trim() : "";
      if (category.length == 0) return false;
      else return true;
    },
  },
  {
    label: "Quantity",
    value: 0,
    field: "quantity",
    isRequired: true,
    spreadSheetColumn: null,
    validate: (value) => {
      if (isNaN(Number(value))) return false;
      else return true;
    },
  },
  // {
  //   label: "Items in pack",
  //   value: 1,
  //   field: "ItemsInPack",
  //   isRequired: true,
  //   spreadSheetColumn: null,
  //   validate: (value) => {
  //     if (isNaN(Number(value))) return false;
  //     else return true;
  //   },
  // },
  {
    label: "Price",
    field: "price",
    value: 0,
    isRequired: false,
    spreadSheetColumn: null,
    validate: (value) => {
      if (value && isNaN(Number(value))) return false;
      else return true;
    },
  },
  {
    label: "Status",
    value: "",
    field: "status",
    isRequired: true,
    spreadSheetColumn: null,
    validate: (value) => {
      let statusValues = ["Available", "Unavailable"];
      let status = value ? value.toString().trim() : "";
      if (status.length == 0 || !statusValues.includes(status)) return false;
      else return true;
    },
  },
  {
    label: "SKUCode",
    value: "",
    field: "SKUCode",
    isRequired: true,
    spreadSheetColumn: null,
    validate: (value) => {
      let skuRegex = /^[a-zA-Z0-9-]*$/;
      let skuid = value ? value.toString().trim() : "";
      if (skuid.length > 20 || skuid.length == 0) return false;
      else return skuRegex.test(skuid);
    },
  },
  {
    label: "Description",
    value: "",
    field: "desc",
    isRequired: false,
    spreadSheetColumn: null,
    validate: (value) => {
      let regex = /[ a-z]/;
      let desc = value ? value.toString().trim() : "";
      return regex.test(desc);
    },
  },

  {
    label: "Thumbnail",
    field: "image_link",
    value: "",
    isRequired: true,
    spreadSheetColumn: null,
    validate: (value) => {
      let thumbnailURL = value ? value.toString().trim() : "";
      if (thumbnailURL.length == 0) return false;
      else return true;
    },
  },
  {
    label: "Images",
    field: "additional_image_link",
    value: "",
    isRequired: false,
    spreadSheetColumn: null,
    validate: (value) => {
      return true;
    },
  },
];

const customerDefaultColumns = [
  {
    label: "Customer Name",
    value: "",
    field: "name",
    isRequired: true,
    spreadSheetColumn: null,
    validate: (value) => {
      let customerName = value ? value.toString().trim() : "";

      if (customerName.length == 0) return false;
      else return true;
    },
  },
  // {
  //   label: "Country code",
  //   field: "countryCode",
  //   value: "+91",
  //   isRequired: false,
  //   validate: (value) => {
  //     let cc = value ? "+" + value.toString().trim() : "";
  //     return true;
  //   },
  // },
  {
    label: "Phone (*with country code)",
    value: "",
    field: "phone",
    isRequired: true,
    spreadSheetColumn: null,
    validate: (value) => {
      let mob = value ? value.toString().trim() : "";
      if (mob.length == 0) return false;
      else {
        return isValidPhoneNumber(mob);
      }
    },
  },
  {
    label: "Email",
    value: "",
    field: "email",
    isRequired: false,
    spreadSheetColumn: null,
    validate: (value) => {
      let emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      let email = value ? value.trim() : "";
      if (email.length != 0) {
        return emailRegex.test(email);
      } else return true;
    },
  },
  {
    label: "Group name",
    value: "",
    field: "groupName",
    isRequired: false,
    spreadSheetColumn: null,
    validate: (value) => {
      return true;
    },
  },
  {
    label: "Billing address",
    value: "",
    field: "billingAddress",
    isRequired: false,
    spreadSheetColumn: null,
    validate: (value) => {
      return true;
    },
  },
  {
    label: "Delivery address",
    value: "",
    field: "deliveryAddress",
    isRequired: false,
    spreadSheetColumn: null,
    validate: (value) => {
      return true;
    },
  },
];

const groupDefaultColumns = [
  {
    label: "Customer Name",
    value: "",
    field: "name",
    isRequired: true,
    spreadSheetColumn: null,
    validate: (value) => {
      let customerName = value ? value.toString().trim() : "";

      if (customerName.length == 0) return false;
      else return true;
    },
  },
  // {
  //   label: "Country code",
  //   field: "countryCode",
  //   value: "+91",
  //   isRequired: false,
  //   validate: (value) => {
  //     let cc = value ? "+" + value.toString().trim() : "";
  //     return true;
  //   },
  // },
  {
    label: "Phone (*with country code)",
    value: "",
    field: "phone",
    isRequired: true,
    spreadSheetColumn: null,
    validate: (value) => {
      let mob = value ? value.toString().trim() : "";
      if (mob.length == 0) return false;
      else {
        return isValidPhoneNumber(mob);
      }
    },
  },
  {
    label: "Email",
    value: "",
    field: "email",
    isRequired: false,
    spreadSheetColumn: null,
    validate: (value) => {
      let emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      let email = value ? value.trim() : "";
      if (email.length != 0) {
        return emailRegex.test(email);
      } else return true;
    },
  },
  {
    label: "Billing address",
    value: "",
    field: "billingAddress",
    isRequired: false,
    spreadSheetColumn: null,
    validate: (value) => {
      return true;
    },
  },
  {
    label: "Delivery address",
    value: "",
    field: "deliveryAddress",
    isRequired: false,
    spreadSheetColumn: null,
    validate: (value) => {
      return true;
    },
  },
];

export {
  prodctDefaultColumns,
  customerDefaultColumns,
  importSheetStemps,
  groupDefaultColumns,
};
