import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const ProtectedRoute = ({ redirectPath = "/login", children }) => {
  const token = localStorage.getItem("token");
  //const {isLogedIn} = useSelector(state => state.authenticate);

  if (!token) return <Navigate to={redirectPath} replace />;

  return children;
};
