import React from "react";
import Messages from "./Messages";
import Input from "./Input";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import {
  changeIsUserSelected,
  setIsImageSelected,
  setIsProductSelected,
} from "../../reducers/enqChatSlice";
import Products from "./Products";
import { AddNewCustomer } from "../../components/Forms/AddNewCustomer";
import { Toast } from "primereact/toast";
import {
  changeMode,
  changeSelectedCustomer,
  resetMode,
  resetSelectedCustomer,
} from "../../reducers/customerTableSlice";
import { CustomButton } from "../../components/CustomButton";
import { Text } from "../../components/Text";
import FileInputsComponenets from "../../components/FileInputsComponents";
import AgentPlace from "./AgentPlace";
import { API_GET_ALL_AGENTS } from "../../api/account.service";
import TicketReasign from "./TicketReasign";
import { formatMobNumber } from "../../utils/commonUtils";
import { Menu } from "primereact/menu";
import modulesName from "../../roles/modulesName";
import permissions from "../../roles/permissions";

const Chat = () => {
  const {
    allotedAgent,
    user,
    isFileSelect,
    isProductSelect,
    isImageSelect,
    isVideoSelect,
    chatId,
  } = useSelector((state) => state.enqChats);
  const { accesskey } = useSelector((state) => state.authenticate);
  const dispatch = useDispatch();
  const toast = useRef(null);
  const menuButtonRef = useRef(null);
  // const [displayBasic, setDisplayBasic] = useState(false);

  // const onHide = () => {
  //   setDisplayBasic(false);
  //   dispatch(resetSelectedCustomer());
  //   dispatch(resetMode());
  //   // dispatch(changeSelectedCustomer({phone:user.mob}));
  //   // dispatch(changeMode("convert"));
  // };

  // const addCustomerModal = () => {
  //   return (
  //     <AddNewCustomer
  //       onHide={onHide}
  //       displayBasic={displayBasic}
  //       toast={toast}
  //     />
  //   );
  // };

  // function handelClick() {
  //   dispatch(changeMode("convert"));
  //   dispatch(changeSelectedCustomer({ phone: user.mob }));
  //   setDisplayBasic(true);
  // }

  function ChatHeader() {
    if (Object.keys(user).length == 0) {
      return <></>;
    } else {
      return (
        <div className="flex align-items-center gap-1">
          <CustomButton
            varient="icon-button md:hidden"
            icon={"pi pi-arrow-left"}
            onClick={() => dispatch(changeIsUserSelected(false))}
          />
          <Text type="sub-heading" cust="flex align-items-center">
            <div className="capitalize mr-3">
              <Text type={"sub-heading"}>
                {user?.displayName || formatMobNumber(user.mob)}
              </Text>
            </div>
            <div>
              {user?.displayName ? (
                <CustomButton
                  varient="icon-button"
                  icon={"pi pi-info-circle"}
                  onClick={(event) => menuButtonRef.current.toggle(event)}
                />
              ) : (
                ""
              )}
            </div>
          </Text>
        </div>
      );
    }
  }

  let items = [{ label: formatMobNumber(user?.mob) }];

  return (
    <div className="h-full">
      <Toast ref={toast} />
      <Menu model={items} popup ref={menuButtonRef} id="menu_button" />
      <div className="flex flex-column h-full ">
        {/* {displayBasic ? addCustomerModal() : <></>} */}
        {!accesskey[modulesName.SALES]?.includes(permissions.UPDATE) && (
          <AgentPlace />
        )}
        {Object.keys(user).length != 0 ? (
          <>
            <div className="chatInfo p-2 lg:pl-4 xl:pl-4 ">
              <div className="px-2 flex flex-column md:flex-row justify-content-between  align-items-start md:align-items-center">
                <div>{ChatHeader()}</div>
                <div className="w-12 md:w-6">
                    <div className="w-full">
                      <TicketReasign />
                    </div>
                  {/* <CustomButton varient="icon-button" icon={"pi pi-ellipsis-v"} /> */}
                </div>
              </div>
            </div>
            
            {isFileSelect ? (
              <FileInputsComponenets />
            ) : isImageSelect ? (
              <FileInputsComponenets />
            ) : isVideoSelect ? (
              <FileInputsComponenets />
            ) : isProductSelect ? (
              <Products />
            ) : (
              <>
                <Messages />
                <Input />
              </>
            )}
          </>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default Chat;
