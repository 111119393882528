import { Outlet } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import AppLogout from "../../utils/AppLogout";
import { useLocation } from "react-router-dom";
import { Dashboard } from "../Dashboard";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { subscription } from "../../reducers/authSlice";
import Sidebar from "../../components/Sidebar";
import ChatEnq from "../ChatEnq/Index";
import modulesName from "../../roles/modulesName";
import permissions from "../../roles/permissions";
import axiosInstance, {
  setupRequestInterceptor,
} from "../../api/axios.instance";
export const Home = () => {
  const [type, setType] = useState(true);

  const { user, accesskey, pause } = useSelector((state) => state.authenticate);
  const location = useLocation();
  const dispatch = useDispatch();
  const currentPath = location.pathname;

  const homePage = () => {
    if (Object.keys(accesskey).length != 0) {
      if (
        (accesskey[modulesName.DASHBOARD]?.includes(permissions.READ) ||
          pause) &&
        currentPath === "/"
      ) {
        return <Dashboard />;
      } else if (
        (accesskey[modulesName.SALES]?.includes(permissions.READ) || pause) &&
        currentPath === "/"
      )
        return <ChatEnq />;
      else return <Outlet />;
    } else return <></>;
  };

  useEffect(() => {
    if (user) dispatch(subscription(user?.companyId));
    var w = window.innerWidth;
    if (w < 768) {
      setType(false);
    }
  }, [user]);

  useEffect(() => {
    let companyKey = JSON.parse(localStorage.getItem("companykey"));

    let localInterceptorId = null;

    if (companyKey) {
      localInterceptorId = setupRequestInterceptor({
        companyId: companyKey.id,
      });
    } else {
      //no action
    }

    return () => {
      axiosInstance.interceptors.request.eject(localInterceptorId);
    };
  }, []);

  return (
    <AppLogout>
      <div>
        <header className="header__app ">
          <Header type={type} setType={setType} />
        </header>
        <div className="flex">
          <div>
            <Sidebar type={type} setType={setType} />
          </div>
          <div
            className={`w-full h-full flex flex-column justify-content-between  ${
              type ? `side-p-o` : `side-p-c`
            }`}
            style={{ minHeight: "92vh", marginTop: "60px" }}
          >
            <div className="flex-grow-1 ml-2">{homePage()}</div>
            {currentPath !== "/chat" && <Footer />}
          </div>
        </div>
      </div>
    </AppLogout>
  );
};
