import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import Loader from "../../components/Loader";
import { CustomButton } from "../../components/CustomButton";
import { AddNewCustomer } from "../../components/Forms/AddNewCustomer";
import { Column } from "primereact/column";
import { ReactComponent as Edit } from "../../svg/edit.svg";
import { ReactComponent as Delete } from "../../svg/delete.svg";
import { useDispatch, useSelector } from "react-redux";
import { DeleteAlert } from "../../components/Alert/DeleteAlert";
import CustomPaginator from "../../components/CustomPaginator";
import CreateGroup from "../../components/CreateGroup";
import "./style.css";
import { Text } from "../../components/Text";
import {
  changeMode,
  changeSelectedCustomer,
  changeShowCustomersType,
  changePage,
  getCustomers,
  resetMode,
  resetSelectedCustomer,
  changelimit,
  setChunkpage,
  getGroups,
  getCustomersinChunk,
  changeSelectedCustomers,
  bulkDeleteCustomer,
} from "../../reducers/customerTableSlice";
import GroupTable from "./GroupTable";
import { Button } from "primereact/button";
import CSVupload from "../../components/CSVupload";
import GoogleContactsUpload from "../../components/GoogleContactsUpload";
// import { useGoogleLogin } from "@react-oauth/google";
// import axios from "axios";
// import { formatGoogleContacts } from "../../utils/tableUtils";
import { Menu } from "primereact/menu";

import TableActionContainer from "../../components/CustomTable/TableActionsContainer";
import DebounceSearch from "../../components/common/DebounceSearch";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TabMenu } from "primereact/tabmenu";
import { formatMobNumber, maskMobNumber } from "../../utils/commonUtils";
import SklbtConfirmation from "../../components/common/SklbtConfirmation";
import ImportCustomersFromSheet from "./ImportCustomersFromSheet";
import modulesName from "../../roles/modulesName";
import permissions from "../../roles/permissions";

const CustomerList = () => {
  const [displayBasic, setDisplayBasic] = useState(false);
  const [showCreateGropeModule, setShowCreateGroupModule] = useState(false);
  const [displayAlertDelete, setDisplayAlertDelete] = useState(false);
  const [csvUpload, setCsvUpload] = useState(false);
  const [showGoogleContacts, setShowGoogleContacts] = useState(false);
  const [googleContacts, setGoogleContacts] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [bulkDeleteAlert, setBulkDeleteAlert] = useState(false);

  const items = [
    {
      label: "All Customers",
      icon: "",
      value: "all",
      route: "/customers/list"
    },
    { label: "Groups", icon: "", value: "groups", route: "/customers/groups" }
  ];

  // const googleLogin = useGoogleLogin({
  //   onSuccess: async (tokenResponse) => {
  //     const contactUrl = `${process.env.REACT_APP_GOOGLE_CONTACT_API+process.env.REACT_APP_GOOGLE_API_KEY}`;
  //     const contacts = await axios.get(contactUrl, {
  //       headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
  //     });
  //     const formatedContactList = formatGoogleContacts(
  //       contacts.data.connections
  //     );
  //     setGoogleContacts(formatedContactList);
  //     setShowGoogleContacts(true);
  //   },
  //   scope: `${process.env.REACT_APP_GOOGLE_SCOPE}`,
  //   onError: (errorResponse) => console.error(errorResponse),
  // });

  const hideGoogleContacts = () => {
    setShowGoogleContacts(false);
    setGoogleContacts([]);
  };
  const menu = useRef(null);
  const toast = useRef(null);
  const dispatch = useDispatch();

  const importContactOptions = [
    {
      label: "Import from excel",
      icon: "pi pi-file-excel",
      command: () => {
        setCsvUpload(true);
      },
    },
    // {
    //   label: "Import from Gmail(comming soon)",
    //   icon: "pi pi-google",
    //   command: () => {
    //     //googleLogin()
    //   },
    // },
  ];

  const {
    customerData,
    chunkpage,
    page,
    limit,
    loading,
    secondaryloading,
    totalCustomerCount,
    showCustomersType,
    selectedGroupID,
    selectedCustomers,
  } = useSelector((state) => state.customerTable);
  const { accesskey } = useSelector((state) => state.authenticate);

  const modalLoad = () => {
    return <Loader visible={loading} />;
  };

  const onAddNewClick = () => {
    if (showCustomersType === "groups") {
      navigate(`/customers/gnew`);
    } else {
      dispatch(resetMode());
      navigate(`/customers/new`);
    }
  };

  const onHide = (reset) => {
    setDisplayBasic(false);
    dispatch(resetSelectedCustomer());
    dispatch(resetMode());
    reset();
  };
  const { id } = useParams();
  const location = useLocation();
  const [index, setIndex] = useState(0);
  const [mem, setMem] = useState({});

  const navigate = useNavigate();

  const handleEdit = (customer) => {
    dispatch(changeSelectedCustomer(customer));
    dispatch(changeMode("update"));
    navigate(`/customers/${customer.id}/update`);
  };

  useEffect(() => {
    if (location.pathname.includes(`/customers/groups`)) {
      dispatch(changeShowCustomersType("groups"));
      setIndex(1);
    }
  }, [location, id]);

  const handleDelete = (customer) => {
    setDisplayAlertDelete(true);
    dispatch(changeSelectedCustomer(customer));
  };

  const deleteModule = () => {
    return (
      <DeleteAlert
        item="customer"
        displayAlertDelete={displayAlertDelete}
        setDisplayAlertDelete={setDisplayAlertDelete}
        toast={toast}
      />
    );
  };

  const addCustomerModal = () => {
    return (
      <AddNewCustomer
        onHide={onHide}
        displayBasic={displayBasic}
        toast={toast}
      />
    );
  };



  const phoneNumberBody = (rowData) => {
    let selectedFormattedPhoneNumber = formatMobNumber(rowData);
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <CustomButton
          varient="icon-button"
          icon={"pi pi-info-circle"}
          tooltip={`${selectedFormattedPhoneNumber}`}
          tooltipOptions={{ showDelay: 0, hideDelay: 0, position: "bottom" }}
        />

        <span>{maskMobNumber(rowData)}</span>
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex">
        {accesskey[modulesName.CUSTOMER]?.includes(permissions.UPDATE) && (
          <CustomButton
            varient="icon-button"
            onClick={() => handleEdit(rowData)}
            icon={<Edit />}
          />
        )}
        {accesskey[modulesName.CUSTOMER]?.includes(permissions.DELETE) && (
          <CustomButton
            varient="icon-button"
            onClick={() => handleDelete(rowData)}
            icon={<Delete />}
          />
        )}
      </div>
    );
  };

  const CreateGroupModule = () => {
    return (
      <CreateGroup
        createGrpModule={showCreateGropeModule}
        setCreateGrpModule={setShowCreateGroupModule}
        toast={toast}
      />
    );
  };

  const handleTabChange = (e) => {
    dispatch(changeShowCustomersType(e.value.value));
    setIndex(e.index);
    navigate(e.value.route);
  };

  function handleSelectCustomer(selectedData) {
    dispatch(changeSelectedCustomers({ selectedData, customerData }));
  }

  const handleBulkDelete = () => {
    const customerIds = selectedCustomers?.map((customer) => customer?.id);
    dispatch(bulkDeleteCustomer(customerIds))
      .unwrap()
      .then((res) => {
        setBulkDeleteAlert(false);
        toast.current.show({
          severity: "success",
          detail: `${res.length} Customers deleted successfully`,
          life: 3000,
        });
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          detail: err?.message,
          life: 3000,
        });
      });
  };

  const listInnerRef = useRef();

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (
        Math.floor(scrollTop + clientHeight + 5) >= scrollHeight ||
        Math.ceil(scrollTop + clientHeight + 5) >= scrollHeight
      ) {
        dispatch(setChunkpage(chunkpage + 1));
      }
    }
  };
  useEffect(() => {
    if (showCustomersType == "all") {
      // console.log("selectedCustoer", showCustomersType);
      if (limit <= 100) {
        dispatch(getCustomers({ page, limit, globalFilterValue: inputValue }))
          .unwrap()
          .catch(() => {
            //show toast from here
          });
      } else {
        dispatch(
          getCustomersinChunk({
            page: chunkpage,
            limit: 100,
            globalFilterValue: inputValue,
          })
        )
          .unwrap()
          .catch(() => {
            //show toast from here
          });
      }
    }
    return () => {
      dispatch(changeShowCustomersType("all"));
    };
  }, [page, limit, inputValue, limit >= 100 ? chunkpage : ""]);

  return (
    <div className="p-3">
      {bulkDeleteAlert && (
        <SklbtConfirmation
          visible={bulkDeleteAlert}
          handleConfirm={handleBulkDelete}
          handleReject={() => setBulkDeleteAlert(false)}
          message={`Are you sure, you want to delete ${selectedCustomers.length} customers?`}
        />
      )}
      <Toast ref={toast} />
      {csvUpload && (
        <ImportCustomersFromSheet
          visiable={csvUpload}
          hideDialog={() => setCsvUpload(false)}
          toast={toast}
        />
      )}
      <GoogleContactsUpload
        contactList={googleContacts}
        showGoogleContacts={showGoogleContacts}
        hideGoogleContacts={hideGoogleContacts}
        toast={toast}
      />
      {displayBasic && addCustomerModal()}
      {showCreateGropeModule && CreateGroupModule()}
      {loading && modalLoad()}
      {deleteModule()}

      <TableActionContainer>
        <div className="flex justify-content-between align-items-center flex-wrap gap-3">
          <div className="flex gap-2 align-items-end ">
            <TabMenu
              model={items}
              activeIndex={index}
              onTabChange={handleTabChange}
              className={`custom-tabmenu`}
            />
            {selectedCustomers.length != 0 && (
              <CustomButton
                varient="icon-button"
                label={
                  <Text>({selectedCustomers.length}) Delete Selected</Text>
                }
                onClick={() => setBulkDeleteAlert(true)}
                disabled={selectedCustomers.length == 0}
                style={{ marginBottom: "4px" }}
              />
            )}
          </div>

          <div className="flex gap-3 flex-wrap-reverse">
            {showCustomersType === "all" && (
              <DebounceSearch
                placeholder={
                  showCustomersType === "all"
                    ? "Search Customer"
                    : "Search Group"
                }
                inputValue={inputValue}
                handleInputChange={(value) => setInputValue(value)}
              />
            )}

            <div className="flex gap-2 justify-content-end align-items-center flex-grow-1">
              {accesskey[modulesName.CUSTOMER]?.includes(permissions.CREATE) &&
                showCustomersType === "all" && (
                  <div className="card flex justify-content-center">
                    {/* <Menu
                      model={importContactOptions}
                      popup
                      ref={menu}
                      className="import-contact-menu"
                    />
                    <Button
                      // label="Import Contacts"
                      icon="pi pi-ellipsis-h"
                      onClick={(e) => menu.current.toggle(e)}
                      className="skalebot-button import-contact-btn"
                    /> */}
                    <ImportCustomersFromSheet toast={toast} />
                  </div>
                )}
              {(showCustomersType === "all" || selectedGroupID === "all") &&
                accesskey[
                  showCustomersType === "all" ? "customer" : "group"
                ]?.includes("create") && (
                  <div className="flex gap-2">
                    <CustomButton
                      varient="filled"
                      label={
                        showCustomersType === "all"
                          ? "Add New Customer"
                          : "Create New Group"
                      }
                      icon={"pi pi-plus"}
                      onClick={onAddNewClick}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
      </TableActionContainer>

      <div className="mt-3">
        {showCustomersType !== "all" ? (
          <GroupTable />
        ) : (
          <div>
            <div
              className="overflow-y-auto"
              ref={listInnerRef}
              onScroll={onScroll}
              style={{ maxHeight: "70vh" }}
            >
              <DataTable
                value={customerData}
                selection={selectedCustomers}
                onSelectionChange={(e) => handleSelectCustomer(e.value)}
                dataKey="id"
                scrollable
                selectionMode={
                  accesskey[modulesName.CUSTOMER]?.includes(permissions.DELETE)
                    ? "checkbox"
                    : ""
                }
                className="skalebot-table"
              >
                {accesskey[modulesName.CUSTOMER]?.includes(
                  permissions.DELETE
                ) && (
                  <Column
                    selectionMode="multiple"
                    headerStyle={{ width: "3rem" }}
                    exportable={false}
                  ></Column>
                )}
                <Column
                  field="customerCode"
                  header={<span>Customer&nbsp;Code</span>}
                  style={{ maxWidth: "140px" }}
                  className="text-sm font-normal"
                />
                <Column
                  header={<span>Customer&nbsp;Name</span>}
                  field="name"
                  style={{ minWidth: "200px" }}
                  className="text-sm font-normal"
                  bodyStyle={{ textTransform: "capitalize", height: "50px" }}
                />
                <Column
                  header="Phone"
                  field="phone"
                  className="text-sm font-normal"
                  style={{ maxWidth: "25ch" }}
                  body={(rowData) => phoneNumberBody(rowData?.phone)}
                  bodyStyle={{ textWrap: "nowrap" }}
                />
                <Column
                  field="email"
                  header="Email"
                  className="text-sm font-normal"
                  style={{ maxWidth: "50ch" }}
                  bodyStyle={{ textTransform: "lowercase" }}
                />
                {(accesskey[modulesName.CUSTOMER]?.includes(
                  permissions.UPDATE
                ) ||
                  accesskey[modulesName.CUSTOMER]?.includes(
                    permissions.DELETE
                  )) && (
                  <Column
                    body={actionBodyTemplate}
                    header="Actions"
                    className="text-sm"
                    style={{ width: "100px" }}
                  ></Column>
                )}
              </DataTable>
            </div>
            <div className="mt-3">
              <TableActionContainer>
                <div className="flex justify-content-end">
                  <CustomPaginator
                    page={page}
                    limit={limit}
                    changelimit={changelimit}
                    totalRecords={totalCustomerCount}
                    changePage={changePage}
                  />
                </div>
              </TableActionContainer>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerList;
