import sampleCampaignData from "./sampleCampaigndata.json";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useState, useRef } from "react";
import PaymentGateway from "../../components/PaymentGateway";
function DemoCampaigns() {
  const toast = useRef();
  const [gateway, setGateway] = useState(false);

  const handlegateway = () => {
    setGateway(true);
  };

  const gatewayPopup = () => {
    return (
      <PaymentGateway
        gateway={gateway}
        setGateway={setGateway}
        category={"SUBSCRIPTION"}
        toast={toast}
      />
    );
  };

  return (
    <div className="disableTableForPro">
      {gateway && gatewayPopup()}
      <DataTable
        value={sampleCampaignData[0]}
        key="id"
        className="skalebot-table standardTable mt-3"
        responsiveLayout="scroll"
        emptyMessage="No data found."
        resizableColumns
        columnResizeMode="expand"
      >
        <Column field="campaignName" header="Campaign Name " />
        <Column
          field="message"
          header={"Message"}
          body={"Campaign Information"}
          bodyStyle={{
            width: "auto",
            maxWidth: "180px",
            textOverflow: "ellipsis",
          }}
        />
        <Column
          field="conversationsCount"
          header="Conversations count"
          body={"45"}
        />
      </DataTable>
      <div className="hideContent">
        <div className="__card">
          <div className="__card-body cursor-pointer" onClick={handlegateway}>
            Unlock With Pro Subscription
          </div>

          <i className="pi pi-unlock mx-1"></i>
        </div>
      </div>
    </div>
  );
}

export default DemoCampaigns;
