import { Text } from "../../components/Text";
import { CustomButton } from "../../components/CustomButton";
import { useSelector } from "react-redux";
import QRCode from "react-qr-code";
import { useEffect, useState, useRef } from "react";
import { Toast } from "primereact/toast";
import axiosInstance from "../../api/axios.instance";
import "./index.css";
import ChangePassword from "../../components/ChangePassword";
import Loader from "../../components/Loader";
import TableActionsContainer from "../../components/CustomTable/TableActionsContainer";
import { formatMobNumber } from "../../utils/commonUtils";
import EditProfile from "./EditProfile";
import { Image } from "primereact/image";
import PaymentQR from "./PaymentQR";
import QRCodeStyling from "qr-code-styling";
import QrIcon from "../../assets/qrcenter.png";

function UserProfile() {
  const [dpData, setDpData] = useState(null);
  const [showChangePassword, setChangeShowPassword] = useState(false);
  const [editProfile, setEditProfile] = useState(false);

  const { user, userSub } = useSelector((state) => state.authenticate);
  const { company, loadingC } = useSelector((state) => state.company);
  const ref = useRef(null);
  const toast = useRef(null);

  const isAdmin = user.role === "superadmin";

  const downloadQRCode = () => {
    const svg = document.getElementById("generatedQrCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  useEffect(() => {
    if (user?.companyId) {
      axiosInstance
        .get(`company/${user.companyId}/image`)
        .then((resp) => {
          setDpData(resp?.data);
          // setGetLogo(resp.data.url);
          // setPutLogo(resp.data.src);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const qrCode = new QRCodeStyling({
    width: 180,
    height: 180,
    image: QrIcon,
    dotsOptions: {
      color: "#000",
      type: "rounded"
    },
    imageOptions: {
      crossOrigin: "anonymous"
    },
    backgroundOptions: {
      color: "#f8f9fe"
    },
    backgroundOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#ffffff",
        color2: "#ffffff"
      }
    },
    qrOptions: { typeNumber: "0", mode: "Byte", errorCorrectionLevel: "L" },
    imageOptions: { hideBackgroundDots: true, imageSize: 0.5, margin: 0 },
    dotsOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#6a1a4c",
        color2: "#6a1a4c",
        rotation: "0"
      }
    },
    cornersSquareOptions: { type: "square", color: "#000000" },
    cornersSquareOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000000",
        color2: "#000000",
        rotation: "0"
      }
    },
    cornersDotOptions: { type: "", color: "#000000" },
    cornersDotOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000000",
        color2: "#000000",
        rotation: "0"
      }
    }
  });

  useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  // change upi address in this location .👇
  useEffect(() => {
    qrCode.update({
      data: `https://wa.me/${company?.phone}?text=Hi`
    });
  }, [company?.phone]);

  const onDownloadClick = () => {
    qrCode.update({
      data: `https://wa.me/${company?.phone}?text=Hi`
    });
    qrCode.download({
      extension: "png"
    });
  };

  return (
    <>
      <div>
        <Loader visible={loadingC} />
        {editProfile && (
          <EditProfile
            showDialog={editProfile}
            hideDialog={() => setEditProfile(false)}
            toast={toast}
            profilePicData={dpData}
          />
        )}

        <Toast ref={toast} />
        {showChangePassword && (
          <ChangePassword
            showChangePassword={showChangePassword}
            hideChangePassword={() => setChangeShowPassword(false)}
            toast={toast}
          />
        )}
        <div className={`w-full p-3 `}>
          <TableActionsContainer>
            <Text type={"heading"} style={{ padding: "0.9rem 0.5rem" }}>
              User Profile
            </Text>
          </TableActionsContainer>

          <div className="my-3">
            <TableActionsContainer>
              <div className="flex justify-content-between align-items-center p-2">
                <div>
                  <div className="flex">
                    <Text type={"sub-heading"}>Business Name:&nbsp;</Text>
                    <Text type={"heading"}>&nbsp;{company?.companyName}</Text>
                  </div>

                  <div className="mt-3 flex">
                    <Text type={"sub-heading"}> Subscription Type:&nbsp;</Text>

                    <Text type={"heading"}>
                      &nbsp;
                      <span className="capitalize">
                        {userSub.isUserSub ? userSub.userSubType : "none"}
                      </span>
                      <span className="capitalize">
                        {userSub.isUserSub && ` (${userSub.plan})`}
                      </span>
                    </Text>
                  </div>
                </div>
                {/* <button
                  className="logo"
                  title={isAdmin ? "Click to modify logo" : undefined}
                  onClick={handleLogoEdit}
                > */}
                <div>
                  <Image
                    src={`${dpData?.url}?v=${new Date().getTime()}`}
                    onError={(e) => (e.target.src = "./svgIcons/UserIcon.svg")}
                    alt="Company logo"
                    className="logoImg"
                    width="100px"
                  />
                </div>

                {/* {isAdmin && <img className="editIcon" src={edit} alt="" />}
                </button> */}
              </div>
            </TableActionsContainer>
            <div className="w-full flex lg:flex-row flex-column gap-3 flex-wrap justify-content-between py-3 pb-4">
              <div className=" w-12 lg:w-7">
                <Text type={"heading"}>Profile Details</Text>
                <div className="my-3">
                  <TableActionsContainer>
                    <div className={`w-full`}>
                      <div>
                        <div className="my-3">
                          <div className="mb-3">
                            <Text type={"sub-heading"}>
                              Business mobile number
                            </Text>
                            <div className="mt-2">
                              <Text type={"heading"}>
                                {formatMobNumber(company?.phone)}
                              </Text>
                            </div>
                          </div>
                          <div className="mb-3">
                            <Text type={"sub-heading"}>
                              Personal mobile number
                            </Text>
                            <div className="mt-2">
                              <Text type={"heading"}>
                                {formatMobNumber(company?.personalPhone)}
                              </Text>
                            </div>
                          </div>
                          <div className="mb-3">
                            <Text type={"sub-heading"}>GST No</Text>
                            <div className="mt-2">
                              <Text type={"heading"}>{company?.gstNumber}</Text>
                            </div>
                          </div>
                          <div className="mb-3">
                            <Text type={"sub-heading"}>PAN No</Text>
                            <div className="mt-2">
                              <Text type={"heading"}>{company?.panNumber}</Text>
                            </div>
                          </div>
                          <div className="mb-3">
                            <Text type={"sub-heading"}>Billing Address</Text>
                            <div className="mt-2">
                              <Text type={"heading"}>
                                {company?.billingAddress}
                              </Text>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* )} */}

                      <div className="w-full flex flex-row gap-2 justify-content-end">
                        {isAdmin && (
                          <CustomButton
                            varient="outline"
                            type={"button"}
                            onClick={() => setEditProfile(true)}
                            label={"Edit Profile"}
                          />
                        )}
                      </div>
                    </div>
                  </TableActionsContainer>
                </div>
                <div>
                  <div className="mb-3">
                    <Text type={"heading"}>Security</Text>
                  </div>
                  <TableActionsContainer>
                    <div className="my-3 flex justify-content-between">
                      <div>
                        <Text>Password</Text>
                        <div className="mt-2">
                          <Text type={"heading"}>***********</Text>
                        </div>
                      </div>
                      <div>
                        <CustomButton
                          varient="outline"
                          label={"Change Password"}
                          onClick={() => setChangeShowPassword(true)}
                        />
                      </div>
                    </div>
                  </TableActionsContainer>
                </div>

                <div className="mt-3">
                  <TableActionsContainer>
                    <div className="mt-3">
                      <Text type={"sub-heading"}>Contact sales</Text>
                      <div className="mt-2">
                        <Text type={"heading"}>Golwin Paul</Text>
                      </div>
                      <div className="my-3">
                        <div className="flex">
                          <Text type={"sub-heading"}>Mobile:&nbsp;</Text>
                          <Text type={"heading"}>&nbsp;+91 9988776655</Text>
                        </div>
                        <div className="flex">
                          <Text type={"sub-heading"}>Email:&nbsp;</Text>
                          <Text type={"heading"}>
                            &nbsp;dev-group@deskala.in
                          </Text>
                        </div>
                      </div>
                    </div>
                  </TableActionsContainer>
                </div>
              </div>
              <div className="w-12 lg:w-4">
                <div className="flex flex-column justify-content-center align-items-center">
                  <Text type={"heading"}>Scan and Explore Skalebot</Text>
                  <div className="mt-3">
                    <TableActionsContainer>
                      <div className="w-full p-4">
                        {/* {userSub.isUserSub && (
                          <>
                            <QRCode
                              id="generatedQrCode"
                              size={156}
                              style={{
                                // height: "100%",
                                display: "block",
                                margin: "auto",
                              }}
                              value={`https://wa.me/${company?.phone}?text=Hi`}
                            />
                          </>
                        )} */}
                        {userSub.isUserSub ? (
                          <div className="flex  flex-align-center justify-content-center">
                            <div ref={ref} />
                          </div>
                        ) : (
                          <div className="w-12rem flex justify-content-center align-items-center border-dashed border-round-sm p-3 h-12rem">
                            <div className="w-12">
                              <Text type={"sub-heading"}>
                                Please contact sales to generate business QR
                                code{" "}
                              </Text>
                            </div>
                          </div>
                        )}
                        {userSub.isUserSub && (
                          <div className="mt-6 text-center">
                            <Text type={"sub-heading"}>
                              Powered by Skalebot
                            </Text>
                          </div>
                        )}
                      </div>
                    </TableActionsContainer>

                    {userSub?.userSubType === "none" ? (
                      ""
                    ) : (
                      <div className="mt-3 flex justify-content-center">
                        <CustomButton
                          varient="filled"
                          type={"button"}
                          onClick={onDownloadClick}
                          label={"Download QR"}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-6 flex flex-column justify-content-center align-items-center">
                  <PaymentQR />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`flex flex-wrap w-11 m-auto pb-8`}>
          <div className="ml-2 w-6 min-w-min flex justify-content-center flex-grow-1 panel-right"></div>
        </div>
      </div>
    </>
  );
}

export default UserProfile;
