import { useEffect, useState } from "react";
import TransactionAlert from "../../components/TransactionAlert";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import TrailDashboard from "../TrailDashboard";
import StatisticalRecord from "./StatisticalRecord";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import SectionSelection from "./SectionSelection";
import OrdersSection from "./Sections/OrdersSection";
import EnquiriesSection from "./Sections/EnquiriesSection";
import CampaignsSections from "./Sections/CampaignsSection";
import AgentsSections from "./Sections/AgentsSections";
import TicketsSections from "./Sections/TicketsSection";
import "./style.module.css";

ChartJS.register(ArcElement, Tooltip, Legend);

export const Dashboard = () => {
  let { transactionid } = useParams();
  const [status, setStatus] = useState(transactionid);
  const [transaction, setTransaction] = useState(false);
  const [recentOrderLoading, setRecentOrdersLoading] = useState(false);
  const [recentCampaignslaoding, setRecentCampaignsLoading] = useState(false);
  const { userSub } = useSelector((state) => state.authenticate);

  useEffect(() => {
    setTransaction(true);
    setStatus(transactionid);
    if (status === "success") {
      setTransaction(true);
      setStatus(transactionid);
    }
  }, [userSub.userSubType]);

  const [analyticSection, setAnalyticSection] = useState("Orders");
  const section = () => {
    switch (analyticSection) {
      case "Enquiries":
        return <EnquiriesSection />;
      case "Campaigns":
        return <CampaignsSections />;
      case "Agents":
        return <AgentsSections />;
      case "Tickets":
        return <TicketsSections />;
      case "Orders":
        return <OrdersSection />;
      default:
        return <></>;
    }
  };
  if (!userSub.isUserSub) {
    return (
      <div className="w-11 m-auto">
        <TrailDashboard />
      </div>
    );
  } else {
    return (
      <div className="bg-white cp-10">
        <Loader visible={recentCampaignslaoding || recentOrderLoading} />
        <TransactionAlert
          transaction={transaction}
          setTransaction={setTransaction}
          status={status}
        />

        <div className={` cp-10 pt-0  m-auto h-auto`}>
          <div className="">
            <StatisticalRecord />
          </div>
        </div>
        <div className={` cp-10 pt-0 m-auto h-auto`}>
          <SectionSelection
            analyticSection={analyticSection}
            setAnalyticSection={setAnalyticSection}
          />
        </div>
        <div className={`cp-10 pt-0 m-auto h-auto`}>{section()}</div>
      </div>
    );
  }
};
