import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import { Skeleton } from "primereact/skeleton";
import { Text } from "../../components/Text";
import { ReactComponent as GreenTickIcon } from "../../assets/svgIcons/greentick.svg";
import { CustomButton } from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { ReactComponent as EditIcon } from "../../svg/edit.svg";
import {
  changeUserToast,
  getAllUsers,
  handleAccountUser,
} from "../../reducers/accountSlice";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import Loader from "../../components/Loader";
import { useRef } from "react";
import { dateCompiler } from "../../utils/tableUtils";
import * as Messag from "../../config/ToastMessage";
import TextAutomation from "./TextAutomation";
import Tabview from "./Tabview";

const AccountSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toast = useRef(null);

  const { allUsers, superAdminUser, loading, userToast } = useSelector(
    (state) => state.account
  );
  const { user, conversation } = useSelector((state) => state.authenticate);
  const { company } = useSelector((state) => state.company);

  const [supportAgents, setSupportAgents] = useState([]);
  const [campaignUser, setCampaignUser] = useState([]);
  const [adminUser, setAdminUser] = useState([]);

  const handlePhoneNo = (phone) => {
    if (!phone) return;
    var phoneStr = phone.toString();
    if (phoneStr.length === 10) {
      phoneStr = "91" + phoneStr;
    }
    return parseInt(phoneStr);
  };

  const modalLoad = () => {
    return <Loader visible={loading} />;
  };

  const UserCard = (userData) => {
    if (!userData) return;
    return (
      <div
        className={`${styles["user-card"]} ${
          !userData.isActive ? styles["deactivated-user"] : ""
        } ${user.id === userData.id ? styles["current-user"] : ""} p-3`}
      >
        <div className="flex justify-content-between align-items-center">
          <Text type={"heading"} cust="bold">
            {userData.userName}
          </Text>
          {userData.id === superAdminUser?.id &&
          user.id !== superAdminUser?.id ? (
            ""
          ) : (
            <CustomButton
              varient={`icon-button`}
              icon={<EditIcon />}
              onClick={() => handleEditUser(userData)}
              style={{ padding: "0px" }}
            />
          )}
        </div>
        <Text cust={"mt-2"}>{userData.name}</Text>
        <Text cust={"mt-2"}>{userData.email}</Text>
        <Text cust={"mt-2"}>+{handlePhoneNo(userData.phone)}</Text>
        <Text type={"small-text"} cust="mt-3" style={{ color: "#1C738E" }}>
          {userData.isActive ? "Active" : "Inactive"}
        </Text>
      </div>
    );
  };

  useEffect(() => {
    dispatch(getAllUsers());
    if (userToast === "create") {
      toast.current.show({
        severity: "success",
        detail: Messag.Create_USER_ToastSuccessMessage,
      });
      dispatch(changeUserToast(""));
    } else if (userToast === "update") {
      toast.current.show({
        severity: "success",
        detail: Messag.Update_USER_ToastSuccessMessage,
      });
      dispatch(changeUserToast(""));
    } else if (userToast === "delete") {
      toast.current.show({
        severity: "success",
        detail: Messag.Delete_USER_ToastSuccessMessage,
      });
      dispatch(changeUserToast(""));
    }
  }, []);

  useEffect(() => {
    const supportUsers = allUsers.filter((user) => user.role === "support");
    const campaignUsers = allUsers.filter((user) => user.role === "campaign");
    const adminUsers = allUsers.filter(
      (user) => user.role === "admin" || user.role == "superadmin"
    );

    setSupportAgents(supportUsers);
    setCampaignUser(campaignUsers);
    setAdminUser(adminUsers);
  }, [allUsers]);

  const createNewUser = () => {
    navigate("./new");
  };

  const handleEditUser = (userData) => {
    if (userData.id === superAdminUser?.id && user.id !== superAdminUser?.id)
      return;
    dispatch(handleAccountUser(userData));
    navigate(`./${userData.id}`);
  };

  return (
    <div className="bg-white">
      <Toast ref={toast} />
      {modalLoad()}
      <div className={`${styles["record-wrap-stat"]} m-3 p-3`}>
        <div className="text-xm font-semibold">Account Information</div>

        <div className={`md:flex align-items-center`}>
          <div className="flex flex-wrap align-items-center md:mr-8">
            <div className="flex justify-content-between align-items-center w-6rem h-8rem">
              <img
                src={company?.url}
                onError={(e) => (e.target.src = "./svgIcons/UserIcon.svg")}
                alt="Company logo"
                className="logoImg w-8rem h-8rem"
              />
            </div>
            <div className="mb-3 sm:mb-0">
              <div className="text-xm font-semibold">Account Name</div>
              <div className="font-semibold flex flex-row gap-2  align-items-center h-2rem">
                <div className="pt-2">{company?.companyName}</div>
                <div className="h-1rem ">
                  <GreenTickIcon />
                </div>
              </div>
              <div className="flex flex-row py-1 ">
                <div className="font-normal mr-2">Plan validity :</div>
                <div className=" font-semibold">
                  {dateCompiler(conversation.endTime)}
                </div>
              </div>
            </div>
          </div>

          <div className="flex-grow flex align-items-center gap-3">
            <div className="flex gap-2">
              <div className={`${styles["v-line"]} hidden md:block`}></div>
              <div>
                {loading ? (
                  <Skeleton height="1.5rem" />
                ) : (
                  <Text type={"heading"}>
                    <span style={{ fontWeight: "400", fontSize: "14px" }}>
                      {supportAgents.length}
                    </span>
                  </Text>
                )}

                <Text
                  type={"sub-heading"}
                  color={"rgba(0, 0, 0, 1)"}
                  style={{
                    fontWeight: "600",
                    fontSize: "14px",
                    marginTop: ".8rem",
                  }}
                >
                  Support Agents
                </Text>
              </div>
            </div>
            <div className="flex gap-2">
              <div className={`${styles["v-line"]}`}></div>

              <div>
                {loading ? (
                  <Skeleton height="1.5rem" />
                ) : (
                  <Text type={"heading"}>
                    <span style={{ fontWeight: "400", fontSize: "14px" }}>
                      {campaignUser.length}
                    </span>
                  </Text>
                )}

                <Text
                  type={"sub-heading"}
                  color={"rgba(0, 0, 0, 1)"}
                  style={{
                    fontWeight: "600",
                    fontSize: "14px",
                    marginTop: ".8rem",
                  }}
                >
                  Campaign Users
                </Text>
              </div>
            </div>

            <div className="flex gap-2">
              <div className={`${styles["v-line"]} `}></div>
              <div>
                {loading ? (
                  <Skeleton height="1.5rem" />
                ) : (
                  <Text type={"heading"}>
                    <span style={{ fontWeight: "400", fontSize: "14px" }}>
                      {adminUser.length}
                    </span>
                  </Text>
                )}

                <Text
                  type={"sub-heading"}
                  color={"rgba(0, 0, 0, 1)"}
                  style={{
                    fontWeight: "600",
                    fontSize: "14px",
                    marginTop: ".8rem",
                  }}
                >
                  Admin Users
                </Text>
              </div>
            </div>
          </div>
        </div>

        {/* <div className={`${styles["hr-line"]} mt-4 md:mt-0`}></div> */}
      </div>
      <div className={`px-3`}>
        <Tabview />
      </div>
    </div>
  );
};

export default AccountSettings;
