import { Image } from "primereact/image";
import { Text } from "../Text";
import styles from "./stylesheets/startTag.module.css";

export const StartTag = () => {
  return (
    <div className="flex">
      <div
        className={`px-3 py-2  mb-2 flex align-items-center ${styles.style_tag}`}
      >
        <Image src={"/svgIcons/skalebot-icon.svg"} className="mr-2" />
        <Text type={"heading"}>Start</Text>
      </div>
    </div>
  );
};
