import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomPaginator from "../../components/CustomPaginator";
import { CustomButton } from "../../components/CustomButton";
import CreateGroup from "../../components/CreateGroup";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { ReactComponent as Bluecheck } from "../../svg/blueright.svg";
import { ReactComponent as Greencheck } from "../../svg/greenright.svg";
import { ReactComponent as Greycheck } from "../../svg/greyright.svg";
import { ReactComponent as Star } from "../../svg/star.svg";
import { ReactComponent as Replied } from "../../svg/Replied.svg";
import { ReactComponent as Failed } from "../../svg/fail.svg";

import {
  getCampaignCustomers,
  getCampaignsAnalytics,
  changecampCustomerPage,
  changelimit,
  clearCampaignCustomers,
} from "../../reducers/campaignSlice";
import "./customerAnalyticsStyle.css";
import TableActionsContainer from "../../components/CustomTable/TableActionsContainer";
import { useParams } from "react-router-dom";
import { Text } from "../../components/Text";
import { OverlayPanel } from "primereact/overlaypanel";
import * as XLSX from "xlsx";
import { formatMobNumber } from "../../utils/commonUtils";
const CustomerAnalyticsDataView = () => {
  const [createGrpModule, setCreateGrpModule] = useState(false);
  const {
    selectedCampaignId,
    campaignCustomers,
    campCustomerPage,
    stats,
    limit,
    totalCampaignCustomersCount,
    campaignType,
  } = useSelector((state) => state.campaign);
  const { user } = useSelector((state) => state.authenticate);

  const [filterStatusValue, setFilterStatusValue] = useState("All");

  const dispatch = useDispatch();
  const toast = useRef();
  const { id } = useParams();
  const errorRef = useRef(null);

  const filterStatusFiled = () => {
    return (
      <div className="my-3 flex align-items-center gap-3 overflow-auto py-2">
        <div
          className={`flex p-2 border-round cursor-pointer ${
            filterStatusValue === "All" ? "select_stat" : ""
          }`}
          onClick={() => onfilterStatusValueChange("All")}
        >
          <div className="w_max_content">
            &nbsp;Conversations&nbsp;:[{stats.All}]
          </div>
        </div>
        <div
          className={`flex p-2 border-round cursor-pointer ${
            filterStatusValue === "Sent" ? "select_stat" : ""
          }`}
          onClick={() => onfilterStatusValueChange("Sent")}
        >
          <Greycheck />
          <div>&nbsp;Sent&nbsp;[{stats.sent}]</div>
        </div>
        <div
          className={`flex p-2 border-round cursor-pointer ${
            filterStatusValue === "Delivered" ? "select_stat" : ""
          }`}
          onClick={() => onfilterStatusValueChange("Delivered")}
        >
          <Greencheck />
          <div>&nbsp;Delivered&nbsp;[{stats.delivered}]</div>
        </div>
        <div
          className={`flex p-2 border-round cursor-pointer ${
            filterStatusValue === "Read" ? "select_stat" : ""
          }`}
          onClick={() => onfilterStatusValueChange("Read")}
        >
          <Bluecheck />
          <div>&nbsp;Read&nbsp;[{stats.read}]</div>
        </div>
        <div
          className={`flex p-2 border-round cursor-pointer ${
            filterStatusValue === "replied" ? "select_stat" : ""
          }`}
          onClick={() => onfilterStatusValueChange("replied")}
        >
          <Replied />
          <div>&nbsp;Replied&nbsp;[{stats.replied}]</div>
        </div>
        <div
          className={`flex p-2 border-round cursor-pointer ${
            filterStatusValue === "opt-out" ? "select_stat" : ""
          }`}
          onClick={() => onfilterStatusValueChange("opt-out")}
        >
          <Star />
          <div className="w_max_content">
            &nbsp;Opt-Out&nbsp;[{stats["opt-out"]}]
          </div>
        </div>
        <div
          className={`flex p-2 border-round cursor-pointer ${
            filterStatusValue === "failed" ? "select_stat" : ""
          }`}
          onClick={() => onfilterStatusValueChange("failed")}
        >
          <Failed />
          <div>&nbsp;Failed&nbsp;[{stats.failed}]</div>
        </div>
      </div>
    );
  };

  const CreateGroupModule = () => {
    return (
      <CreateGroup
        customersFrom="campaign"
        campCustomerStatus={filterStatusValue}
        createGrpModule={createGrpModule}
        setCreateGrpModule={setCreateGrpModule}
        toast={toast}
      />
    );
  };

  const exportToExcel = () => {
    // Create a worksheet from the selected rows
    const worksheet = XLSX.utils.json_to_sheet(
      campaignCustomers.map((row) => ({
        "Customer Name": row?.name || "",
        Phone: formatMobNumber(row?.phone),
        Status: row?.status || "",
        CTA: row?.replyButton || "",
      }))
    );

    // Create a new workbook and add the worksheet to it
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      "CustomerAnalyticsDataView"
    );
    // Generate buffer and trigger download
    XLSX.writeFile(workbook, `Customer_resp_campaign${id}.xlsx`);
  };

  const grpbtn = () => {
    return (
      <div className="flex  justify-content-end  gap-3">
        <CustomButton
          varient="text"
          label="Export"
          icon="pi pi-file-excel"
          onClick={exportToExcel}
        />
        {user.role !== "support" && (
          <CustomButton
            varient="filled"
            label={"Create a Group"}
            onClick={() => {
              setCreateGrpModule(true);
            }}
          />
        )}
      </div>
    );
  };

  const onfilterStatusValueChange = (e) => {
    const value = e;
    setFilterStatusValue(value);
  };

  const phoneBodyTemplate = (rowdata) => {
    return `+${rowdata.phone}`;
  };

  const failedIcon = (errorMessage) => {
    return (
      <div className="relative">
        <i
          className=" pi pi-info-circle cursor-pointer"
          key={(Math.random() * 100) | 0}
          style={{ fontSize: "0.8rem", color: "grey" }}
          data-pr-tooltip={errorMessage}
          onClick={(e) => errorRef.current.toggle(e)}
        />
        <OverlayPanel ref={errorRef}>
          <Text type={"sub-heading"}>Error&nbsp;:&nbsp;{errorMessage}</Text>
        </OverlayPanel>
      </div>
    );
  };

  const stausBodyTemplate = (rowdata) => {
    const isStatusFailed = rowdata.status === "failed";
    return (
      <div className="flex align-items-center gap-2">
        <Text>{rowdata.status}</Text>
        {isStatusFailed && failedIcon(rowdata?.error || "")}
      </div>
    );
  };

  useEffect(() => {
    if (!selectedCampaignId) return;
    const page = campCustomerPage;

    dispatch(
      getCampaignCustomers({ id, page, limit, status: filterStatusValue })
    )
      .unwrap()
      .catch((err) => {
        //show toast here
      });

    return () => {
      dispatch(clearCampaignCustomers());
    };
  }, [selectedCampaignId, campCustomerPage, limit, filterStatusValue]);

  return (
    <div className="p-1 pt-2">
      <Toast ref={toast} />
      {createGrpModule ? CreateGroupModule() : <></>}
      {id[0] !== "s" && filterStatusFiled()}
      <DataTable
        key={"id"}
        value={campaignCustomers}
        className="skalebot-table "
        responsiveLayout="scroll"
        emptyMessage="No data found."
        dataKey="id"
        resizableColumns
        columnResizeMode="expand"
      >
        <Column
          field="name"
          header="Customer Name"
          bodyStyle={{ height: "50px" }}
        />
        <Column field="phone" header="Phone" body={phoneBodyTemplate} />
        <Column
          field="status"
          header="Status"
          bodyStyle={{ textTransform: "capitalize" }}
          body={stausBodyTemplate}
        />
        <Column
          field="replyButton"
          header="CTA"
          bodyStyle={{ textTransform: "capitalize" }}
        />
        <Column
          headerStyle={{ display: "flex", justifyContent: "flex-end" }}
          header={grpbtn}
          body={null}
          bodyStyle={{ border: "none", minWidth: "150px" }}
        />
      </DataTable>
      <div className="mt-3">
        <TableActionsContainer>
          <div className="flex  justify-content-end">
            <CustomPaginator
              page={campCustomerPage}
              limit={limit}
              changelimit={changelimit}
              totalRecords={totalCampaignCustomersCount}
              changePage={changecampCustomerPage}
            />
          </div>
        </TableActionsContainer>
      </div>
    </div>
  );
};

export default CustomerAnalyticsDataView;
