import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { ChevronDownIcon } from "primereact/icons/chevrondown";
import { ChevronRightIcon } from "primereact/icons/chevronright";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../api/axios.instance";
import { changeCompanyId } from "../../reducers/authSlice";
import { resetCompany } from "../../reducers/companySlice";

function AccountChanger() {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({});

  // const { company } = useSelector((state) => state.company);
  const { user } = useSelector((state) => state.authenticate);

  const dispatch = useDispatch();

  let companyKey = JSON.parse(localStorage.getItem("companykey"));

  useEffect(() => {
    axiosInstance.get("/user/child/company").then((s) => {
      setCompanies([...s.data]);
    });
    return () => {
      setSelectedCompany({});
      // dispatch(resetCompany());
    };
  }, []);

  useEffect(() => {
    if (user?.companyId) {
      if (!companyKey?.id) {
        let _companyKey = {
          id: user?.companyId,
        };
        setSelectedCompany({ ..._companyKey });
        localStorage.setItem("companykey", JSON.stringify({ ..._companyKey }));
      } else {
        let _companyKey = {
          id: user?.companyId,
        };

        setSelectedCompany({ ...companyKey });
      }
    }
  }, [user]);

  const selectedCompanyTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center gap-2 company-dp-background">
          <div className="text-bold">{option.companyName}</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const companyOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center company-dp-background">
        {option.companyName}
      </div>
    );
  };

  const changeorganization = (e) => {
    setSelectedCompany({ id: e.value });
    // dispatch(changeCompanyId(e.value.id));
    localStorage.setItem("companykey", JSON.stringify({ id: e.value }));
    window.location.reload();
  };

  return (
    <div className="flex flex-row align-items-center gap-2 " style={{border:"1px solid #F8F9FE", borderRadius:"4px"}} >
      <Dropdown
        value={selectedCompany.id}
        onChange={changeorganization}
        options={companies}
        optionValue="id"
        optionLabel="companyName"
        style={{ border: "transparent", minHeight: "2rem" }}
        panelClassName="company-dp-background"
        valueTemplate={selectedCompanyTemplate}
        itemTemplate={companyOptionTemplate}
        className="w-full md:w-14rem company-dp-background"
        dropdownIcon={(opts) => {
          return opts.iconProps["data-pr-overlay-visible"] ? (
            <ChevronRightIcon {...opts.iconProps} />
          ) : (
            <ChevronDownIcon {...opts.iconProps} />
          );
        }}
      />
    </div>
  );
}

export default AccountChanger;
