import { useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import { CustomButton } from "../../components/CustomButton";
import TableActionsContainer from "../../components/CustomTable/TableActionsContainer";
import { Text } from "../../components/Text";
import { useState } from "react";
import { RadioButton } from "primereact/radiobutton";
import TopUp from "./TopUp";
import MonthlySubs from "./MonthlySubs";
import { useDispatch, useSelector } from "react-redux";
import { sendPayment } from "../../reducers/paymentSlice";
import { useRef } from "react";
import { Toast } from "primereact/toast";
const rechargeTypes = [
  {
    id: "sub001",
    label: "Topup",
    value: "topup",
  },
  // {
  //   id: "sub002",
  //   label: "Monthly subscription",
  //   value: "monthly",
  // },
];
function Recharge() {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [subscriptionType, setSubscriptionType] = useState("topup");
  const { user } = useSelector((state) => state.authenticate);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useRef();

  const breadcumbHome = { label: "Dashboard", url: "/dashboard" };
  const breadcumbList = [{ label: `Recharge`, url: "#" }];

  const handlePay = (plan) => {
    let data = {
      productType: subscriptionType.toUpperCase(),
      ...user,
      ...plan,
    };
    dispatch(sendPayment({ data }))
      .unwrap()
      .then((res) => {})
      .catch((err) => {
        toast.current.show({ severity: "error", detail: err.message });
        //  alert("err", err);
      });
  };

  const subscriptionForm = (sub) => {
    switch (sub) {
      case "topup":
        return (
          <TopUp topup={selectedPlan} handleChangeTopUp={setSelectedPlan} />
        );
      case "monthly":
        return (
          <MonthlySubs
            plan={selectedPlan}
            agentCharge={1200}
            handlePlanChange={setSelectedPlan}
            plateFormFees={1000}
            noOfAgent={4}
          />
        );
      default:
        return (
          <TopUp topup={selectedPlan} handleChangeTopUp={setSelectedPlan} />
        );
    }
  };

  return (
    <div className="w-full">
      <Toast ref={toast} />
      <CustomBreadcrumb home={breadcumbHome} itemslist={breadcumbList} />
      <div className="m-2 pl-3">
        <TableActionsContainer>
          <div className="flex justify-content-between align-items-center">
            <Text type={"heading"}>Recharge</Text>
            <CustomButton
              varient="outline"
              label={"Cancel"}
              onClick={() => navigate("/dashboard")}
            />
          </div>
        </TableActionsContainer>
      </div>
      <div className="flex justify-content-center">
        <div className="mt-4 w-11 lg:w-4">
          <div className="card ml-3 flex justify-content-start">
            <div className="flex flex-wrap gap-3">
              {rechargeTypes?.map((element, i) => (
                <div className="flex align-items-center" key={i}>
                  <RadioButton
                    inputId={element.id}
                    name="recharge"
                    value={element.value}
                    onChange={(e) => setSubscriptionType(e.value)}
                    checked={subscriptionType === element.value}
                  />
                  <Text className={"sub-heading"}>
                    <label htmlFor={element.id} className="ml-2">
                      {element.label}
                    </label>
                  </Text>
                </div>
              ))}
            </div>
          </div>
          <div className="w-full my-4">
            {subscriptionForm(subscriptionType)}
          </div>

          <CustomButton
            varient={"filled"}
            label={<span>Pay &#x20B9;{selectedPlan?.amount}</span>}
            type="button"
            onClick={() => handlePay(selectedPlan)}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
}

export default Recharge;
