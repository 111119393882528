import Header from "./Header";
import Welcome from "./Welcome";
import VideoTour from "./VideoTour";
import PaymentPlan from "./paymentPlan";
import Footer from "./Footer";
import SalesTeam from "./SalesTeam";

export const Login = () => {
  return (
    <div className={` flex flex-column w-full`}>
      <Header />
      <Welcome />
      <VideoTour />
      <PaymentPlan />
      <SalesTeam/>
      <Footer />
    </div>
  );
};
