import { useLocation, useNavigate, useParams } from "react-router";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import { CustomButton } from "../../components/CustomButton";
import TableActionsContainer from "../../components/CustomTable/TableActionsContainer";
import { Text } from "../../components/Text";
import { useDispatch, useSelector } from "react-redux";
import CustomDropdown from "../../components/common/CustomDropdown";
import CustomInputText from "../../components/common/CustomInputText";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useForm, Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import { useRef } from "react";
import Loader from "../../components/Loader";
import { Toast } from "primereact/toast";
import {
  changeTemplateMode,
  resetSelectedTemplate,
} from "../../reducers/templateReducer/templateSlice";
import {
  addTemplate,
  getTemplatesbyId,
  getTemplatesbyappId,
  updateTemplate,
} from "../../reducers/templateReducer/templateActions";
import { ReactComponent as Emoji } from "../../assets/svgIcons/emoji.svg";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { Menu } from "primereact/menu";
import { RadioButton } from "primereact/radiobutton";
import TemplatePreview from "../../components/TemplatePreview";
import {
  languageOptions,
  templateTypes,
  buttonTypes,
  tempCategories,
  formateMessageInput,
  extractVariablesFormMessage,
  tempProductButons,
  validateMediaSize,
  isVariableExist,
  templeteVariablesType,
  formatBodyContent,
  formatVariableArr,
  isValidURL,
} from "../../utils/templateUtils";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import CONSTANTS from "../../config/Constants";
import CustomSaveModel from "../../components/CustomeSaveModel";
import * as Messag from "../../config/ToastMessage";
import modulesName from "../../roles/modulesName";
import permissions from "../../roles/permissions";
import { useUtility } from "../../context/UtilityContext";

function NewTemplate() {
  const location = useLocation();
  const { id } = useParams();
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [documentPreview, setDocumentPreview] = useState(null);
  const [templateButtons, setTempBtn] = useState([]);
  const [tempVariables, setTempvariables] = useState([]);
  const [showEmoji, setShowEmoji] = useState(false);
  const [seletedButtonType, setSelectedButtonType] = useState("none");
  const { templateMode, loading } = useSelector((state) => state.template);
  const [showSave, setShowSave] = useState(false);
  const [templateConfig, setTemplateConfig] = useState({});
  const [editTemplate, setEditTemplate] = useState(null);

  const navigate = useNavigate();
  const toast = useRef(null);
  const dispatch = useDispatch();
  const mediaFileRef = useRef();
  const menuRef = useRef(null);
  const textAreaRef = useRef(null);
  const { setAlert, navigation } = useUtility();
  const [messageVarCount, setMessageVarCount] = useState(1);
  const messsageVariables = [
    {
      label: "Customer Name",
      value: "{{customerName}}",
      command: (e) => {
        addVariablesClick(e.item.value);
      },
    },
    {
      label: "Customer Mobile No",
      value: "{{customerMobile}}",
      command: (e) => {
        addVariablesClick(e.item.value);
      },
    },
    {
      label: "Customer Email",
      value: "{{customerEmail}}",
      command: (e) => {
        addVariablesClick(e.item.value);
      },
    },
    {
      label: "Message",
      value: `{{message${messageVarCount}}}`,
      command: (e) => {
        addVariablesClick(e.item.value);
        setMessageVarCount((prev) => prev + 1);
      },
    },
  ];
  const { accesskey } = useSelector((state) => state.authenticate);
  useEffect(() => {
    return () => {
      setAlert(false);
    };
  }, []);
  const breadcumbHome = { label: "Templates", url: "/campaign-templates/list" };
  const breadcumbList = [
    {
      label: `${templateMode == "update" ? "Update" : "New"} Template`,
      url: "#",
    },
  ];

  const defaultValues = {
    elementName: "",
    languageCode: "en",
    category: "MARKETING",
    templateType: "TEXT",
    header: "",
    mediaId: "",
    vertical: "",
    content: "",
    example: "",
    footer: "",
  };
  //function form get error message
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };
  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
    watch,
    reset,
    setValue,
  } = useForm({ defaultValues });

  const watchHeaderType = watch("templateType");
  const watchHeader = watch("header");
  const watchFooter = watch("footer");
  const watchContent = watch("content");

  const previewFile = (e) => {
    // Reading New File (open file Picker Box)
    const reader = new FileReader();
    // Gettting Selected File (user can select multiple but we are choosing only one)
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      reader.readAsDataURL(selectedFile);
    }
    // As the File loaded then set the stage as per the file type
    reader.onload = (readerEvent) => {
      if (selectedFile.type.includes("image")) {
        setImagePreview(readerEvent.target.result);
      } else if (selectedFile.type.includes("video")) {
        setVideoPreview(readerEvent.target.result);
      } else if (selectedFile.type.includes("pdf")) {
        setDocumentPreview(readerEvent.target.result);
      }
    };
  };

  const resetHeader = () => {
    watchHeader && setValue("header", "");
    file && setFile(null);
    imagePreview && setImagePreview(null);
    videoPreview && setVideoPreview(null);
    documentPreview && setDocumentPreview(null);
  };

  const handleTemplateTypeRadioSelect = (e) => {
    resetHeader();
    setValue("templateType", e.value.value);
  };

  const headerContainer = () => {
    const mediaSelect = (icon, accept) => {
      if (file) {
        var { isValid, acceptedSize } = validateMediaSize(
          file?.size,
          watchHeaderType.toLowerCase()
        );
      }

      return !file ? (
        <div className="field">
          <input
            ref={mediaFileRef}
            type="file"
            accept={`${accept}`}
            hidden
            onChange={previewFile}
          />
          <button
            type="button"
            className="w-full temp-img flex gap-3 p-3 my-2 cursor-pointer"
            onClick={() => mediaFileRef.current.click()}
          >
            <i className={`pi ${icon}`} />
            <Text type={"sub-heading"}>
              Choose {watchHeaderType.toLowerCase()}
            </Text>
          </button>
        </div>
      ) : (
        <>
          <div className="temp-img-upload">
            <div className="flex gap-2 justify-content-between align-items-center">
              <div className="flex gap-2 align-items-center">
                <i className={`pi ${icon}`} />
                <Text type={"sub-heading"}>{file.name}</Text>
              </div>
              <i
                className="pi pi-trash cursor-pointer"
                style={{ float: "right" }}
                onClick={handleDeleteMedia}
              />
            </div>
          </div>
          {!isValid && (
            <div className="mb-2">
              <small className="p-error ">
                {watchHeaderType.toLowerCase()} size should be less than{" "}
                {acceptedSize} MB.
              </small>
            </div>
          )}
        </>
      );
    };

    const handleDeleteMedia = () => {
      setFile(null);
      switch (watchHeaderType) {
        case "IMAGE":
          setImagePreview(null);
          break;
        case "VIDEO":
          setVideoPreview(null);
        case "Document":
          setDocumentPreview(null);
        default:
          break;
      }
    };

    switch (watchHeaderType) {
      case "TEXT":
        return (
          <div className="w-full">
            <CustomInputText
              name="header"
              label="Header"
              control={control}
              placeholer="Enter Header Text"
              required={false}
              maxLength={60}
            />
          </div>
        );
      case "IMAGE":
        return mediaSelect("pi-image", "image/jpeg, image/png");
      case "VIDEO":
        // previously using this
        // return mediaSelect("pi-video", "video/*");
        return mediaSelect("pi-video", "video/mp4");
      case "DOCUMENT":
        return mediaSelect("pi-file", ".doc,docx,.pdf");
      default:
        return <></>;
    }
  };

  const addVariablesClick = (varlabel) => {
    const input = textAreaRef.current;
    const textAreavalue = watchContent || "";
    if (!input) return;
    const { selectionStart, selectionEnd } = input;
    const newValue =
      textAreavalue.slice(0, selectionStart) +
      varlabel +
      textAreavalue.slice(selectionEnd);

    // Set the cursor position after the inserted text
    const newPosition = selectionStart + varlabel.length;
    textAreaRef.current.value = newValue;
    input.setSelectionRange(newPosition, newPosition);
    input.focus();
    let newVarObj = {
      id: Math.floor(1000 + Math.random() * 9000),
      variableName: `${varlabel}`,
      variableValue: "",
      variableType: varlabel.includes("customer")
        ? templeteVariablesType.varlabel
        : "customNote",
    };
    if (!isVariableExist(newVarObj, tempVariables)) {
      setTempvariables((prev) => [...prev, newVarObj]);
    }
    setValue("content", newValue);
  };
  const handleEditVariable = (e, id) => {
    let updateVariableData = tempVariables.map((item) => {
      if (item.id !== id) return item;
      else return { ...item, variableValue: e.target.value };
    });
    setTempvariables(updateVariableData);
  };
  const handleButtonEdit = (e, id, key) => {
    let updatedButtons = templateButtons.map((btn) => {
      if (btn.id !== id) return btn;
      else
        return {
          ...btn,
          [`${key}`]: key == "phone_number" ? e : e.target.value,
        };
    });
    setTempBtn(updatedButtons);
  };

  const formatButtonData = (buttons) => {
    let buttonsData = buttons.map((btn) => {
        let newBtn = { ...btn, text: btn.text.trim() }; 
        delete newBtn.id;
        delete newBtn.isSaved;
        return newBtn;
    });

    return buttonsData;
};

  const handleEmojiClick = (e) => {
    const emoji = e.native;
    const input = textAreaRef.current;
    const textAreavalue = watchContent || "";
    if (!input) return;

    const { selectionStart, selectionEnd } = input;
    const newValue =
      textAreavalue.substring(0, selectionStart) +
      emoji +
      textAreavalue.substring(selectionEnd);
    // Set the cursor position after the inserted text
    const newPosition = selectionStart + emoji.length;
    textAreaRef.current.value = newValue;
    input.setSelectionRange(newPosition, newPosition);
    input.focus();
    // Move caret position after the inserted emoji
    // input.selectionStart = input.selectionEnd = selectionStart + emoji.length;
    setValue("content", newValue);
  };

  const handleButtonTypeSelect = (e) => {
    const btnType = e.value.value;
    setSelectedButtonType(btnType);
    let buttonObj = {
      id: Math.floor(1000 + Math.random() * 9000),
      text: "",
      isSaved: false,
      type: btnType,
    };
    switch (btnType) {
      case "PHONE_NUMBER":
        buttonObj["phone_number"] = "";
        setTempBtn([buttonObj]);
        break;
      case "URL":
        buttonObj["url"] = "";
        setTempBtn([buttonObj]);
        break;
      case "PRODUCT":
        setTempBtn(tempProductButons);
        break;
      default:
        setTempBtn([]);
    }
  };

  const saveTemplate = (configData) => {
    setShowSave(false);
    if (templateMode === "create" || templateMode == "clone") {
      dispatch(addTemplate({ configData, media: file }))
        .unwrap()
        .then(() => {
          toast.current.show({
            severity: "success",
            detail: Messag.Add_Template_ToastSuccessMessage,
          });
          navigate(`/campaign-templates/list`);
        })
        .catch((err) => {
          toast.current.show({
            severity: "error",
            detail: err.message,
          });
        });
    } else if (templateMode === "update") {
      dispatch(updateTemplate({ configData, media: file, id }))
        .unwrap()
        .then(() => {
          toast.current.show({
            severity: "success",
            detail: Messag.Update_Template_ToastSuccessMessage,
          });
          navigate(`/campaign-templates/list`);
        })
        .catch((err) => {
          toast.current.show({
            severity: "error",
            detail: err.message,
          });
        });
    }
  };

  const onSubmit = (data) => {
    switch (seletedButtonType) {
      case "PHONE_NUMBER":
        if (
          !isValidPhoneNumber(templateButtons[0].phone_number) ||
          !templateButtons[0].text?.trim()
        ) {
          return;
        }
        break;
      case "URL":
        if (
          !isValidURL(templateButtons[0].url) ||
          !templateButtons[0].text?.trim()
        ) {
          return;
        }
        break;
      case "QUICK_REPLY":
        let isQuickTextEmpty = false;
        templateButtons?.forEach(btn => {
          if (btn.text.trim() === "") {
            isQuickTextEmpty = true
            return
          }
        })
        if (templateButtons.length == 0) {
          toast.current.show({
            severity: "warn",
            detail: "Please select button for quick reply.",
          });
          return;
        } else if (isQuickTextEmpty) {
          toast.current.show({
            severity: "warn",
            detail: "Please add text for quick reply buttons, it can't be empty",
          });
          return;
        }
        break;
      default:
        break;
    }

    if (!file && watchHeaderType !== "TEXT") {
      toast.current.show({
        severity: "warn",
        detail: "Please selece a media file.",
      });
      setShowSave(false);
      return;
    }
    if (watchHeaderType !== "TEXT") {
      let { isValid, acceptedSize } = validateMediaSize(
        file.size,
        watchHeaderType.toLowerCase()
      );
      if (!isValid) {
        toast.current.show({
          severity: "warn",
          detail: `Please select ${watchHeaderType}, less than ${acceptedSize} MB in size`,
        });
        setShowSave(false);
        return;
      }
    }
    const { content } = data;

    let isCampainTypeProduct = false
    templateButtons?.forEach(btn => {
      if (btn?.text === "Menu") {
        isCampainTypeProduct = true
        return
      } 
    })

    const configData = {
      template: {
        ...data,
        header: data?.header || "",
        buttons:
          templateButtons.length != 0
            ? JSON.stringify(formatButtonData(templateButtons))
            : [],
        content: formatBodyContent(content),
        example: formateMessageInput(content, "submit", tempVariables),
        exampleHeader: data?.header || "",
        enableSample: true,
        allowTemplateCategoryChange: true,
        checkerApprovalRequired: false,
      },
      variables: formatVariableArr(content, tempVariables),
      campaignType: isCampainTypeProduct ? "product" : "",
    };
    setTemplateConfig(configData);
    setShowSave(true);
  };

  const setFormValueOnUpdateMode = (selectedTemplate, mode) => {
    const {
      containerMeta,
      elementName,
      languageCode,
      category,
      templateType,
      variables,
      id,
      vertical,
    } = selectedTemplate;
    const { data, header, footer, sampleMedia, buttons, mediaId } =
      JSON.parse(containerMeta);
    if (templateType.toUpperCase() !== "TEXT") {
      axios
        .get(
          `https://api.gupshup.io/wa/${selectedTemplate.appId}/wa/media/${mediaId}?download=false`,
          { responseType: "blob" }
        )
        .then((res) => {
          return res.data;
        })
        .then((e) => {
          return new File([e], `file.${e.type.split(`/`)[1]}`, {
            type: e.type,
          });
        })
        .then((e) => {
          const reader = new FileReader();
          const selectedFile = e;
          if (selectedFile) {
            setFile(selectedFile);
            reader.readAsDataURL(selectedFile);
          }
          reader.onload = (readerEvent) => {
            if (selectedFile.type.includes("image")) {
              setImagePreview(readerEvent.target.result);
            } else if (selectedFile.type.includes("video")) {
              setVideoPreview(readerEvent.target.result);
            } else if (selectedFile.type.includes("pdf")) {
              setDocumentPreview(readerEvent.target.result);
            }
          };
        })
        .catch((e) => {
          console.log(e);
        });
    }

    if (mode == "clone") {
      setValue("elementName", elementName + "_copy");
    } else {
      setValue("elementName", elementName);
    }
    setValue("category", category);
    setValue("languageCode", languageCode);
    setValue("templateType", templateType);
    setValue("header", header || "");
    setValue("footer", footer || "");
    setValue("vertical", vertical);
    setValue("mediaId", sampleMedia);
    let messStr = data;
    if (variables && variables.length != 0) {
      let localMessageVarCount = 1;
      let templateVars = [];
      variables?.forEach((item) => {
        messStr = messStr.replace(
          item.variableName,
          `{{${
            item.variableType == "customNote"
              ? `message${localMessageVarCount}`
              : item.variableType
          }}}`
        );
        let varObj = {
          ...item,
          variableName:
            item.variableType == "customNote"
              ? `{{message${localMessageVarCount++}}}`
              : `{{${item.variableType}}}`,
          id: Math.floor(1000 + Math.random() * 9000),
          variableValue: item.variableValue || "",
        };
        if (!isVariableExist(varObj, templateVars)) {
          templateVars.push(varObj);
        }
      });
      setTempvariables(templateVars);
      setMessageVarCount(localMessageVarCount);
    }
    let btnType = buttons[0]?.type;
    if (btnType) setSelectedButtonType(btnType);
    else setSelectedButtonType("none");

    if (buttons && buttons.length != 0) {
      if (buttons[0]?.type === "PHONE_NUMBER") {
        let temp = buttons[0]?.["phone_number"].replace("+", "");
        setValue(btnType.toUpperCase(), "+" + temp, {
          shouldValidate: true,
        });
      }
      if (buttons[0]?.type === "URL") {
        setValue("url", buttons[0]?.["url"], { shouldValidate: true });
      }

      let tempBtns = buttons.map((btn) => {
        return {
          ...btn,
          id: Math.floor(1000 + Math.random() * 9000),
          isSaved: btnType == "QUICK_REPLY" ? true : false,
        };
      });
      setTempBtn(tempBtns);
    }
    setValue("content", messStr);
  };

  const handleReset = () => {
    if (templateMode == "create") {
      reset();
      setTempBtn([]);
      setTempvariables([]);
      setFile(null);
      setSelectedButtonType("none");
    } else if (editTemplate != null) {
      setFormValueOnUpdateMode(editTemplate);
    } else {
      setValue("templateType", "TEXT");
      setValue("languageCode", "en");
      setValue("category", "MARKETING");
    }
  };

  useEffect(() => {
    let allvariablesInContent = extractVariablesFormMessage(watchContent);
    let filterTempVars = [];
    let newVars = [];

    for (let i = 0; i < allvariablesInContent.length; i++) {
      if (!isVariableExist(allvariablesInContent[i], tempVariables)) {
        newVars.push(allvariablesInContent[i]);
      }
    }
    for (let i = 0; i < tempVariables.length; i++) {
      if (isVariableExist(tempVariables[i], allvariablesInContent)) {
        filterTempVars.push(tempVariables[i]);
      }
    }
    setTempvariables([...filterTempVars, ...newVars]);
    if (watchContent == undefined || !watchContent.trim())
      setMessageVarCount(1);
  }, [watchContent]);

  useEffect(() => {
    if (id != undefined) {
      dispatch(getTemplatesbyappId(id))
        .unwrap()
        .then((selectedTemplate) => {
          let pathNameArr = location.pathname.split("/");
          let templateMode = pathNameArr[pathNameArr.length - 1];
          dispatch(changeTemplateMode(templateMode));
          setFormValueOnUpdateMode(selectedTemplate, templateMode);
          setEditTemplate(selectedTemplate);
        })
        .catch((err) => {
          console.log("errr", err);
        });
    } else {
      setValue("templateType", "TEXT");
      setValue("languageCode", "en");
      setValue("category", "MARKETING");
    }
    return () => {
      dispatch(resetSelectedTemplate());
    };
  }, [id]);

  const SaveModel = () => {
    return (
      <CustomSaveModel
        showSave={showSave}
        header={alertText()}
        dialogFooter={dialogFooter}
        onHide={onHide}
      />
    );
  };
  const alertText = () => {
    return <div>{CONSTANTS.TEMPLATE_SAVE_CONFIRMATION}</div>;
  };
  const dialogFooter = () => {
    return (
      <div className="flex justify-content-end ">
        <CustomButton
          label="Cancel"
          varient="cancel"
          autofocus={true}
          onClick={() => {
            setShowSave(false);
          }}
        />
        <CustomButton
          label="Confirm"
          type="button"
          varient="filled"
          onClick={() => saveTemplate(templateConfig)}
        />
      </div>
    );
  };
  const onHide = () => {
    setShowSave(false);
  };

  const handleQuickReplyChange = (e, id) => {
    if (!id) return;
    const val = e.target.value;
    if (val.length > 17) {
      toast.current.show({
        severity: "warn",
        detail: "Button length should be less than 17",
      });
      return;
    }
  
    const custQuickReply = templateButtons?.map((quickBtn) => {
      if (quickBtn.id === id) {
        return {
          ...quickBtn, 
          text: val,
        };
      }
      return quickBtn; 
    });
  
    setTempBtn(custQuickReply)
  };
  
  const addQuickReplyBtn = () => {
    const custQuickReply = {
      id: Math.floor(1000 + Math.random() * 9000),
      text: "",
      type: seletedButtonType,
      isSaved: true
    }
    setTempBtn((prev) => [...prev, custQuickReply])
  }

  const removeQuickReplyBtn = (id) => {
    if (!id) return
    setTempBtn((prev) => prev.filter((btn) => btn.id != id));
  }

  return (
    <div>
      <Loader visible={loading} />
      <Toast ref={toast} />
      <CustomBreadcrumb home={breadcumbHome} itemslist={breadcumbList} />
      <SaveModel />
      <form
        onSubmit={handleSubmit(onSubmit)}
        onChange={() => {
          setAlert(true);
        }}
      >
        <div className="m-3">
          <TableActionsContainer>
            <div className="flex justify-content-between align-items-center gap-3 flex-wrap">
              <Text type={"heading"}>Template Information</Text>
              <div className="flex gap-2">
                <CustomButton
                  varient="text"
                  type="button"
                  label={"Back"}
                  onClick={() => navigation("/campaign-templates/list")}
                />
                <CustomButton
                  varient="outline"
                  type="button"
                  label={"Reset"}
                  onClick={handleReset}
                />
                {accesskey[modulesName.TICKET]?.includes(
                  templateMode == "update"
                    ? permissions.UPDATE
                    : permissions.CREATE
                ) && (
                  <CustomButton
                    varient="filled"
                    type="submit"
                    label={templateMode == "update" ? "Update" : "Save"}
                  />
                )}
              </div>
            </div>
          </TableActionsContainer>
        </div>
        <div className="px-3 mb-3 flex justify-content-between gap-3 flex-wrap-reverse">
          <div className="p-3 min-w-min lg:w-7 xl:w-7 form-container">
            <div className=" flex flex-wrap gap-3 flex-grow-1 ">
              <div className="w-full flex flex-column lg:flex-row flex-wrap gap-3 lg:gap-2 ">
                <div className="flex-1">
                  <CustomInputText
                    name="elementName"
                    label="Template Name"
                    control={control}
                    placeholer="Enter Template Name"
                    required={true}
                    maxLength={50}
                    rules={{
                      pattern: {
                        value: /^[a-z0-9_]*$/,
                        message:
                          "Only small letters, numbers and underscore are allowed",
                      },
                    }}
                    capitalize={false}
                  />
                  {getFormErrorMessage("elementName")}
                </div>
                <div className="flex-1">
                  <CustomDropdown
                    name="languageCode"
                    label="Language"
                    control={control}
                    isMandatory={true}
                    optionLabel="key"
                    optionValue="value"
                    options={languageOptions}
                    placeholder="Select"
                  />
                  {getFormErrorMessage("languageCode")}
                </div>
              </div>
              <div className="w-full flex flex-column lg:flex-row flex-wrap gap-3 lg:gap-2">
                <div className="flex-1">
                  <CustomDropdown
                    name="category"
                    label="Category"
                    control={control}
                    isMandatory={true}
                    defaultValue={"MARKETING"}
                    optionLabel="key"
                    optionValue="value"
                    options={tempCategories}
                    placeholder="Select"
                  />
                  {getFormErrorMessage("category")}
                </div>
                <div className="flex-1">
                  <CustomInputText
                    name="vertical"
                    label="Template labels"
                    control={control}
                    placeholer="Enter template label"
                    required={true}
                    maxLength={60}
                    helpingText={
                      "( Define what use-case, does this template serves e.g promotions, etc.)"
                    }
                  />
                  {getFormErrorMessage("vertical")}
                </div>
              </div>
              <div className="w-full ">
                <label>Template Type *</label>
                <div className="w-full flex flex-row  flex-wrap gap-3 mt-3">
                  {templateTypes.map((tempType, i) => {
                    return (
                      <div key={i} className="flex align-items-center">
                        <RadioButton
                          inputId={tempType.value}
                          name="templateType"
                          value={tempType}
                          onChange={handleTemplateTypeRadioSelect}
                          checked={watch("templateType") === tempType.value}
                        />
                        <label htmlFor={tempType.value} className="ml-2">
                          {tempType.key}
                        </label>
                      </div>
                    );
                  })}
                </div>
                <div className="mt-3">{headerContainer()}</div>
              </div>
              <div className="w-full relative ">
                <label htmlFor="content" className="inline-block mb-2">
                  Body *
                </label>
                <Controller
                  name="content"
                  control={control}
                  rules={{ required: "Content is required." }}
                  render={({ field, fieldState }) => (
                    <div className="relative">
                      <InputTextarea
                        className={`${classNames({
                          "p-invalid": fieldState.invalid,
                        })} w-full`}
                        id={field.name}
                        value={field.value}
                        rows={3}
                        cols={30}
                        {...field}
                        maxLength={1028}
                        // autoResize
                        placeholder="Enter your content (max 1028 chars)"
                        ref={textAreaRef}
                      />
                    </div>
                  )}
                />

                {getFormErrorMessage("content")}
              </div>

              <div className="w-full flex justify-content-between relative">
                <div className="mt-2">
                  <Menu model={messsageVariables} popup ref={menuRef} />
                  <CustomButton
                    varient="outline"
                    type="button"
                    icon={"pi pi-plus"}
                    label={"Add Variables"}
                    onClick={(event) => menuRef.current.toggle(event)}
                  />
                </div>
                <span
                  className="cursor-pointer"
                  onClick={(event) => {
                    event.stopPropagation();
                    setShowEmoji((prev) => !prev);
                  }}
                >
                  <Emoji width={30} fill="grey" />
                </span>
                {showEmoji && (
                  <div
                    className="absolute w-full flex justify-content-end "
                    style={{ right: "0", top: 35, zIndex: 3 }}
                  >
                    <Picker
                      data={data}
                      onEmojiSelect={handleEmojiClick}
                      onClickOutside={() => setShowEmoji(false)}
                    />
                  </div>
                )}
              </div>
              {tempVariables.length !== 0 && (
                <div className="w-full ">
                  {tempVariables?.map((item, i) => (
                    <div
                      className=" my-2 flex gap-2 justify-content-start align-items-center"
                      key={item.id}
                    >
                      <div className="min-w-min">
                        {i == 0 && (
                          <label className="mb-3  block">Variable</label>
                        )}
                        <InputText value={item.variableName} disabled />
                      </div>
                      <div className="relative flex-grow-1">
                        {i == 0 && (
                          <label className="mb-3 block">Sample Value</label>
                        )}
                        <div className="flex align-items-center gap-2 w-full">
                          <InputTextarea
                            rows={1}
                            autoResize
                            value={item.variableValue}
                            placeholder={`Enter sample value`}
                            onChange={(e) => handleEditVariable(e, item.id)}
                            required
                            className={`${classNames({
                              "p-invalid": !item.variableValue,
                            })} w-full`}
                          />
                          {/* <i
                            className="pi pi-trash cursor-pointer"
                            onClick={() =>
                              handleDeleteVariable(
                                item.id,
                                tempVariables,
                                watchContent
                              )
                            }
                          /> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              <div className="w-full ">
                <CustomInputText
                  name="footer"
                  label="Footer"
                  control={control}
                  placeholer="Enter Footer Text"
                  maxLength={60}
                  required={false}
                />
              </div>
              <div className="w-full ">
                <div className="flex justify-content-between align-items-center">
                  <span>Button Type</span>
                </div>
                <div className="flex flex-row  flex-wrap gap-3 my-2">
                  {buttonTypes.map((button, i) => {
                    return (
                      <div key={i} className="flex align-items-center">
                        <RadioButton
                          inputId={button.value}
                          name="button"
                          value={button}
                          onChange={handleButtonTypeSelect}
                          checked={seletedButtonType === button.value}
                        />
                        <label htmlFor={button.value} className="ml-2 ">
                          {button.key}
                        </label>
                      </div>
                    );
                  })}
                </div>

                {seletedButtonType == "QUICK_REPLY" && (
                  <div className="mt-3">
                    <CustomButton
                      varient="outline"
                      type="button"
                      icon={"pi pi-plus"}
                      label={"Add Buttons"}
                      disabled={templateButtons?.length > 2}
                      onClick={addQuickReplyBtn}
                    />
                    <div className="my-2">
                      {templateButtons?.length > 0 && templateButtons.map((item, i) => (
                        <div key={i} className="flex align-items-center gap-1 my-2">
                          <InputText placeholder="Enter Label (Max 17 Char)" value={item?.text} autoFocus = {templateMode === "create"} onChange={(e) => handleQuickReplyChange(e, item?.id)} />
                          <i className="pi pi-trash cursor-pointer" onClick={() => removeQuickReplyBtn(item?.id)}></i>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {templateButtons.length !== 0 && (
                  <div className="button-preview-container w-full mb-2 py-2">
                    {templateButtons?.map((item) =>
                      item.isSaved ? (
                        <></>
                        // <div
                        //   className="my-2  flex justify-content-between p-3"
                        //   style={{ backgroundColor: "#005068" }}
                        //   key={item.id}
                        // >
                        //   <Text type={"sub-heading"} color={"#ffffff"}>
                        //     {item.text}
                        //   </Text>
                        //   {seletedButtonType !== "PRODUCT" && (
                        //     <i
                        //       className="pi pi-trash cursor-pointer"
                        //       onClick={() => handleDeleteButton(item)}
                        //       style={{ color: "#ffffff" }}
                        //     />
                        //   )}
                        // </div>
                      ) : (
                        <div className="m-2 relative" key={item.id}>
                          <div className="relative mt-2">
                            <div className="flex flex-column gap-2">
                              <div className=" w-full gap-2">
                                <InputText
                                  placeholder="Enter Label (Max 17 Char)"
                                  value={item.text}
                                  onChange={(e) =>
                                    handleButtonEdit(e, item.id, "text")
                                  }
                                  maxLength={17}
                                  required
                                  className={`${classNames({
                                    "p-invalid": item?.text.trim().length == 0,
                                  })} w-full`}
                                />
                                {!item.text?.trim() && (
                                  <span
                                    style={{
                                      color: "#e24c4c",
                                      fontSize: "0.8rem",
                                      display: "block",
                                    }}
                                  >
                                    Please enter label for button.
                                  </span>
                                )}
                              </div>
                              {item.type == "PHONE_NUMBER" && (
                                <div className="w-full">
                                  <div className="flex w-full gap-2 mt-2">
                                    <div className="w-full">
                                      <PhoneInput
                                        name="phone_number"
                                        defaultCountry="IN"
                                        placeholder="Enter phone number"
                                        value={item.phone_number}
                                        international={true}
                                        onChange={(e) => {
                                          handleButtonEdit(
                                            e,
                                            item.id,
                                            "phone_number"
                                          );
                                        }}
                                        className={`${classNames({
                                          "p-invalid": !item.phone_number,
                                        })} flex-grow-1`}
                                      />
                                      {!item.phone_number ||
                                      !isValidPhoneNumber(item.phone_number) ? (
                                        <span
                                          style={{
                                            color: "#e24c4c",
                                            fontSize: "0.8rem",
                                          }}
                                        >
                                          Please enter a valid number
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                    </div>

                                    {/* <CustomButton
                                      varient="icon-button"
                                      icon={"pi pi-trash"}
                                      onClick={() => handleDeleteButton(item)}
                                    /> */}
                                  </div>
                                </div>
                              )}
                              {item.type == "URL" && (
                                <div className="w-full">
                                  <div className="flex w-full gap-2 mt-2">
                                    <div className="w-full">
                                      <InputText
                                        placeholder="Enter URL"
                                        value={item.url}
                                        onChange={(e) => {
                                          handleButtonEdit(e, item.id, "url");
                                        }}
                                        required
                                        className={`${classNames({
                                          "p-invalid": !isValidURL(item.url),
                                        })} w-full`}
                                      />
                                      {!isValidURL(item.url) && (
                                        <span
                                          style={{
                                            color: "#e24c4c",
                                            fontSize: "0.8rem",
                                            display: "block",
                                          }}
                                        >
                                          Please enter a valid url
                                        </span>
                                      )}
                                    </div>
                                    {/* <CustomButton
                                      varient="icon-button"
                                      icon={"pi pi-trash"}
                                      onClick={() => handleDeleteButton(item)}
                                    /> */}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="py-3 flex-grow-1">
            <div className="flex justify-content-center">
              <TemplatePreview
                header={watchHeader}
                footer={watchFooter}
                buttons={templateButtons}
                content={formateMessageInput(
                  watchContent,
                  "preview",
                  tempVariables
                )}
                videoPreview={videoPreview}
                documentPreview={documentPreview}
                imagePreview={imagePreview}
                file={file}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default NewTemplate;
