export const CANVAS_NODE_TYPES = {
  TEXT: "text",
  IMAGE: "image",
  VIDEO: "video",
  QUICK_REPLY: "quick_reply",
  LIST: "list",
  HTTP: "http",
  ASSIGN_AGENT: "assignAgent",
  ADDRESSS_INFO: "addressInfo",
  SHARE_CATALOG: "shareCatalog",
  TEMPLATE: "template",
};
export const CANVAS_NODE_TITLES = {
  text: "Text",
  image: "Image",
  video: "Video",
  quick_reply: "Quick Reply",
  list: "List",
  http: "HTTP",
  assignAgent: "Assign Agent",
  addressInfo: "Address",
  shareCatalog: "Share Catalog",
  template: "Template",
};

export const CANVAS_NODE_ICONS = {
  text: "/svgIcons/Bot builder icons/left-align-icon.svg",
  image: "/svgIcons/Bot builder icons/image-icon.svg",
  video: "/svgIcons/Bot builder icons/image-icon.svg",
  quick_reply: "/svgIcons/Bot builder icons/quick-reply-icon.svg",
  list: "/svgIcons/Bot builder icons/List.svg",
  http: "/svgIcons/Bot builder icons/http-icon.svg",
  assignAgent: "/svgIcons/Bot builder icons/assign-agent.svg",
  addressInfo: "/svgIcons/Bot builder icons/location.svg",
  shareCatalog: "/svgIcons/Bot builder icons/catalog.svg",
  template: "/svgIcons/Bot builder icons/template.svg",
};
export const tailNodes = ["addressInfo", "assignAgent", "shareCatalog"];
