import React from "react";
import { CustomButton } from "../../../components/CustomButton";
import { useState } from "react";
import CSVupload from "../../../components/CSVupload";
import { useRef } from "react";
import { Toast } from "primereact/toast";
import { prodctDefaultColumns } from "../../../utils/csvUtils";
import { API_BULK_PRODUCT_VALIDATE } from "../../../api/product.services";
import { useDispatch } from "react-redux";
import { bulkProductAdd } from "../../../reducers/productTableSlice";
import { ReactComponent as ExcelIcon } from "../../../assets/svgIcons/excelIcon.svg";

function ImportProductCSV() {
  const [importDialog, setImportDialog] = useState(false);
  const [importProductList, setImportedProdList] = useState([]);

  const toast = useRef(null);

  const dispatch = useDispatch();

  const handleValidateData = async () => {
    try {
      const data = await API_BULK_PRODUCT_VALIDATE(importProductList);
      return { new: data?.newProducts, update: data?.updateProducts };
    } catch (err) {
      throw err;
    }
  };
  function refreshPage() {
    setTimeout(() => {
      window.location.reload(false);
    }, 500);
  }
  const handleSaveData = () => {
    dispatch(bulkProductAdd(importProductList))
      .unwrap()
      .then((res) => {
        setImportDialog(false);
        setImportedProdList([]);
        toast.current.show({
          severity: "success",
          detail: `Products got added success.`,
        });
        refreshPage();
      })
      .catch((err) => {
        toast.current.show({ severity: "error", detail: err?.message });
      });
  };
  const handleDialog = () => {
    setImportDialog(false);
    setImportedProdList([]);
  };

  return (
    <>
      <Toast ref={toast} />
      <CustomButton
        varient="icon-button"
        icon={<ExcelIcon />}
        onClick={() => setImportDialog(true)}
      />
      {importDialog && (
        <CSVupload
          showDialog={importDialog}
          hideDialog={handleDialog}
          toast={toast}
          headerTitle="Import products from excel"
          targetColumns={prodctDefaultColumns}
          validateData={handleValidateData}
          dataToAdd={importProductList}
          handleDataChange={(value) => setImportedProdList(value)}
          handleSaveData={handleSaveData}
          sampleFileUrl="/assets/sklbt_products_schema.xlsx"
        />
      )}
    </>
  );
}

export default ImportProductCSV;
