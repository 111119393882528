import React, { useEffect, useState } from "react";
import { Menu } from "primereact/menu";
import { useSelector, useDispatch } from "react-redux";
import {
  changeAgentSearchtUser,
  changeTicketStatusFilter,
} from "../../reducers/enqChatSlice";
import { CustomButton } from "../../components/CustomButton";
import { ReactComponent as Filter } from "../../assets/svgIcons/filter-icon.svg";
import { InputText } from "primereact/inputtext";
import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

const Search = () => {
  const [username, setUsername] = useState("");
  const [err, setErr] = useState(false);
  const filterMenuRef = useRef(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const navigate=useNavigate()
  const { ticketStatusFilter } = useSelector((state) => state.enqChats);
  const dispatch = useDispatch();
  const { mob } = useParams();

  useEffect(() => {
    const debouncedSearch = setTimeout(() => {
      dispatch(changeAgentSearchtUser(username));
    }, 1000);
    return () => {
      clearTimeout(debouncedSearch);
    };
  }, [username]);

  useEffect(() => {
        if(mob&&mob?.length>4){
          setUsername(mob)
          dispatch(changeAgentSearchtUser(mob));
        }
  }, [mob]);
  

  const ticketStatusOptions = [
    {
      label: "All",
      icon: ticketStatusFilter === "" ? "pi pi-check" : "",
      command: (e) => {
        dispatch(changeTicketStatusFilter(""));
      },
    },
    {
      label: "New",
      icon: ticketStatusFilter === "New" ? "pi pi-check" : "",
      command: (e) => {
        dispatch(changeTicketStatusFilter(e.item.label));
      },
    },
    {
      label: "In Progress",
      icon: ticketStatusFilter === "In Progress" ? "pi pi-check" : "",

      command: (e) => {
        dispatch(changeTicketStatusFilter(e.item.label));
      },
    },
    {
      label: "Follow Up",
      icon: ticketStatusFilter === "Follow Up" ? "pi pi-check" : "",

      command: (e) => {
        dispatch(changeTicketStatusFilter(e.item.label));
      },
    },
    {
      label: "Closed",
      icon: ticketStatusFilter === "Closed" ? "pi pi-check" : "",

      command: (e) => {
        dispatch(changeTicketStatusFilter(e.item.label));
      },
    },
    { separator: true },

    {
      command: () => {
        dispatch(changeTicketStatusFilter(""));
      },
      template: (item, options) => {
        return (
          <div className="ml-3">
            <CustomButton
              varient="text"
              icon={"pi pi-times"}
              onClick={(e) => options.onClick(e)}
              label={"Clear Filter"}
              disabled={ticketStatusFilter === ""}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="search">
      <div className="searchForm flex gap-2 align-items-center justify-content-center pl-3 p-2 ">
        <div className="flex-grow-1">
          <span className="p-input-icon-right w-full">
            {!username || username?.length === 0 ? (
              <i className="pi pi-search" />
            ) : (
              <i
                className="pi pi-times cursor-pointer"
                onClick={() => {
                  //setInitialLoad(false);
                  setUsername("");
                  navigate(`/chat`,)    
                }}
              />
            )}
            <InputText
              type="text"
              placeholder="Search customer"
              onChange={(e) => {
                setInitialLoad(false);
                setUsername(e.target.value);
              }}
              value={username}
              className="chat-search capitalize"
            />
          </span>
        </div>
        <div style={{ height: "31.75px" }}>
          <CustomButton
            varient="cus-filter-chat outline "
            type="button"
            icon={<Filter />}
            onClick={(event) => filterMenuRef.current.toggle(event)}
          />
          <Menu
            model={ticketStatusOptions}
            popup
            ref={filterMenuRef}
            //  id="popup_menu_left"
            aria-haspopup
          />
        </div>
      </div>
      {err && <span>Customer not found!</span>}
    </div>
  );
};

export default Search;
