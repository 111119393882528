import { Text } from "../../../components/Text";
import { getDate } from "../../../utils/datemaker";
import PieChartTimer from "../PieChartTimer";
import { ReactComponent as AudioIcon } from "../../../svg/audio.svg";
import { useState, useEffect } from "react";
import { formatMobNumber } from "../../../utils/commonUtils";
const ticketStatusColor = {
  New: "#005068",
  "In Progress": "#2DBECD",
  "Follow Up": "#000000",
  Closed: "#767676"
};

function CustomerChatInfo({ customer = null }) {
  const [currentTime, setCurrentTime] = useState(new Date().getTime());

  function handelChatLength(msg) {
    if (msg) {
      let __msg = msg.split(" ");
      if (__msg.length > 5) {
        return __msg.slice(0, 5).join(" ") + "...";
      }
    }
    return msg;
  }
  function customerLastMessage(customer) {
    const lastMessageTypeIcon = (messageType) => {
      switch (messageType) {
        case "image":
          return <i className="pi pi-camera" />;
        case "video":
          return <i className="pi pi-video" />;
        case "audio":
          return (
            <>
              <AudioIcon />
              <span>audio</span>
            </>
          );
        case "file":
          return <i className="pi pi-file" />;
        default:
          return;
      }
    };
    return (
      <span className="ch-limit-30">
        {lastMessageTypeIcon(customer.lastMessage.messageType)}{" "}
        {customer?.lastMessage?.text
          ? handelChatLength(customer.lastMessage.text)
          : customer.lastMessage.messageType === "audio"
          ? null
          : customer.lastMessage.messageType}
      </span>
    );
  }

  useEffect(() => {
    // Update the current time every second
    const intervalId = setInterval(() => {
      setCurrentTime(new Date().getTime());
    }, 1000);

    // Cleanup the interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      {customer !== null && (
        <div className={`userChatInfo flex flex-column gap-0`}>
          <div className="flex justify-content-between">
            <div>
              <Text type={"sub-heading"} cust="ch-limit-30">
                <span>
                  {customer.customerInfo.displayName
                    ? customer.customerInfo.displayName
                        .charAt(0)
                        .toUpperCase() +
                      customer.customerInfo.displayName.substring(1)
                    : formatMobNumber(customer.customerInfo.mob)}
                </span>
              </Text>
            </div>

            <div className="flex align-items-center">
              <Text type={"small-text"}>
                {getDate(customer.lastUpdatedAt).split(",")[1]}
              </Text>
              <div
                className="mb-1 ml-2 flex justify-content-center"
                style={{
                  width: "20px",
                  height: "20px",
                  border: "1px solid rgba(0, 0, 0, 0.7)",
                  borderRadius: "50%",
                  padding: "2px"
                }}
              >
                <PieChartTimer
                  currentTime={currentTime}
                  startTimestamps={customer.enqStartedAt}
                  duration={24 * 60 * 60 * 1000}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-content-between gap-2 align-items-center">
            <Text
              type={"small-text"}
              style={{
                opacity: "0.6"
              }}
              cust="ch-limit-30"
            >
              {customerLastMessage(customer)}
            </Text>

            <Text
              type={"small-text"}
              cust={`font-weigh-700`}
              style={{ color: ticketStatusColor[customer?.ticketStatus] }}
            >
              {customer?.ticketStatus}
            </Text>
          </div>
        </div>
      )}
    </>
  );
}

export default CustomerChatInfo;
