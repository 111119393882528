import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import { Text } from "../../components/Text";
import { Image } from "primereact/image";
import { Fieldset } from "primereact/fieldset";
import { ReactComponent as DownloadIcon } from "../../svg/download.svg";
import CustomDropdown from "../../components/common/CustomDropdown";
import { setForwardMessage } from "../../reducers/enqChatSlice";
import { isTimeOver } from "../../utils/chatUtils";
import { setForwardFirstMessageId } from "../../reducers/enqChatSlice";
import { CustomMessageText } from "../../components/CustomMessageText/CustomMessageText";
import { ReactComponent as ShareSvg } from "../../assets/svgIcons/svgIcons/whatsappForward.svg";
import { CustomButton } from "../../components/CustomButton";
import { OverlayPanel } from "primereact/overlaypanel";

const ChatButton = ({ title, icon, id }) => {
  return (
    <button
      key={id}
      style={{
        flexGrow: "1",
        color: "#1c738e",
        borderRadius: "4px",
        border: "none",
      }}
      className="mt-1 p-2"
    >
      {title}
    </button>
  );
};
const Message = ({ message, key_id = "" }) => {
  const [showArrow, setShowArrow] = useState(false);
  const [showDropdownModal, setShowDropdownModal] = useState(false);
  const dropdownModalRef = useRef(null);
  // const modalRef = useRef(null);
  const errorRef = useRef(null);

  const { forwardMessagesFlag, forwardMessages, user } = useSelector(
    (state) => state.enqChats
  );

  const dispatch = useDispatch();

  const handleMouseEnter = () => {
    setShowArrow(true);
  };

  const handleMouseLeave = () => {
    setShowArrow(false);
  };

  // const handleMouseLeave = () => {
  //   if (!showDropdownModal) {
  //     setShowArrow(false);
  //   } else {
  //     setShowArrow(true);
  //   }
  // };

  const toggleDropdown = () => {
    setShowDropdownModal((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownModalRef.current &&
        !dropdownModalRef?.current?.contains(event.target)
      ) {
        setShowArrow(false);
        setShowDropdownModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleForwardClick = (event) => {
    // Prevent the click event from propagating to the parent container
    // event.stopPropagation();
    dispatch(setForwardMessage({ forwardMessagesFlag: true }));
    dispatch(
      setForwardFirstMessageId({ clickedForwardFirstMessageId: message.gsId })
    );
  };

  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  function StatusIcon() {
    if (message?.isDelivered) {
      return (
        <>
          <img
            width="14"
            height="14"
            className="mt-2"
            src="https://img.icons8.com/ios-filled/50/A3ABA5/double-tick.png"
            alt="double-tick"
          />
        </>
      );
    } else if (message?.isSent) {
      return (
        <img
          width="14"
          height="14"
          className="mt-2"
          src="https://img.icons8.com/material/24/A3ABA5/checkmark--v1.png"
          alt="single-tick"
        />
      );
    } else if (message?.isEnqueued) {
      let currentDate = new Date();
      let oldTime = new Date(message?.lastUpdatedAt);
      if (currentDate - oldTime < 336763) {
        return (
          <img
            width="14"
            height="14"
            src="https://img.icons8.com/material-outlined/24/000000/delivery-time.png"
            alt="delivery-time"
          />
        );
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  }

  const msgBody = () => {
    let date = formatAMPM(new Date(message?.lastUpdatedAt));

    return (
      <>
        <div className="flex w-full justify-content-between align-items-center ">
          <div>
            <CustomMessageText type={"sub-heading"}>
              {message.message.text}
            </CustomMessageText>
          </div>
          <div className="flex mt-1 ml-1">
            <span className="__date mt-1">{`${date}`}</span>
            {message?.messageType === "inbound" ? (
              <></>
            ) : (
              <div className="ml-1">{<StatusIcon />}</div>
            )}
          </div>
        </div>
      </>
    );
  };

  const failedIcon = () => {
    if (message?.err) {
      const { code, reason } = JSON.parse(message?.err);
      return (
        <div className="relative">
          <Image
            className="failIcon cursor-pointer"
            width="14"
            height="14"
            key={(Math.random() * 100) | 0}
            src="https://img.icons8.com/color/48/high-importance--v1.png"
            alt="checkmark--v1"
            data-pr-tooltip={reason}
            onClick={(e) => errorRef.current.toggle(e)}
          />
          <OverlayPanel ref={errorRef}>
            <Text type={"sub-heading"}>
              Error({code}):&nbsp;{reason}
            </Text>
          </OverlayPanel>
        </div>
      );
    }
  };

  const OrderTable = (items) => {
    return (
      <div
        className="card mt-1"
        style={{
          borderRadius: "4px",
          overflow: "hidden",
        }}
      >
        <DataTable
          className="skalebot-table"
          value={items}
          tableStyle={{ minWidth: "25rem" }}
        >
          <Column field="id" header="id"></Column>
          <Column field="amount" header="amount"></Column>
          <Column field="quantity" header="Quantity"></Column>
        </DataTable>
      </div>
    );
  };

  const CampaignLink = (msg) => {
    let date = formatAMPM(new Date(message?.lastUpdatedAt));
    return (
      <>
        <div className="flex w-full justify-content-between align-items-center">
          <Link to={`/${msg.link}`}>Go to Campaign</Link>
          <div className="flex mt-1 ml-1">
            <span className="__date mt-2">{`${date}`}</span>
            {message?.messageType === "inbound" ? (
              <></>
            ) : (
              <div className="ml-1">{<StatusIcon />}</div>
            )}
          </div>
        </div>
      </>
    );
  };

  const DocumentView = () => {
    return (
      <div className="chatImgContainer">
        <div className="flex __file justify-content-center align-items-center">
          <i
            className="pi pi-file "
            style={{ fontSize: "38px", color: "gray" }}
          />
          <div className="ml-1 flex flex-column  w-8 lg:w-full ">
            <span
              style={{ fontSize: "15px", color: "gray" }}
              className="overflow-hidden	 "
            >
              {message.message.filename}
            </span>
            {message.message.size && (
              <span
                className="mt-1"
                style={{ fontSize: "12px", color: "gray" }}
              >
                {message.message.size / 1000} kb
              </span>
            )}
          </div>
          <div
            onClick={() => {
              downloadFile(message.message);
            }}
            className="download flex  justify-content-center align-items-center"
          >
            <i
              className="pi pi-arrow-down"
              style={{ fontWeight: "bold", color: "gray" }}
            ></i>
          </div>
        </div>
      </div>
    );
  };

  const downloadFile = (msg) => {
    const fileUrl = msg.url;
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.target = "_blank";
    anchor.download = msg.filename;
    anchor.click();
  };
  function extractFileNameFromUrl(object) {
    const { url, contentType } = object;
    try {
      const urlObject = new URL(url);
      const pathnameWithoutQuery = urlObject.pathname.split("?")[0];
      const pathSegments = pathnameWithoutQuery.split("/");
      const lastSegment = pathSegments[pathSegments.length - 1];
      const hasExtension = /\.\w+$/.test(lastSegment);
      if (!hasExtension && contentType) {
        const contentTypeMatch = contentType.match(/\/([a-zA-Z0-9]+)/);
        const detectedExtension = contentTypeMatch
          ? contentTypeMatch[1]
          : "unknown";
        const modifiedFileName = `${lastSegment}.${detectedExtension}`;
        return modifiedFileName;
      } else {
        return `${lastSegment}.mp4`;
      }
    } catch (error) {
      console.error("Error parsing URL:", error);
      return null;
    }
  }
  const handleDownload = async (url, fileName) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  // console.log("message", message);

  return (
    <>
      <div
        key={key_id}
        // ref={ref}
        className={`message ${message?.messageType}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className="messageContent "
          // onMouseEnter={handleMouseEnter}
          // onMouseLeave={handleMouseLeave}
        >
          <div className="flex flex-row justify-content-center align-items-center  w-full gap-2 ">
            {showArrow && !forwardMessagesFlag && (
              <div
                className={`mt-1 ${
                  message.message.messageType === "image" ||
                  message.message.messageType === "video" ||
                  message.message.messageType === "file" ||
                  message.message.messageType === "audio" ||
                  message.message.messageType === "text"
                    ? ""
                    : "disabledShareButton"
                } `}
                style={{
                  width: "1.125rem",

                  display:
                    message.messageType === "outbound" ? "block" : "none",
                }}
                onClick={(e) => handleForwardClick(e)}
              >
                <ShareSvg />
              </div>
            )}

            <div
              className={
                message.message.messageType == "audio" ||
                message.message.messageType == "file"
                  ? "ml-0 w-full"
                  : "container ml-0 "
              }
            >
              {message.message.messageType == "image" && (
                <div className="chatImgContainer">
                  <Image
                    src={message.message.url || "./images/ImgPlaceholder.svg"}
                    zoomSrc={
                      message.message.url || "./images/ImgPlaceholder.svg"
                    }
                    onError={(e) =>
                      (e.target.src = "./images/ImgPlaceholder.svg")
                    }
                    alt=""
                    preview
                    width="100%"
                    style={{ maxWidth: "300px", aspectRatio: "auto" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  />
                </div>
              )}
              {message.message.messageType == "video" && (
                <div className="chatImgContainer relative">
                  <video
                    id="videoPlayer"
                    width={"100%"}
                    height={"250px"}
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      maxHeight: "250px",
                      aspectRatio: "auto",
                    }}
                    controls
                    controlsList={
                      message.messageType == "inbound" ? "nodownload" : ""
                    }
                  >
                    <source src={message.message.url} type="video/mp4" />
                  </video>
                  {message.messageType == "inbound" && (
                    <div
                      className="absolute cursor-pointer"
                      style={{
                        background: "transparent",
                        top: "10px",
                        right: "10px",
                      }}
                      onClick={() => {
                        handleDownload(
                          message.message.url,
                          extractFileNameFromUrl(message.message)
                        );
                      }}
                    >
                      <DownloadIcon />
                    </div>
                  )}
                </div>
              )}
              {message.message.messageType == "file" && DocumentView()}
              {message.message.messageType == "audio" && (
                <div className="audio-playerl">
                  <audio
                    src={message.message.url}
                    controls
                    controlsList="nodownload noplaybackrate"
                    className="audio"
                  />
                </div>
              )}
              {message.message.messageType == "link"
                ? CampaignLink(message.message)
                : message.message.text && msgBody()}
            </div>
            {message.messageType === "outbound" &&
              (message.status === "failed" ||
                !(message.isSent || message.isDelivered)) &&
              failedIcon()}
            {showArrow && !forwardMessagesFlag && (
              <div
                className={`mt-1 ${
                  message.message.messageType === "image" ||
                  message.message.messageType === "video" ||
                  message.message.messageType === "file" ||
                  message.message.messageType === "audio" ||
                  message.message.messageType === "text"
                    ? ""
                    : "disabledShareButton"
                } `}
                style={{
                  width: "1.125rem",

                  display: message.messageType === "inbound" ? "block" : "none",
                }}
                onClick={(e) => handleForwardClick(e)}
              >
                <ShareSvg />
              </div>
            )}
          </div>
          <div className="flex flex-wrap gap-2">
            {message.message.messageType == "list" &&
              message.message.button?.map((ele, i) => (
                <>
                  <ChatButton title={ele.title} id={i} />
                </>
              ))}
            {message.message.messageType == "product_details" &&
              message.message.button?.map((ele, i) => (
                <ChatButton title={ele.title} id={i} />
              ))}
            {message.message.messageType == "order" &&
              OrderTable(message.message.orderItems)}
          </div>
        </div>
      </div>
    </>
  );
};

export default Message;
