import Chat from "./chat";
import Sidebar from "./sidebar";
import "./style.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeAllotedAgent } from "../../reducers/enqChatSlice";
import { getTemplates } from "../../reducers/templateReducer/templateActions";
import { getQuickNotes } from "../../reducers/accountSlice";

const EnqChat = () => {
  const { company } = useSelector((state) => state.company);
  const { user } = useSelector((state) => state.authenticate);
  const { isUserSelected } = useSelector((state) => state.enqChats);

  const isSmallDevice = () => window.innerWidth < 768;

  const dispatch = useDispatch();
  useEffect(() => {
    if (!company) return;
    const agent = {
      displayName: user?.userName,
      agentId: user?.id,
      mob: company?.phone,
      role: user?.role,
    };
    dispatch(changeAllotedAgent(agent));
  }, [company]);
  useEffect(() => {
    dispatch(
      getTemplates({
        pageNo: 0,
        limit: 10000000,
        status: "APPROVED",
        templateType: "text",
      })
    )
      .unwrap()
      .catch((err) => {
        console.log(err);
      });

    dispatch(getQuickNotes({ page: 0, limit: 10000000 }))
      .unwrap()
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="enq-chat">
      <div className="chat-container flex flex-column lg:flex-row xl:flex-row ">
        <div
          className={
            isSmallDevice() && isUserSelected
              ? "hidden"
              : "w-full min-w-max h-full lg:w-3"
          }
        >
          <Sidebar />
        </div>
        <div
          className={`${
            isSmallDevice() && !isUserSelected
              ? "hidden"
              : "w-full h-full lg:w-9 flex flex-column flex-grow-1"
          }`}
        >
          <Chat />
        </div>
      </div>
    </div>
  );
};

export default EnqChat;
