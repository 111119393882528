import styles from "./style.module.css";
import { Text } from "../../components/Text";
import { ReactComponent as AgentIcon } from "../../assets/svgIcons/Agent.svg";
import { useEffect } from "react";

function MonthlySubs({
  handlePlanChange = () => {},
  agentCharge = 1200,
  noOfAgent = 4,
  plateFormFees = 1000,
}) {
  let total = agentCharge * noOfAgent + plateFormFees;
  let plan = {
    id: 2,
    name: "1000 Conversations",
    amount: 1000,
    totalConversation: 1000,
    description: "",
    isActive: true,
    createdAt: "2023-01-17T20:12:48.000Z",
    updatedAt: "2023-01-17T20:12:48.000Z",
  };

  useEffect(() => {
    handlePlanChange({
      ...plan,
      amount: total,
    });
    return () => {
      handlePlanChange(plan);
    };
  }, []);

  return (
    <div className={`w-full p-3 ${styles["sub-container"]}`}>
      <div>
        <Text type={"sub-heading"} color={"#64748B"}>
          Monthly payment
        </Text>
        <div className="my-3">
          <Text type={"sub-heading"}>Agent charge / mon</Text>
          <div
            className={`mt-2 p-2 flex justify-content-between align-items-center gap-2 flex-wrap ${styles["conversation-container"]}`}
          >
            <div className="flex gap-2 align-items-center">
              <AgentIcon />
              <Text type={"heading"}>&#x20B9;{agentCharge}</Text>
            </div>
            <div className="mr-3">
              <Text type={"sub-heading"} color={"#64748B"}>
                Plan: Skalebot Pro
              </Text>
            </div>
          </div>
        </div>
        <div className="my-3">
          <div className="flex justify-content-between gap-3 align-items-center w-11">
            <Text type={"sub-heading"}>Plateform Charges</Text>
            <Text type={"heading"}>&#x20B9;{plateFormFees}</Text>
          </div>
        </div>
        <div className="my-3">
          <div className="flex justify-content-between gap-3 align-items-center w-11">
            <div>
              <Text type={"sub-heading"}>{noOfAgent} Skalebot Agent</Text>
              <Text
                type={"small-text"}
                style={{
                  color: "#000000",
                  opacity: "0.5",
                }}
              >
                {noOfAgent} x {agentCharge}
              </Text>
            </div>
            <Text type={"heading"}>&#x20B9;{noOfAgent * agentCharge}</Text>
          </div>
        </div>
        <div className={`my-4 ${styles["total-ammount"]}`}></div>
        <div>
          <div className="flex justify-content-between gap-3 align-items-center w-11">
            <Text type={"sub-heading"}>Total</Text>
            <Text type={"heading"}>&#x20B9;{total}</Text>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MonthlySubs;
