import { CustomButton } from "../../../components/CustomButton";
import { Text } from "../../../components/Text";
import TableActionsContainer from "../../../components/CustomTable/TableActionsContainer";
import QRCode from "react-qr-code";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import NewUPIForm from "./NewUPIForm";
import QRCodeStyling from "qr-code-styling";
import QrIcon from "../../../assets/qrcenter.png";
import { getBuisenessinfo } from "../../../reducers/accountSlice";
import permissions from "../../../roles/permissions";
import modulesName from "../../../roles/modulesName";
import { ReactComponent as Delete } from "../../../svg/delete.svg";
import { postBuisenessinfo } from "../../../reducers/accountSlice";
import { useLayout } from "../../../context/LayoutContext";
import SklbtConfirmation from "../../../components/common/SklbtConfirmation";
import CONSTANTS from "../../../config/Constants";
import * as Messag from "../../../config/ToastMessage";

function PaymentQR() {
  const [editUPIDialog, setEditUPIDialog] = useState(false);
  const { user, userSub, accesskey } = useSelector(
    (state) => state.authenticate
  );
  const { businessdata } = useSelector((state) => state.account);
  const [isCopied, setCopied] = useState(false);
  const { toastMessage } = useLayout();
  const [showDeleteModel, setshowDeleteModel] = useState(false);

  const handleDelete = () => {
    dispatch(
      postBuisenessinfo({
        id: user?.id,
        data: { upi: "", upiQR: "", isActive: false }
      })
    )
      .unwrap()
      .then((e) => {
        toastMessage({
          message: "success",
          detail: Messag.Delete_UPI_ToastSuccessMessage
        });
      });

    ref.current = null;

    setshowDeleteModel(false);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
  };
  const qrCode = new QRCodeStyling({
    width: 180,
    height: 180,
    image: QrIcon,
    dotsOptions: {
      color: "#000",
      type: "rounded"
    },
    imageOptions: {
      crossOrigin: "anonymous"
    },
    backgroundOptions: {
      color: "#f8f9fe"
    },
    backgroundOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#ffffff",
        color2: "#ffffff"
      }
    },
    qrOptions: { typeNumber: "0", mode: "Byte", errorCorrectionLevel: "L" },
    imageOptions: { hideBackgroundDots: true, imageSize: 0.5, margin: 0 },
    dotsOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#6a1a4c",
        color2: "#6a1a4c",
        rotation: "0"
      }
    },
    cornersSquareOptions: { type: "square", color: "#000000" },
    cornersSquareOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000000",
        color2: "#000000",
        rotation: "0"
      }
    },
    cornersDotOptions: { type: "", color: "#000000" },
    cornersDotOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000000",
        color2: "#000000",
        rotation: "0"
      }
    }
  });

  const ref = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBuisenessinfo({})).then();
  }, []);

  // change upi address in this location .👇
  useEffect(() => {
    if (businessdata?.upi) {
      qrCode.append(ref?.current);
      qrCode.update({
        data: `upi://pay?pa=${businessdata?.upi}&cu=INR`
      });
    }
  }, [ref?.current, businessdata?.upi]);

  return (
    <div>
      <Text type={"heading"} cust="text-center">
        Payment Details
      </Text>
      {editUPIDialog && (
        <NewUPIForm
          visiable={editUPIDialog}
          handleHide={() => setEditUPIDialog(false)}
        />
      )}
      <div className="mt-3">
        <TableActionsContainer>
          <div className="w-full p-4">
            {businessdata?.upi && (
              <div className="pb-3 text-center">
                <Text type={"heading"}>UPI QR Code</Text>
              </div>
            )}
            {/* {userSub.isUserSub && (
              <>
                <QRCode
                  id="generatedQrCode"
                  size={156}
                  style={{
                    // height: "100%",
                    display: "block",
                    margin: "auto",
                  }}
                  value={`upi://pay?pa=8577963932@paytm&cu=INR`}
                />
              </>
            )} */}

            {/* {userSub.isUserSub && (
              <div className="flex  flex-align-center justify-content-center">
                <div ref={ref} />
              </div>
            )} */}

            {businessdata?.upi && (
              <div className="flex  flex-align-center justify-content-center">
                <div ref={ref} />
              </div>
            )}

            {!businessdata?.upi && (
              <div className="w-12rem flex align-items-center border-dashed border-round-sm p-3 h-12rem">
                <div className="w-12">
                  <Text type={"sub-heading"}>
                    Please provide UPI id to generate QR code{" "}
                  </Text>
                </div>
              </div>
            )}
            {businessdata?.upi && (
              <div className="mt-4 text-center">
                <Text type={"heading"}>Scan & Pay</Text>
              </div>
            )}
            {businessdata?.upi && (
              <div className="mt-3 text-center">
                <Text type={"sub-heading"}>Powered by Skalebot</Text>
              </div>
            )}
            {businessdata?.upi && (
              <div className="mt-3  ">
                <div className="p-2 flex align-items-center gap-2">
                  <div className="px-2 py-1 flex align-items-center gap-1 upi-container">
                    <Text type={"heading"}>UPI&nbsp;ID:&nbsp;</Text>
                    <Text type={"sub-heading"}>{businessdata?.upi}</Text>

                    <CustomButton
                      varient="icon-button"
                      icon={isCopied ? "pi pi-check" : "pi pi-copy"}
                      type="button"
                      onClick={() => copyToClipboard(businessdata?.upi)}
                    />
                  </div>
                  <div onClick={() => setshowDeleteModel(true)}>
                    <Delete />
                  </div>
                </div>
              </div>
            )}
          </div>
        </TableActionsContainer>
        <div className="mt-3 flex justify-content-center">
          {accesskey[modulesName.PAYMENT]?.includes(permissions.UPDATE) && (
            <CustomButton
              varient="filled"
              type={"button"}
              onClick={() => setEditUPIDialog(true)}
              // label={"Change Payment Details"}
              label={businessdata?.upi ? "Update UPI Id" : "Add UPI Id"}
            />
          )}
        </div>
        <SklbtConfirmation
          icon="pi pi-trash"
          message={CONSTANTS.UPI}
          visible={showDeleteModel}
          handleConfirm={handleDelete}
          handleReject={() => setshowDeleteModel(false)}
        />
      </div>
    </div>
  );
}

export default PaymentQR;
