import axios from "axios";
import baseUrl from "./server";
import { logout } from "../reducers/authSlice";

// const axiosInstance = axios.create({
//     baseURL: baseUrl,
//     headers: {
//         Accept: "*/*",
//         "Content-Type": "application/json",
//         authorization: localStorage.getItem("token")
//     },

//     withCredentials: true,
// });

export const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
  },
});

export const setupRequestInterceptor = (params) => {
  return axiosInstance.interceptors.request.use(
    (config) => {
      config.params = {
        ...config.params,
        cmpId: params.companyId,
      };
      return config;
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      logout();
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

var token = localStorage.getItem("token");
if (token) {
  axiosInstance.defaults.headers.common["authorization"] = token;
}

export default axiosInstance;
