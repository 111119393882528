import { useNavigate } from "react-router";
import { CustomButton } from "../../../components/CustomButton";
import TableActionsContainer from "../../../components/CustomTable/TableActionsContainer";
import { Text } from "../../../components/Text";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as WhatsappIcon } from "../../../assets/Whatsapp.svg";
import { ReactComponent as Edit } from "../../../svg/edit.svg";
import { ReactComponent as Delete } from "../../../svg/delete.svg";
import { Checkbox } from "primereact/checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  changeBotMode,
  changeBotflowLimit,
  changeBotflowPage,
  changeSelectectBotFlow,
  deleteBotFlow,
  getAllBotFlow,
  resetBotFlowList,
} from "../../../reducers/canvas/canvasSlice";
import { BOT_MODES } from "../bot-constants";
import Loader from "../../../components/Loader";
import CustomPaginator from "../../../components/CustomPaginator";
import SklbtConfirmation from "../../../components/common/SklbtConfirmation";
import CONSTANTS from "../../../config/Constants";
import { getDate } from "../../../utils/datemaker";
import { useLayout } from "../../../context/LayoutContext";
import BotFlowForm from "./BotFlowForm";

export default function BotList() {
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showdelDialog, setShowDelDialog] = useState(false);
  const [showBotForm, setBotForm] = useState(false);
  const [selectedBotFlow, setBotFlow] = useState(null);
  const flowid = useRef(null);

  const { botFlows, page, limit, loading, totalbotflowCount } = useSelector(
    (state) => state.canvas
  );
  const { toastMessage } = useLayout();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleEditClick = (flow) => {
    flowid.current = flow.id;
    setShowEditDialog(true);
  };
  const onHandleEditSubmit = () => {
    dispatch(changeBotMode(BOT_MODES.EDIT));
    dispatch(changeSelectectBotFlow(flowid.current));
    navigate(`/bots/${flowid.current}/update`);
  };
  const handleDeleteClick = (flowId) => {
    flowid.current = flowId;
    setShowDelDialog(true);
  };

  const onHandleDelSubmit = () => {
    dispatch(deleteBotFlow(flowid.current))
      .unwrap()
      .then(() => {
        toastMessage({ severity: "success", detail: "Botflow deleted." });
        setShowDelDialog(false);
      })
      .catch((err) => {
        toastMessage({ severity: "danger", detail: "Botflow delete failed" });
      });
  };
  const itemTemplate = (bflow, idx) => {
    const dateShower = (d) => {
      let date = getDate(d)?.split(",");
      return (
        <div className="flex flex-wrap gap-1">
          <div>{date[0]}</div>
          <div>{date[1]}</div>
        </div>
      );
    };

    return (
      <div
        key={bflow?.id}
        className="w-full p-2 xs:p-3 card mb-2"
        style={{
          borderRadius: "2px",
          border: "0.5px solid rgba(0, 0, 0, 0.20)",
          background: "#FFF",
        }}
      >
        <div className="flex justify-content-between align-items-center">
          {/* <Checkbox /> */}
          <div className="w-auto pl-2">
            <Text>{idx + 1}</Text>
          </div>

          <div className="w-auto pl-2">
            <WhatsappIcon />
          </div>

          <div className="flex-grow-1 pl-1 w-auto md:w-6 ml-1">
            <Text>{bflow?.flowName}</Text>
            <div className="mt-2"></div>
            <Text color={"rgba(0, 0, 0, 0.50)"}>
              {dateShower(bflow?.updatedAt)}
            </Text>
          </div>
          <div className="w-2 flex-nowrap">
            <Text color={"rgba(0, 0, 0, 0.50)"}>Status</Text>
            <div className="mt-2 ">
              <Text color={"#05C1A0"}>{bflow?.status}</Text>
            </div>
          </div>
          <div className="w-auto">
            <div className="flex justify-content-end gap-1">
              <CustomButton
                varient="text"
                icon={<Edit />}
                onClick={() => handleEditClick(bflow)}
              />
              <CustomButton
                varient="text"
                icon={<Delete />}
                onClick={() => handleDeleteClick(bflow.id)}
              />
              {/* <CustomButton varient="text" icon={"pi pi-ellipsis-v"} /> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const listTemplate = (items) => {
    if (!items || items.length === 0) return null;

    let list = items.map((bflow, index) => {
      return itemTemplate(bflow, index);
    });

    return <div className="grid grid-nogutter">{list}</div>;
  };

  const handleNewBotFlowClick = () => {
    setBotForm(true);
    // () => navigate("/bots/new")
  };

  useEffect(() => {
    // fetch all bot list
    dispatch(getAllBotFlow({ page, limit }));
    return () => {
      //dispatch(resetBotFlowList());
      // dispatch(changeBotflowPage(0));
      // dispatch(changeBotflowLimit(30));
    };
  }, []);

  return (
    <div>
      <Loader visible={loading} />
      {showBotForm && (
        <BotFlowForm
          showBotFlowForm={showBotForm}
          onCancel={() => setBotForm(false)}
          botFlow={selectedBotFlow}
        />
      )}
      <SklbtConfirmation
        icon="pi pi-trash"
        message={CONSTANTS.BOT_EDIT_CONFIRMATION}
        visible={showEditDialog}
        handleConfirm={onHandleEditSubmit}
        handleReject={() => setShowEditDialog(false)}
      />
      <SklbtConfirmation
        icon="pi pi-trash"
        message={CONSTANTS.BOT_DELETE_CONFIRMATION}
        visible={showdelDialog}
        handleConfirm={onHandleDelSubmit}
        handleReject={() => setShowDelDialog(false)}
      />
      <div className="m-3">
        <TableActionsContainer>
          <div className="flex justify-content-between align-items-center">
            <Text type={"heading"}>Bot Flows</Text>
            <CustomButton
              icon={"pi pi-plus"}
              label={"Create new bot flow"}
              onClick={handleNewBotFlowClick}
            />
          </div>
        </TableActionsContainer>
      </div>
      <div className="p-3 w-full overflow-x-auto">
        {botFlows?.map((flow, index) => itemTemplate(flow, index))}
      </div>

      <TableActionsContainer>
        <div className="flex  justify-content-end">
          <CustomPaginator
            page={page}
            limit={limit}
            changelimit={changeBotflowLimit}
            totalRecords={totalbotflowCount}
            changePage={changeBotflowPage}
          />
        </div>
      </TableActionsContainer>
    </div>
  );
}
