import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Loader from "../../components/Loader";
import { Text } from "../../components/Text";
import { CustomButton } from "../../components/CustomButton";
import { ReactComponent as Edit } from "../../svg/edit.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSelectedEnquiry,
  resetMode,
  getEnquiries,
  resetSelectedEnquiry,
  updateEnquiry,
  updateMode,
  changePage,
  changelimit,
  resetPagination
} from "../../reducers/enquiryTabelSlice";
import { getDate } from "../../utils/datemaker";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import CreateGroup from "../../components/CreateGroup";
import * as Messag from "../../config/ToastMessage";
import CustomPaginator from "../../components/CustomPaginator";
import { AddNewCustomer } from "../../components/Forms/AddNewCustomer";
import { ReactComponent as AddCustImg } from "../../svg/addcustomer.svg";
import { ReactComponent as AllCustImg } from "../../svg/alreadycustomer.svg";

import "./style.css";
import {
  changeMode,
  changeSelectedCustomer,
  resetSelectedCustomer
} from "../../reducers/customerTableSlice";
import TableActionsContainer from "../../components/CustomTable/TableActionsContainer";
import DateRangePicker from "../../components/common/DateRangePicker";
import DebounceSearch from "../../components/common/DebounceSearch";
import { useNavigate } from "react-router-dom";
import modulesName from "../../roles/modulesName";
import permissions from "../../roles/permissions";
import TrailDashboard from "../TrailDashboard";
const statusOptions = [
  { key: "New", value: "New" },
  { key: "In Progress", value: "In Progress" },
  { key: "Done", value: "Done" }
];

const Enquiries = () => {
  const [inputValue, setInputValue] = useState("");
  const [dateRange, setDateRange] = useState({ fromDate: "", toDate: "" });

  const toast = useRef(null);
  const [createGrpModule, setCreateGrpModule] = useState(false);
  const [displayBasic, setDisplayBasic] = useState(false);
  const { user, userSub } = useSelector((state) => state.authenticate);

  const dispatch = useDispatch();
  const {
    enquiryData,
    mode,
    page,
    limit,
    loading,
    selectedEnquiry,
    totalEnquiryCount,
    existingCustomerCount,
    newCustomerCount
  } = useSelector((state) => state.enquiryTable);
  const [status, setStatus] = useState(null);
  const { accesskey} = useSelector((state) => state.authenticate);

  const { customerData } = useSelector((state) => state.customerTable);

  const handleDateRangeFrom = (newDate) => {
    setDateRange((prev) => {
      return { ...prev, fromDate: newDate };
    });
  };
  const handleDateRangeTo = (newDate) => {
    setDateRange((prev) => {
      return { ...prev, toDate: newDate };
    });
  };

  const modalLoad = () => {
    return <Loader visible={loading} />;
  };
  const CreateGroupModule = () => {
    return (
      <CreateGroup
        customerData={customerData}
        createGrpModule={createGrpModule}
        setCreateGrpModule={setCreateGrpModule}
        toast={toast}
      />
    );
  };

  const handleEdit = (rowData) => {
    dispatch(changeSelectedEnquiry(rowData));
    dispatch(updateMode("update"));
  };

  const phoneBodyTemplate = (rowdata) => {
    return `+${rowdata.phone}`;
  };

  const statusModule = (rowData) => {
    if (mode === "update" && selectedEnquiry.id === rowData.id) {
      return (
        <Dropdown
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          options={statusOptions}
          optionLabel="key"
          optionValue="value"
        />
      );
    } else return <span>{rowData.status}</span>;
  };
  const addCust = (rowData) => {
    return !rowData.customer &&
      rowData.customer !== undefined &&
      user.role !== "support" ? (
      <CustomButton
        varient="text font-light no-underline text-sm bg-white border-solid  border-1"
        label={"Add Customer"}
        icon={"pi pi-plus"}
        onClick={() => handler(rowData)}
      />
    ) : (
      <></>
    );
  };

  const custName = (rowData) => {
    return (
      <div className="flex align-items-center">
        <div className="mr-2">
          {!rowData.customer && rowData.customer !== undefined ? (
            <AddCustImg />
          ) : (
            <AllCustImg />
          )}
        </div>
        <div>{rowData.name}</div>
      </div>
    );
  };

  const editColumnBody = (rowData) => {
    if (mode === "update" && selectedEnquiry.id === rowData.id) {
      return (
        <div className="flex">
          <CustomButton
            varient="icon-button"
            onClick={() => handleSaveStatus()}
            icon={"pi pi-check"}
          />
          <CustomButton
            varient="icon-button"
            onClick={() => handleCancelUpdate()}
            icon={"pi pi-times"}
          />
        </div>
      );
    } else {
      return (
        <CustomButton
          varient="icon-button edit-border"
          onClick={() => handleEdit(rowData)}
          icon={<Edit />}
        />
      );
    }
  };

  const handleSaveStatus = () => {
    const enquiryId = selectedEnquiry.id;
    const updatedStatus = {
      status: status
    };
    dispatch(updateEnquiry({ enquiryId, updatedStatus }))
      .unwrap()
      .then(() => {
        //show toast from here
        if (updatedStatus.status === "In Progress") {
          let Message_Success = Messag.Progress_Enq_ToastSuccessMessage;
          toast.current.show({ severity: "success", detail: Message_Success });
        }
        if (updatedStatus.status === "Done") {
          let Message_Success = Messag.Done_Enq_ToastSuccessMessage;
          toast.current.show({ severity: "success", detail: Message_Success });
        }
      })
      .catch((err) => {
        //show toast from here
        toast.current.show({ severity: "error", detail: err.response.data });
      });
  };

  const navigate = useNavigate();
  const handleCancelUpdate = () => {
    dispatch(resetMode());
    dispatch(resetSelectedEnquiry());
  };

  const grpbtn = () => {
    return (
      <div className="flex  justify-content-right align-items-right">
        <CustomButton
          varient="filled"
          label={"Create Group"}
          onClick={() => {
            setCreateGrpModule(true);
          }}
        />
      </div>
    );
  };
  const onHide = (reset) => {
    setDisplayBasic(false);
    dispatch(resetSelectedCustomer());
    dispatch(resetMode());
    reset();
  };

  const handler = (customer) => {
    dispatch(changeSelectedCustomer(customer));
    dispatch(changeMode("convert"));
    navigate(`/customers/new`);
    // setDisplayBasic(true);
  };

  const addCustomerModal = () => {
    return (
      <AddNewCustomer
        onHide={onHide}
        displayBasic={displayBasic}
        toast={toast}
      />
    );
  };

  ///filter--------------------------------
  const [filtersData, setFiltersData] = useState({
    name: null,
    status: null,
    isCustomerActive: null
  });

  const [filters1, setFilters1] = useState({
    name: { value: null },
    status: { value: null }
  });

  const [isCustomerActive, setisCustomerActive] = useState(null);

  let stocks = ["New", "In Progress", "Done"];

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={stocks}
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
        }}
        itemTemplate={statusItemTemplate}
        placeholder="Select"
        className="p-column-filter"
        showClear
      />
    );
  };

  const statusItemTemplate = (option) => {
    return <span className={`customer-badge status-${option}`}>{option}</span>;
  };

  const onClearNameFilter = () => {
    filters1.name.value = null;
    filtersData.name = null;
    onApplyAllFilter();
  };

  const onClearStatusFilter = () => {
    filters1.status.value = null;
    filtersData.status = null;
    onApplyAllFilter();
  };

  const onClickFilter = (e) => {
    switch (e.field) {
      case "name":
        filters1.name.value = e.constraints.constraints[0].value;
        filtersData.name = e.constraints.constraints[0].value;
        break;
      case "status":
        filters1.status.value = e.constraints.value;
        filtersData.status = e.constraints.value;
        break;
      default:
        break;
    }

    onApplyAllFilter();
  };

  const managePaginationData = () => {
    let filterData = [];
    let tzoffset = new Date().getTimezoneOffset() * 60000;
    var { fromDate, toDate } = dateRange;
    if (!toDate) toDate = fromDate;
    for (var key in filtersData) {
      if (filtersData[key]) {
        filterData.push({
          key: key,
          value: filtersData[key]
        });
      }
    }
    if (isCustomerActive !== null) {
      filterData.push({
        key: "isCustomerActive",
        value: isCustomerActive
      });
    }

    let paginationData = {
      page: page,
      limit: limit,
      startDate: fromDate
        ? new Date(fromDate - tzoffset).toISOString().substring(0, 10)
        : fromDate,
      endDate: toDate
        ? new Date(toDate - tzoffset).toISOString().substring(0, 10)
        : toDate,
      filterData,
      globalFilterValue: inputValue
    };
    return paginationData;
  };

  const onApplyAllFilter = () => {
    let paginationData = managePaginationData();
    dispatch(getEnquiries(paginationData));
  };

  useEffect(() => {
    if (mode === "update") {
      setStatus(selectedEnquiry.status);
    }
  }, [selectedEnquiry]);

  useEffect(() => {
    const paginationData = managePaginationData();
    dispatch(getEnquiries(paginationData))
      .unwrap()
      .then((res) => {})
      .catch(() => {
        //show toast from here
        toast.current.show({
          severity: "error",
          detail: "Something went wrong!"
        });
      });
  }, [inputValue, isCustomerActive, dateRange, page, limit, customerData]);
  useEffect(()=>{
    return ()=>{
      dispatch(resetPagination())
    }
  },[])
  return (
    <div className="w-full m-auto">
      <Toast ref={toast} />
      {displayBasic ? addCustomerModal() : <></>}
      <div className="m-3">
        {!userSub.isUserSub ? (
          <TrailDashboard/>
        ) : (
          <div className="">
            <TableActionsContainer>
              <div className="flex align-items-center justify-content-between flex-wrap gap-3">
                <Text type="heading">Enquiries List</Text>
                <div className="flex gap-2 flex-wrap  ">
                  <DateRangePicker
                    fromDate={dateRange.fromDate}
                    toDate={dateRange.toDate}
                    handleFromDate={handleDateRangeFrom}
                    handleToDate={handleDateRangeTo}
                  />
                  <DebounceSearch
                    placeholder={"Search Enquries"}
                    inputValue={inputValue}
                    handleInputChange={(value) => setInputValue(value)}
                  />
                </div>
              </div>
            </TableActionsContainer>
            <div className="card lg:my-2">
              {createGrpModule ? CreateGroupModule() : <></>}
              {modalLoad()}
              <div
                className={`w-full flex flex-column dashboard-tablecontent border-round-sm `}
              >
                <div className={`w-full justify-content-start `}>
                  <div
                    className={`w-full flex flex-row overflow-auto enquries-filter gap-4 lg:gap-0`}
                  >
                    <div
                      className={` cursor-pointer text-md mr-2  p-3 enquries-filter-el ${
                        isCustomerActive === null && "enquries-filter-el-sel"
                      } `}
                      onClick={() => {
                        setisCustomerActive(null);
                      }}
                    >
                      Total&nbsp;Enquiries({totalEnquiryCount})
                    </div>
                    <div
                      className={`p-2 cursor-pointer text-md mr-2 enquries-filter-el py-3 ${
                        isCustomerActive === 0 && "enquries-filter-el-sel"
                      } `}
                      onClick={() => {
                        setisCustomerActive(0);
                      }}
                    >
                      New&nbsp;Customers({newCustomerCount})
                    </div>
                    <div
                      className={`p-2 cursor-pointer text-md mr-2 enquries-filter-el py-3 ${
                        isCustomerActive === 1 && "enquries-filter-el-sel"
                      } `}
                      onClick={() => {
                        setisCustomerActive(1);
                      }}
                    >
                      Existing&nbsp;Customers({existingCustomerCount})
                    </div>
                  </div>
                </div>
                <DataTable
                  value={enquiryData}
                  responsiveLayout="scroll"
                  resizableColumns
                  columnResizeMode="expand"
                  className="skalebot-table "
                  style={{ borderTop: "0px" }}
                  filters={filters1}
                  filterDisplay="menu"
                  globalFilterFields={["name", "status"]}
                >
                  <Column
                    field="createdAt"
                    header="Date"
                    style={{width:"20ch"}} 
                    className="text-sm font-normal"
                    body={(rowData) => getDate(rowData.createdAt)}
                    bodyStyle={{ textTransform: "uppercase" }}
                  />
                  <Column
                    field="name"
                    header="Name"
                    className="text-sm font-normal"
                    body={(rowData) => custName(rowData)}
                  />
                  <Column
                    field="phone"
                    header="Phone"
                    className="text-sm font-normal"
                    style={{width:"30ch"}} 
                    body={(rowData) => phoneBodyTemplate(rowData)}
                  />
                  {/* <Column
                      field="status"
                      header="Status"
                      body={(rowData) => statusModule(rowData)}
                      showFilterMatchModes={false}
                      filter
                      filterElement={statusFilterTemplate}
                      onFilterApplyClick={(e) => onClickFilter(e)}
                      onFilterClear={onClearStatusFilter}
                    />
                  */}

                    {accesskey[modulesName.CUSTOMER]?.includes(permissions.CREATE)&&<Column 
                        style={{width:"25ch"}} 
                        body={(rowData) => addCust(rowData)} />}

                  {/* <Column
                      header={"Actions"}
                      body={(rowData) => editColumnBody(rowData)}
                    /> */}
                </DataTable>
              </div>
            </div>
            <div className="mt-3">
              <TableActionsContainer>
                <div className="flex  justify-content-end">
                  <CustomPaginator
                    page={page}
                    limit={limit}
                    changelimit={changelimit}
                    totalRecords={totalEnquiryCount}
                    changePage={changePage}
                  />
                </div>
              </TableActionsContainer>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Enquiries;
