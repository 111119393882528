import React from "react";
// import "./style.css";
import styles from "./footer.module.css";
import { useNavigate, useLocation, Link, NavLink } from "react-router-dom";
import deskalaLogoWhiteSvg from "../../assets/welcomeScreen/socialLogos/deskalaLogo.svg";
import linkdinLogoSvg from "../../assets/welcomeScreen/socialLogos/linkedinIcon.svg";
import youtubeLogoSvg from "../../assets/welcomeScreen/socialLogos/youtubeIcon.svg";
import instagramLogoSvg from "../../assets/welcomeScreen/socialLogos/instagramIcon.jpg";

const Footer = () => {
  const navigate = useNavigate();
  const handleClickScroll = (idName) => {
    navigate("/login");
    setTimeout(() => {
      const element = document.getElementById(idName);
      if (element) {
        // Calculate the offset based on the header's height
        const header = document.querySelector(`.${styles.footer}`);
        const footerHeight = header ? header.clientHeight : 0;
        const offset = element.offsetTop - (footerHeight - 140);

        window.scrollTo({
          top: offset,
          behavior: "smooth"
        });
      }
    }, 300);
  };

  const websiteRedirectHandler = (section) => {
    // Open SkaleBot website in a new tab
    window.open(`http://skalebot.com/#${section}`, "_blank");
  };


  return (
    <>
      <div className={`${styles.footer}`}>
        <div className="w-9">
          <div className="flex flex-column mb-3 ">
            <p className={styles.powerdby}>Powered by</p>
            <a href="https://www.deskala.in/">
            <img
              src={deskalaLogoWhiteSvg}
              alt="deskala-logo"
              className={styles.deskalaLogo}
              />
            </a>
          </div>

          <div className="flex flex-wrap flex-md-row  justify-content-start  col-lg-12  ">
            <div className="block flex-column lg:flex-column justify-content-center xl:justify-content-start justify-content-center align-items-start col-6 lg:col-3   ">
              <h4
                className={styles.linkTitle}
                onClick={() => handleClickScroll("home")}
              >
                About
              </h4>
              <h4
                className={styles.linkTitle}
                onClick={()=>handleClickScroll("videoTour")}
              >
                Video tour
              </h4>
              <h4
                className={`${styles.linkTitle} block lg:hidden  `}

              >
                <a href="https://www.skalebot.com/terms&conditions" target="_blank" rel="noreferrer" className={`${styles.linkTitle} no-underline`} >
                Terms & Conditions
                </a>  
              </h4>
            </div>
            <div className="block flex-column lg:flex-column  lg:justify-content-start justify-content-center align-items-start col-6 lg:col-3 ">
              <h4
                className={styles.linkTitle}
                onClick={() => handleClickScroll("paymentPlan")}
              >
                Pricing
              </h4>
              <h4
                className={styles.linkTitle}
                onClick={() => handleClickScroll("team")}
              >
                Contact us
              </h4>
              
              <h4
                className={`${styles.linkTitle} block lg:hidden`}
                onClick={() => handleClickScroll("enquiry")}
              >
                Enquiry
              </h4>
            </div>
            <div className=" hidden lg:block  flex-column lg:flex-column  lg:justify-content-start justify-content-center align-items-start col-6 lg:col-3 ">
              <h4
                className={styles.linkTitle}
                onClick={()=>websiteRedirectHandler("enquiry")}
                
              >
                Enquiry
              </h4>
              <h4
                className={`${styles.linkTitle}`}
                // onClick={() => {
                //   navigate("/terms&conditions");
                //   window.scrollTo({
                //     top: 0,
                //     behavior: "smooth"
                //   });
                // }}
              >
                 <a href="https://www.skalebot.com/terms&conditions" target="_blank" rel="noreferrer" className={`${styles.linkTitle} no-underline`} >
                Terms & Conditions
                </a> 
              </h4>
            </div>

            <div
              className={`flex flex-wrap flex-row  justify-content-start align-items-center  gap-3 col-7 lg:col-3 ${styles.mobileRes} `}
            >
              <a
                href="https://www.youtube.com/@skaleworkskalebot"
                className=" cursor-pointer"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={youtubeLogoSvg}
                  alt="youtubeLogoSvg"
                  className={styles.socialShareLogo}
                />
              </a>
              {/* <a
                href="https://www.facebook.com/profile.php?id=100057829712479"
                className=" cursor-pointer"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={fbLogoSvg}
                  alt="fbLogo"
                  className={styles.socialShareLogo}
                />
              </a> */}
              <a
                href="https://www.linkedin.com/company/skalebot"
                className=" cursor-pointer"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={linkdinLogoSvg}
                  alt="linkdinLogo"
                  className={styles.socialShareLogo}
                />
              </a>
              <a
                href="https://www.instagram.com/skalebot/"
                className=" cursor-pointer"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={instagramLogoSvg}
                  alt="instagramLogo"
                  className={styles.socialShareLogo}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.copyrightsContainer}>
        <p className={styles.copyrightsText}>
          {/* @ 2023 &copy; Copyrights. All rights reserved */}
          Copyrights &copy; 2023 Deskala Research and Design Consulting Pvt. Ltd
        </p>
      </div>
    </>
  );
};

export default Footer;
