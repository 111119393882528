export function formatMessage(message, sentBy) {
  let chat = {};
  if (sentBy == "outbound" && message?.message) {
    let __message = null;
    let messageType = null;
    try {
      __message =
        message?.message && typeof message?.message === "string"
          ? JSON.parse(message.message)
          : message.message;

      messageType = __message?.type;
      //console.log(message, sentBy,messageType);
      chat["messageType"] = messageType;
    } catch (error) {
      console.log(error);
      return;
    }

    switch (messageType) {
      case "list":
        //console.log(__message)
        chat["text"] =
          __message.title != undefined ? __message?.title + ", " : "";
        chat["text"] += __message?.body;
        //console.log(chat["text"])
        chat["button"] = [...__message?.globalButtons];
        break;
      case "product_details":
        chat["text"] = __message?.body?.text;
        chat["button"] = [
          {
            text: "View Items",
            title: "View Items",
          },
        ];
        break;
      case "quick_reply":
        chat["messageType"] = "list";
        chat["text"] = __message?.content?.text;
        if (
          __message.type == "image" ||
          __message.type == "file" ||
          __message.type == "video"
        ) {
          chat["url"] = __message?.content?.url;
        }
        chat["button"] = [...__message?.options];
        break;
      case "text":
        chat["text"] = __message?.text;
        break;
      case "address_message":
        chat["text"] = __message.body?.text;
        break;

      case "link":
        chat["text"] = "Campaign List";
        chat["link"] = __message?.campaignLink;
        break;
      case "image":
        chat["text"] = __message?.caption;
        chat["url"] = __message?.originalUrl;
        break;
      case "file":
        chat["text"] = __message?.caption;
        chat["filename"] = __message?.filename;
        chat["size"] = __message?.size;
        chat["url"] = __message?.url;
        break;
      case "audio":
        chat["text"] = " ";  // space between quotes
        chat["url"] = __message?.url;
        break;
      case "video":
        // console.log("video",__message)
        chat["text"] = __message?.caption;
        chat["url"] = __message?.url;
        break;
      case "sticker":
        chat["text"] = "";
        chat["url"] = message?.url;
        break;
      default:
        chat["text"] = "";
    }
  } else {
    const { payload } = message; // JSON.parse(messageDetails.message);

    const payloadType = payload.type;
    chat["messageType"] = payloadType;

    switch (payloadType) {
      case "list_reply":
        chat["text"] = payload?.payload?.title;
        break;
      case "button_reply":
        chat["text"] = payload?.payload?.title;
        break;
      case "quick_reply":
        chat["text"] = payload?.payload?.text;
        break;

      case "order":
        let item = "";
        const items = payload?.context?.catalog?.order?.items;
        //console.log(payload.context.catalog.order.items)
        chat["orderItems"] = items;
        chat["text"] = `Order Details`;
        break;
      case "text":
        chat["text"] = payload?.payload?.text;
        break;
      case "nfm_reply":
        const address = payload?.payload?.addressDetails;
        // console.log(address);
        chat["text"] =
          "Pin Code : " +
          address?.in_pin_code +
          ", Address : " +
          address?.address +
          ", " +
          address?.landmark_area +
          ", " +
          address?.city +
          ", Mob.No : " +
          address?.phone_number;
        break;

      case "reaction":
        chat["text"] = payload?.payload?.emoji;
        break;
      case "image":
        chat["text"] = payload?.payload?.caption ? payload?.payload?.caption  : " " ; // space between quotes
        chat["url"] = payload?.payload?.url;
        chat["contentType"] = payload?.payload?.contentType;
        break;
      case "file":
        chat["text"] = payload.payload?.caption
          ? payload.payload?.caption
          : " "; // space between quotes
        chat["filename"] = payload?.payload?.name;
        chat["url"] = payload?.payload?.url;
        chat["contentType"] = payload?.payload?.contentType;
        break;
      case "audio":
        chat["text"] = " ";  // space between quotes
        chat["url"] = payload?.payload?.url;
        break;
      case "video":
        chat["text"] = payload?.payload?.caption
          ? payload.payload?.caption
          : " "; // space between quotes
        chat["url"] = payload?.payload?.url;
        chat["contentType"] = payload?.payload?.contentType;
        break;
      case "sticker":
        chat["text"] = "";
        chat["url"] = payload?.payload?.url;
        chat["contentType"] = payload?.payload?.contentType;
        break;

      default:
        chat["text"] = "";
    }
  }

  return chat;
}

export function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

export function StatusIcon(message) {
  if (message.status === "read") {
    return (
      <img
        width="14"
        height="14"
        className="mt-2"
        src="https://img.icons8.com/ios-filled/50/34B7F1/double-tick.png"
        alt="double-tick"
      />
    );
  } else if (message.status === "delivered") {
    return (
      <img
        width="14"
        height="14"
        className="mt-2"
        src="https://img.icons8.com/ios-filled/50/A3ABA5/double-tick.png"
        alt="double-tick"
      />
    );
  } else if (message.status === "sent") {
    return (
      <img
        width="14"
        className="mt-1"
        height="14"
        src="https://img.icons8.com/material/24/A3ABA5/checkmark--v1.png"
        alt="checkmark--v1"
      />
    );
  } else if (message.status === "failed") {
    return (
      <img
        width="14"
        height="14"
        src="https://img.icons8.com/color/48/high-importance--v1.png"
        alt="checkmark--v1"
      />
    );
  } else {
    return <></>;
  }
}

export function ticketStausSorted(dataArr) {
  let statusBasedTickets = {
    "In Progress": [],
    New: [],
    "Follow Up": [],
    Closed: [],
    noStatus: [],
  };
  dataArr.forEach((element) => {
    if (element.ticketStatus) {
      let currentElemts = statusBasedTickets[`${element?.ticketStatus}`];

      statusBasedTickets[`${element?.ticketStatus}`] = [
        ...currentElemts,
        element,
      ];
    } else {
      let noStatusElements = statusBasedTickets.noStatus;
      statusBasedTickets.noStatus = [...noStatusElements, element];
    }
  });

  return [
    ...statusBasedTickets["In Progress"],
    ...statusBasedTickets["New"],
    ...statusBasedTickets["Follow Up"],
    ...statusBasedTickets["Closed"],
    ...statusBasedTickets["noStatus"],
  ];
}

export function isTimeOver(lastTimeStamp, limitTimeStamps) {
  let currentTimerStamps = new Date().getTime();
  let timeAgo = currentTimerStamps - limitTimeStamps;
  if (lastTimeStamp) {
    return lastTimeStamp < timeAgo;
  }
  return true;
}
