import React from "react";
import styles from "./welcome.module.css";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { CustomButton } from "../../components/CustomButton";
import { useState, useRef, useEffect } from "react";
import { login } from "../../reducers/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { Text } from "../../components/Text";
import VersionTag from "../../config/VersionTag";
import { useSelector } from "react-redux";
import {
  resetCompanyToast,
  resetForgetToast,
} from "../../reducers/companySlice";
import * as Messag from "../../config/ToastMessage";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const { isLogedIn, user } = useSelector((state) => state.authenticate);
  const { newCompanyToast, forgetToast } = useSelector(
    (state) => state.company
  );

  const toast = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const defaultValues = {
    userName: "",
    password: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues });

  //function form get error message
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const showError = (message) => {
    toast.current.show({
      severity: "error",
      detail: message,
      life: 3000,
    });
  };

  const resetPage = () => {
    navigate("/resetPass");
  };

  const onSubmit = (data) => {
    if (!loading) {
      setLoading(true);
      dispatch(login(data))
        .unwrap()
        .then((res) => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          showError(err.message);
        });
    } else return;
  };

  useEffect(() => {
    if (isLogedIn && user) {
      if (user?.role === "support") navigate("/chat");
      else navigate("/dashboard");
    }
  }, [isLogedIn, user]);

  useEffect(() => {
    if (newCompanyToast) {
      toast.current.show({
        severity: "success",
        detail: Messag.Success_Register_ToastSuccessMessage,
        life: 6000,
      });
    } else if (forgetToast) {
      toast.current.show({
        severity: "success",
        detail: Messag.Forgot_ToastSuccessMessage,
        life: 6000,
      });
    }
    dispatch(resetCompanyToast());
    dispatch(resetForgetToast());
  }, []);
  return (
    <div className={` ${styles["login-module"]}`}>
      <Toast ref={toast} />
      <div className={`${styles["login-form"]}`}>
        <p className="m-0 text-base font-semibold">Sign in with Skalebot</p>
        <p className="m-0 mt-1 text-sm font-normal opacity-50">
          Seamlessly sign in to elevate your chatbot experience
        </p>
        <form onSubmit={handleSubmit(onSubmit)} className=" pt-4 p-fluid">
          <div className="field">
            <label htmlFor="userName" style={{ color: "#1A1A1A" }}>
              Username
            </label>
            <Controller
              name="userName"
              control={control}
              rules={{
                required: true && "Please enter username.",
                validate: (value) =>
                  value.trim().length > 0 || "username is required",
              }}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  placeholder="Enter username"
                  {...field}
                  className={classNames({
                    "p-invalid": fieldState.invalid,
                  })}
                />
              )}
            />
            {getFormErrorMessage("userName")}
          </div>
          <div className="field">
            <label htmlFor="password" style={{ color: "#1A1A1A" }}>
              Password
            </label>
            <Controller
              name="password"
              control={control}
              rules={{ required: true && "Please enter password." }}
              render={({ field, fieldState }) => (
                <span className="p-input-icon-right">
                  <i
                    className={`pi ${showPassword ? "pi-eye" : "pi-eye-slash"}`}
                    onClick={() => setShowPassword((prev) => !prev)}
                  />
                  <InputText
                    type={`${showPassword ? "text" : "password"}`}
                    id={field.name}
                    placeholder="Enter password"
                    {...field}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                </span>
              )}
            />
            {getFormErrorMessage("password")}
          </div>
          <div className="flex">
            <div style={{ cursor: "pointer" }} onClick={resetPage}>
              <Text type={"sub-heading"} color="#1C738E">
                Forgot Password
              </Text>
            </div>
          </div>
          <div className="m-auto w-full flex flex-row justify-content-center mt-5">
            <CustomButton
              type="submit"
              label={
                loading ? (
                  <i
                    className="pi pi-spin pi-spinner"
                    style={{ fontSize: "0.8rem" }}
                  ></i>
                ) : (
                  "Sign In"
                )
              }
              //   varient="signIn"
              varient="surface-900  font-medium text-sm border-0 p-3 sign-in-btn"
              disabled={loading}
            />
          </div>
        </form>
        <div className="mt-3 text-center">
          <span>Don't have a account?</span>
          <span
            className={`cursor-pointer ${styles.signup}`}
            role="button"
            onClick={() => navigate("/signup")}
          >
            {" "}
            Sign up
          </span>
        </div>
      </div>
    </div>
  );
};

export default Login;
