import styles from "./style.module.css";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../reducers/authSlice";
import Cookies from "js-cookie";
import { SbNavbar } from "../SbNavbar";
import { CustomButton } from "../CustomButton";
import { Text } from "../Text";
import { changeSidebarOpenStatus } from "../../reducers/appSlice";
import { ReactComponent as SkalebotLogo } from "../../assets/svgIcons/Skalebot.svg";
import { useNavigate } from "react-router-dom";
import VersionTag from "../../config/VersionTag";
import axiosInstance from "../../api/axios.instance";
import { getAuth, signOut } from "firebase/auth";
import { useEffect, useRef, useState } from "react";
import CONSTANTS from "../../config/Constants";
import CustomSaveModel from "../CustomeSaveModel";
import modulesName from "../../roles/modulesName";
import permissions from "../../roles/permissions";
import { useUtility } from "../../context/UtilityContext";

export const SlideBar = ({ setType }) => {
  const navigate = useNavigate();
  const { slidebarOpen } = useSelector((state) => state.application);
  const { user, accesskey } = useSelector((state) => state.authenticate);
  const overlayRef = useRef(null);
  const dispatch = useDispatch();
  const slideContentTab = [
    {
      label: "User Profile",
      route: "/userprofile",
      view: accesskey[modulesName.USER]?.includes(permissions.READ),
    },
    {
      label: "Payment History",
      route: "/paymenthistory",
      view: accesskey[modulesName.PAYMENT]?.includes(permissions.READ),
    },
    {
      label: "Image Inventory",
      route: "/mediaInventory",
      view: accesskey[modulesName.PRODUCT]?.includes(permissions.READ),
    },
    {
      label: "Bots",
      route: "/bots",
      view: accesskey[modulesName.BOTS]?.includes(permissions.READ),
    },
  ];
  const handleLogout = async () => {
    dispatch(changeSidebarOpenStatus());
    const auth = getAuth();
    await signOut(auth);

    dispatch(logout())
      .unwrap()
      .then(() => {
        localStorage.clear();
        axiosInstance.defaults.headers.common["authorization"] = null;
        navigate("/login");
      })
      .catch((err) => {
        console.log(err);
        //show toast message from here
      });
  };
  const { navigation } = useUtility();
  const nav = (path) => {
    navigation(path);
    if (slidebarOpen) dispatch(changeSidebarOpenStatus());
  };

  const handleOutsideClick = (event) => {
    if (
      overlayRef.current &&
      overlayRef.current.contains(event.target) &&
      slidebarOpen
    ) {
      dispatch(changeSidebarOpenStatus());
    }
  };

  const handleSlide = () => {
    dispatch(changeSidebarOpenStatus());
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [slidebarOpen]);
  const [showSave, setShowSave] = useState(false);
  const SaveModel = () => {
    return (
      <CustomSaveModel
        showSave={showSave}
        header={alertText()}
        dialogFooter={dialogFooter}
        onHide={onHide}
      />
    );
  };
  const alertText = () => {
    return <div>{CONSTANTS.SIGNOUT_CONFIRMATION}</div>;
  };
  const dialogFooter = () => {
    return (
      <div className="flex justify-content-end ">
        <CustomButton
          label="Cancel"
          varient="cancel"
          autofocus={true}
          onClick={() => {
            setShowSave(false);
          }}
        />
        <CustomButton
          label="Confirm"
          varient="filled"
          onClick={() => {
            handleLogout();
            setShowSave(false);
          }}
        />
      </div>
    );
  };
  const onHide = () => {
    setShowSave(false);
  };

  return (
    <>
      {slidebarOpen && (
        <div ref={overlayRef} className={styles["overlay"]}></div>
      )}
      <div
        className={`absolute z-5 ${styles["closeIcon"]} ${
          slidebarOpen ? "" : "opacity-0"
        }`}
        onClick={handleSlide}
      >
        <i className="pi pi-times p-2 cursor-pointer text-white" />
      </div>
      <div
        className={`flex flex-column justify-content-between ${
          styles["slidebar"]
        } ${slidebarOpen ? styles["open-slide"] : ""}`}
      >
        <SaveModel />
        <div className="flex justify-content-between gap-2 p-3">
          <div></div>
        </div>
        <div className={`${styles["link-container"]}`}>
          <div className={`flex flex-column ${styles["slide-links"]}`}>
            {slideContentTab
              .filter((x) => x.view)
              .map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`py-3 ${styles["slide-tab"]} cursor-pointer`}
                    onClick={() => {
                      if (item.route === "/bots/new") setType(false);
                      nav(item.route);
                    }}
                  >
                    <Text type={"sub-heading"}>{item.label}</Text>
                  </div>
                );
              })}
          </div>
        </div>
        <div
          className={`flex-grow-1 flex flex-column align-items-center relative`}
          style={{ minHeight: "150px" }}
        >
          <div className={`my-4 ${styles["logout-btn-container"]}`}>
            <CustomButton
              varient="filled"
              label={"Sign Out"}
              onClick={() => {
                setShowSave(true);
              }}
            />
          </div>
        </div>
        <div className="flex flex-column justify-content-end align-items-center align-contents-center mb-3">
          <SkalebotLogo width={100} />
          <div className="text-center my-3">
            <VersionTag color={"rgba(255, 255, 255, 0.5)"} />
          </div>
        </div>
      </div>
    </>
  );
};
