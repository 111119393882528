import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useState } from "react";
import { Text } from "../Text";
import EllipsisText from "../common/EllipsisText/indix";
import DataTableDemo from "../Skaletons/DataTableDemo";
import { useEffect } from "react";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { formatMobNumber } from "../../utils/commonUtils";
const CSVDataPreviewTable = ({
  data = [],
  columns = [],
  selectedRow = [],
  handleSelectRow = () => {},
}) => {
  const [dataformating, setDataFormating] = useState(false);
  const [formatedCellData, setFormatedCellData] = useState([]);

  const onSelectRow = (e) => {
    handleSelectRow(e.value);
  };

  const isSelectable = (data) => {
    let isRowValid = true;

    columns?.forEach((col) => {
      if (!col.validate(data[col.field])) isRowValid = false;
    });

    return isRowValid;
  };

  const isRowSelectable = (e) => {
    return isSelectable(e.data);
  };

  const rowClassName = (data) => {
    return isSelectable(data) ? "" : "not-selectable";
  };

  useEffect(() => {
    setDataFormating(true);
    let formateData = data?.map((item) => {
      let itemConfig = {};
      columns?.forEach((col) => {
        if (col.spreadSheetColumn != null) {
          if (col.field == "phone") {
            let mob = item[col.spreadSheetColumn?.field];
            if (mob) {
              let formatedMob = formatMobNumber(mob.toString());
              if (isPossiblePhoneNumber(formatedMob)) {
                itemConfig[col.field] = formatedMob.split(" ").join("");
              } else if (isPossiblePhoneNumber("+91" + mob.toString())) {
                itemConfig[col.field] =
                  "+91" + mob.toString().split(" ").join("");
              } else {
                itemConfig[col.field] = mob.toString().split(" ").join("");
              }
            } else {
              itemConfig[col.field] = mob;
            }
          } else {
            itemConfig[col.field] = item[col.spreadSheetColumn?.field] || null;
          }
        }
      });

      return itemConfig;
    });
    setFormatedCellData(formateData);
    setDataFormating(false);
  }, []);

  return (
    <div className="w-full">
      {dataformating || formatedCellData.length == 0 ? (
        <DataTableDemo columns={columns} />
      ) : (
        <div>
          <div className="w-full flex justify-content-between my-2">
            <Text type={"sub-heading"} color="#5F6368">
              {data.length} Records imported
            </Text>
            <Text type={"sub-heading"} color="#005068">
              {selectedRow.length} Records selected
            </Text>
          </div>
          <DataTable
            value={formatedCellData}
            className="skalebot-table"
            selectionMode="checkbox"
            selection={selectedRow}
            onSelectionChange={onSelectRow}
            scrollable
            scrollHeight="450px"
            isDataSelectable={isRowSelectable}
            rowClassName={rowClassName}
            paginator
            rows={30}
            rowsPerPageOptions={[30, 60, 90, 120]}
            dragSelection
          >
            <Column
              selectionMode="multiple"
              headerStyle={{ width: "3rem" }}
              exportable={false}
            />

            {columns?.map((col, i) => (
              <Column
                key={col.field}
                field={col?.field}
                header={col.label}
                headerStyle={{ textWrap: "nowrap" }}
                body={(rowData) => (
                  <>
                    <EllipsisText text={rowData[col?.field] || "-"} />
                    {!col.validate(rowData[col?.field]) && (
                      <small style={{ color: "red" }}>* Invalid data</small>
                    )}
                  </>
                )}
                bodyStyle={{ minWidth: "200px" }}
              />
            ))}
          </DataTable>
          <div className="my-2">
            <small>* Row with invalid details, can't be selected.</small>
          </div>
        </div>
      )}
    </div>
  );
};

export default CSVDataPreviewTable;
