import styles from "./style.module.css";
import { Text } from "../../components/Text";
import { ReactComponent as Bot } from "../../assets/bot.svg";
import { ReactComponent as CheckedIcon } from "../../assets/svgIcons/Check_ring_duotone_line.svg";
import { ReactComponent as CatalogueIcon } from "../../assets/catalog_icon.svg";
import { ReactComponent as CategoriesIcon } from "../../assets/categories_icon.svg";
import { ReactComponent as BussnessIcon } from "../../assets/sync_buss.svg";
import { Card } from "primereact/card";
import { Link } from "react-router-dom";
import FbRegistrationModule from "../FbRegistrationModule";

const TrailDashboard = () => {
  const benefits = [
    {
      label: "Campaign managemnt",
    },
    {
      label: "Read and unread indicator",
    },
    {
      label: "1000+ broadcast per day",
    },
    {
      label: "Unlimited Product Share",
    },
    {
      label: "Business Agent Allocation",
    },
  ];
  const cardDatav = [
    {
      icon: function () {
        return <CatalogueIcon />;
      },
      title: "Create Catalogue ",
      desc: "Users can modify product info, add new products, update pricing, and manage Inventory levels",
    },
    {
      icon: function () {
        return <CategoriesIcon />;
      },
      title: "Create Categories",
      desc: " When creating a new category, you will need to enter relevant information about the category",
    },
    {
      icon: function () {
        return <BussnessIcon />;
      },
      title: "Sync your business contacts & Groups",
      desc: "sync your contacts from your Gmail or csv",
    },
  ];
  return (
    <>
      <div className="my-3">
        <FbRegistrationModule />
        {/* <div
          className={`w-full relative p-3 lg:p-6 xl:p-6 ${styles["info-continer"]}`}
        >
        

          <Text
            type="sub-heading"
            color="#ffffff"
            style={{ fontSize: "1.5rem" }}
          >
            We need couple of more details to onboard you to run campaigns
          </Text>
          <Text
            type="heading"
            color="#ffffff"
            style={{ fontSize: "2rem", marginTop: "0.7rem" }}
          >
            Please connect with our product experts for any help
          </Text>

          <div className=" mt-4 w-full flex flex-wrap justify-content-center align-items-center gap-3">
            <div
              className={`relative px-3 p-6 lg:p-6 xl:p-6 ${styles["contact-info"]} `}
            >
              <div className={`absolute py-1 px-2 ${styles["contact-tag"]}`}>
                <Text type={"sub-heading"}>Contact Details...</Text>
              </div>
              <div className="flex flex-wrap gap-6 justify-content-center align-items-center">
                <div className="flex gap-6 flex-wrap  align-items-center">
                  <div className="text-left">
                    <div className="flex align-items-center">
                      <Text
                        type={"heading"}
                        color={"#ffffff"}
                        style={{ fontSize: "1.2rem" }}
                      >
                        Goldwin paul kavala
                      </Text>
                      <Text
                        color={"#fff"}
                        style={{
                          fontSize: "0.8rem",
                          marginLeft: "0.8rem",
                          marginTop: "0.3rem",
                        }}
                      >
                        Sales&nbsp;Manager
                      </Text>
                    </div>
                    <Link
                      to={`https://wa.me/${+919490337682}`}
                      style={{ textDecoration: "none" }}
                      target="_"
                    >
                      <div className="flex gap-1 align-items-center mt-1">
                        <i
                          className="pi pi-whatsapp"
                          style={{
                            fontSize: "0.8rem",
                            marginRight: "5px",
                            color: "#ffffff",
                          }}
                        />{" "}
                        <Text type={"sub-heading"} color={"#ffffff"}>
                          +91 9490337682
                        </Text>
                      </div>
                    </Link>
                  </div>
                  <div
                    className={`hidden lg:block xl:block ${styles["divider"]}`}
                  ></div>
                  <div className="text-left">
                    <div className="flex justify-content-end align-items-center">
                      <Text
                        type={"heading"}
                        color={"#ffffff"}
                        style={{ fontSize: "1.2rem" }}
                      >
                        Priyanka&nbsp;Ganguly
                      </Text>
                      <Text
                        color={"#fff"}
                        style={{
                          fontSize: "0.8rem",
                          marginLeft: "0.8rem",
                          marginTop: "0.3rem",
                        }}
                      >
                        Sales&nbsp;Manager
                      </Text>
                    </div>
                    <Link
                      to={`https://wa.me/${+919900064161}`}
                      style={{ textDecoration: "none" }}
                      target="_"
                    >
                      <div className="flex gap-1 align-items-center mt-1">
                        <i
                          className="pi pi-whatsapp"
                          style={{
                            fontSize: "0.8rem",
                            marginRight: "5px",
                            color: "#ffffff",
                          }}
                        />{" "}
                        <Text type={"sub-heading"} color={"#ffffff"}>
                          +91 9900064161
                        </Text>
                      </div>
                    </Link>
                  </div>
                </div>

                <div
                  className={`hidden lg:block xl:block ${styles["divider"]}`}
                ></div>
                <div>
                  <Bot />
                </div>
              </div>
            </div>
            <div
              className={`flex flex-column lg:m-4 xl:m-4 justify-content-center text-left pl-3 lg:pl-6 xl:pl-6 lg:border-left-1 xl:border-left-1 border-gray-500`}
            >
              <Text color={"#fff"}>You'll Get Benefits</Text>
              <div className="mt-3">
                {benefits?.map((item, i) => (
                  <div className="flex gap-2 my-2" key={i}>
                    <CheckedIcon
                      style={{ maxWidth: "18px", minWidth: "18px" }}
                    />
                    <Text color={"#fff"}>{item.label}</Text>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={`w-full mt-6 ${styles["card-container"]}`}>
            <div className="w-full flex flex-row flex-column lg:flex-row xl:flex-row gap-3 justify-content-center">
              {cardDatav.map((item, i) => (
                <Card
                  key={i}
                  className={`w-full lg:w-3 xl:w-3 mb-3 custom-card `}
                >
                  {item.icon()}
                  <br />
                  <br />

                  <Text type={"heading"}>{item.title}</Text>
                  <br />
                  <Text type={"sub-heading"}>{item.desc}</Text>
                </Card>
              ))}
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default TrailDashboard;
