import { Text } from "../Text";
import { Calendar } from "primereact/calendar";

const DateRangePicker = ({
  fromDate = "",
  toDate = "",
  handleFromDate = () => {},
  handleToDate = () => {},
}) => {
  const resetDate = () => {
    handleFromDate("");
    handleToDate("");
  };
  return (
    <div className="flex align-items-center gap-2 flex-grow-0 sm:flex-grow-1 md:flex-grow-1">
      <div className="flex justify-content-between align-items-center gap-2">
        <Text type={"sub-heading"}>From</Text>
        <Calendar
          showIcon={true}
          placeholder="DD/MM/YYYY"
          dateFormat="dd/mm/yy"
          className={`sklbt-calender `}
          value={fromDate}
          onChange={(e) => handleFromDate(e.value)}
          maxDate={new Date()}
        ></Calendar>
      </div>
      <div className="flex  justify-content-between align-items-center gap-2">
        <Text type={"sub-heading"}>To</Text>
        <Calendar
          showIcon={true}
          placeholder="DD/MM/YYYY"
          dateFormat="dd/mm/yy"
          className={`sklbt-calender `}
          value={toDate}
          onChange={(e) => handleToDate(e.value)}
          maxDate={new Date()}
          minDate={fromDate}
        ></Calendar>
      </div>
      {(fromDate || toDate) && (
        <i className="pi pi-times cursor-pointer" onClick={resetDate} />
      )}
    </div>
  );
};

export default DateRangePicker;
