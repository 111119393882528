import { useSelector } from 'react-redux';
import { Route, Navigate } from 'react-router-dom';

const RoleBasedRoute = ({ children , allowedRoles,location,permission }) => {
  const {accesskey,pause } = useSelector(state => state.authenticate)
  if (accesskey[location]?.includes(permission)||pause) {
    return children;
  } else {
    return <Navigate to="/unauthorized" />;
  }
}

export default RoleBasedRoute