import React, { useRef } from "react";
import styles from "./style.module.css";
import style from "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { CustomButton } from "../../../components/CustomButton";
import { getTemplates } from "../../../reducers/templateReducer/templateActions";
import { Card } from "primereact/card";
import { ReactComponent as SendButton } from "../../../assets/svgIcons/svgIcons/sendbuttonicon.svg";
import { ReactComponent as CancelButton } from "../../../assets/svgIcons/cancelIcon.svg";

import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { startConversation } from "../../../reducers/ShareMessage/actions";
import { useLayout } from "../../../context/LayoutContext";
import { classNames } from "primereact/utils";
import { InputTextarea } from "primereact/inputtextarea";
import { formateMessageInput } from "../../../utils/templateUtils";
import CONSTANTS from "../../../config/Constants";
import CustomSaveModel from "../../../components/CustomeSaveModel";

const StartConversation = () => {
  const { templateList } = useSelector((state) => state.template);
  const { chatId, user } = useSelector((state) => state.enqChats);
  const { loading } = useSelector((state) => state.shareMessage);

  const dispatch = useDispatch();
  const [isStartConversation, setIsStartConversation] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [textTemplates, setTextTemplates] = useState([]);
  const [isTemplateSelected, setIsTemplateSelected] = useState(false);

  const defaultValues = {};

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useForm({ defaultValues });

  useEffect(() => {
    setIsTemplateSelected(false);
    setIsStartConversation(false);
    setSelectedTemplate(" ");
  }, [user]);

  const { setLoading, toast } = useLayout();
  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  useEffect(() => {
    let temp = [];
    templateList.forEach((element) => {
      if (element.templateType == "TEXT") {
        // console.log(element)
        temp.push(element);
      }
    });
    setTextTemplates(temp);
  }, [templateList]);

  const handleTemplateChange = (temp) => {
    //  console.log(temp.target.value);
    setSelectedTemplate(temp.target.value);
    setIsTemplateSelected(true);
  };
  const [tempVariables, setTempvariables] = useState({});
  const [variables, setVariables] = useState([]);
  const handleEditVariable = (e, variableName) => {
    let updateVariableData = tempVariables;
    updateVariableData[variableName] = `${e.target.value}`;
    setTempvariables({ ...updateVariableData });
  }; //Use For Updating field Value

  const [message, setMessage] = useState();
  useEffect(() => {
    if (selectedTemplate?.containerMeta) {
      const { data } = JSON.parse(selectedTemplate.containerMeta);
      setValue("message", data, { shouldValidate: true });
      setVariables(selectedTemplate?.variables || []);
      setMessage(data);
      let tempVariable = {};
      selectedTemplate?.variables?.map((x) => {
        tempVariable[x.variableName] = x.variableValue;
        setValue(x.variableName, x.variableValue, { shouldValidate: true });
      });
      setTempvariables(tempVariable || {});
    }
    return () => {
      setTempvariables({});
    };
  }, [selectedTemplate]);

  useEffect(() => {
    if (tempVariables && selectedTemplate?.containerMeta) {
      var { data } = JSON.parse(selectedTemplate?.containerMeta);
      for (let x in tempVariables) {
        data = data.replace(x, tempVariables[x] !== "" ? tempVariables[x] : x);
      }
      setMessage(data);
    }
  }, [tempVariables]);
  async function onSubmit(value) {
    let variables = {};
    if (selectedTemplate?.variables) {
      selectedTemplate?.variables?.forEach((x) => {
        variables[x.variableName] = tempVariables[x.variableName];
      });
    }
    const { data: msg } = JSON.parse(selectedTemplate?.containerMeta);
    const data = {
      SKUCodes: [],
      message: msg,
      customerMobile: user.mob,
      templateId: selectedTemplate?.templateId,
      variables: { ...variables },
    };
    dispatch(startConversation({ config: data }))
      .unwrap()
      .then((x) => {})
      .catch((err) => {
        toast.current.show({ severity: "error", detail: err?.message });
      });
    setIsTemplateSelected(!isTemplateSelected);
    setSelectedTemplate(" ");
    setIsStartConversation(false);
  }
  const broadcastmessage = useRef(null);
  const [showSave, setShowSave] = useState(false);
  const SaveModel = () => {
    return (
      <CustomSaveModel
        showSave={showSave}
        header={alertText()}
        dialogFooter={dialogFooter}
        onHide={onHide}
      />
    );
  };
  const alertText = () => {
    return <div>{CONSTANTS.ENQ_CHAT_BROADCAST_CONFIRMATION}</div>;
  };
  const dialogFooter = () => {
    return (
      <div className="flex justify-content-end ">
        <CustomButton
          label="Cancel"
          varient="cancel"
          autofocus={true}
          onClick={() => {
            setShowSave(false);
          }}
        />
        <CustomButton
          label="Confirm"
          varient="filled"
          onClick={() => {
            onSubmit(broadcastmessage?.current);
            setShowSave(false);
          }}
        />
      </div>
    );
  };
  const onHide = () => {
    setShowSave(false);
  };
  function templateMsg() {
    return (
      <form
        onSubmit={handleSubmit((data) => {
          broadcastmessage.current = data;
          setShowSave(true);
        })}
        className="p-fluid w-full"
        encType="multipart/form-data"
      >
        <SaveModel />
        <div className="field mt-2 w-12 lg:w-10">
          <label htmlFor="message">Body *</label>
          <Controller
            name="message"
            control={control}
            rules={{ required: "message is required." }}
            render={({ field, fieldState }) => (
              <InputTextarea
                className={classNames({
                  "p-invalid": fieldState.invalid,
                })}
                id={field.name}
                value={message}
                rows={5}
                cols={30}
                maxLength={1028}
                autoResize
                disabled
                placeholder="Enter your message (max 1028 chars)"
              />
            )}
          />

          <div className="my-2">
            {variables?.map((item, i) => {
              return (
                <div
                  className="flex gap-2 justify-content-center align-items-center my-3"
                  key={i}
                >
                  <div className="w-3">
                    {i == 0 && <label>Variable</label>}
                    <InputText value={item.variableName} disabled />
                  </div>
                  <div className="relative flex-grow-1">
                    {i == 0 && <label>Value</label>}
                    <div className="flex align-items-center gap-2">
                      <Controller
                        rules={{ required: true }}
                        id={item.variableName}
                        name={item.variableName}
                        control={control}
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            name={field.name}
                            value={tempVariables[item.variableName]}
                            placeholder={`Enter value`}
                            onChange={(e) => {
                              handleEditVariable(e, item.variableName);
                              field.onChange(e.target.value);
                            }}
                            required
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className={`sendbutton w-full flex justify-content-between mt-4 `}>
          <div className="w-6 md:w-4">
            <Dropdown
              value={selectedTemplate}
              options={textTemplates}
              onChange={handleTemplateChange}
              optionLabel="elementName"
              placeholder="Choose Template"
              className="w-full capitalize"
            />
          </div>
          <button type="submit">
            <SendButton />
          </button>
        </div>
      </form>
    );
  }

  return (
    <div className="w-12 flex flex-column">
      {!isTemplateSelected && (
        <div className="pt-2 pb-2">
          <div className="text-base font-semibold">
            {CONSTANTS.ENQ_CHAT_BROADCAST_MSG_HEADER}
          </div>
          <div>{CONSTANTS.ENQ_CHAT_BROADCAST_MSG}</div>
        </div>
      )}

      {!isTemplateSelected ? (
        <div className="w-full md:w-5 lg:w-4 xl:w-3">
          {!isStartConversation ? (
            <CustomButton
              varient="filled"
              label={"Start Conversation"}
              onClick={() => {
                setIsStartConversation(!isStartConversation);
              }}
            />
          ) : (
            <Dropdown
              value={selectedTemplate}
              options={textTemplates}
              onChange={handleTemplateChange}
              optionLabel="elementName"
              placeholder="Choose Template"
              className="w-full"
            />
          )}
        </div>
      ) : (
        <div className="w-full">
          <div className="card __cardDiv">
            <Card className={`  `}>
              <div className="w-full flex justify-content-end pr-1">
                <div
                  className="p-1 border-circle flex justify-content-center align-items-center cursor-pointer"
                  style={{ height: "30px", width: "30px" }}
                  onClick={() => {
                    setIsTemplateSelected(!isTemplateSelected);
                    setSelectedTemplate(" ");
                    setIsStartConversation(false);
                  }}
                >
                  <CancelButton />
                </div>
              </div>
              <div className="flex flex-column justify-content-center align-items-center w-full">
                {templateMsg()}
              </div>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
};

export default StartConversation;
