export const MAX_DOCUMENT_SIZE = 100; //in mb
export const MAX_IMAGE_SIZE = 5;
export const MAX_VIDEO_SIZE = 16;
export const NO_OF_VIDEOS_AND_IMAGES = 5;
export const NO_DOCUMENT = 5;
export const IMAGE_INPUT_EXTENTIONS = "image/png, image/jpeg";
export const VIDEO_INPUT_EXTENTIONS = "video/mp4, video/3gpp";
export const DOC_INPUT_EXTENTIONS = "*/* ";
// export const DOC_INPUT_EXTENTIONS =
//   "image/png, image/jpeg , video/mp4, video/3gpp, application/pdf ,application/x-zip-compressed, application/word ";
