import React, { useRef } from "react";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setImage,
  setVideo,
  setIsFileSelect,
  setDocument,
  setIsVideoSelected,
  setIsImageSelected,
  setFileSending,
  changeTicketID,
  updateUserStatus,
  clearfiles,
} from "../../reducers/enqChatSlice";
import { useEffect, useState } from "react";
import {
  API_GET_IMG_URL,
  API_SEND_MSG,
  API_START_CHAT,
  API_UPLOAD_FILE,
  API_UPLOAD_IMG,
} from "../../api/chat.service";
import { InputText } from "primereact/inputtext";
import CustomSaveModel from "../CustomeSaveModel";
import CONSTANTS from "../../config/Constants";
import { CustomButton } from "../CustomButton";
import { ReactComponent as SendButton } from "../../assets/svgIcons/svgIcons/sendbuttonicon.svg";
import Fileviewer from "../Fileviewer";

const FileInputsComponenets = () => {
  const { user, isFileSelect, fileSending, files, uploadlock } = useSelector(
    (state) => state.enqChats
  );
  const [text, setText] = useState({});
  const closedTicketStatus = user?.ticketStatus === "Closed";
  const [index, setIndex] = useState(0);
  const dispatch = useDispatch();
  const controller = useRef(null);
  const showclose = useRef(null);

  function handelCancelClick() {
    controller.current = false;
    showclose.current = true;
    dispatch(setIsFileSelect(false));
    dispatch(clearfiles());
    dispatch(setFileSending(false));
  }

  const [fileuploadpercent, setFileuploadpercent] = useState({});
  const [showSave, setShowSave] = useState(false);

  const SaveModel = () => {
    return (
      <CustomSaveModel
        showSave={showSave}
        header={alertText()}
        dialogFooter={dialogFooter}
        onHide={onHide}
      />
    );
  };

  const alertText = () => {
    return <div>{CONSTANTS.ENQ_CHAT_TICKET_CONFIRMATION}</div>;
  };

  const dialogFooter = () => {
    return (
      <div className="flex justify-content-end ">
        <CustomButton
          label="Cancel"
          varient="cancel"
          autofocus={true}
          onClick={() => {
            setShowSave(false);
          }}
        />
        <CustomButton
          label="Confirm"
          varient="filled"
          onClick={() => {
            handleSend();
            setShowSave(false);
          }}
        />
      </div>
    );
  };

  const onHide = () => {
    setShowSave(false);
  };

  async function handleSend() {
    controller.current = true;
    showclose.current = true;
    try {
      dispatch(setFileSending(true));
      const promises = [];

      // Map over files array to create promises for each file
      const dataArray = await Promise.all(
        files.map(async (x, i) => {
          const data = {
            payload: {},
          };

          if (closedTicketStatus) {
            data.customerMobile = user?.mob;
          }

          function onUploadProgress(progressEvent) {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setFileuploadpercent((filespercent) => {
              return { ...filespercent, [x.id]: percentCompleted };
            });
          }
          if (!controller.current) {
            return;
          }
          data.payload.caption = text[i] ? text[i] : " ";

          const resp = await API_GET_IMG_URL(user);
          const uploadUrl = resp.data.src;
          if (resp) {
            let uploadPromise;
            switch (x.type) {
              case "image":
                uploadPromise = API_UPLOAD_FILE(uploadUrl, x.data, {
                  onUploadProgress,
                  headers: { "Content-Type": "image/png" },
                });
                data.payload.type = "image";
                data.payload.originalUrl = resp.data.url;
                data.payload.previewUrl = resp.data.url;
                break;
              case "video":
                uploadPromise = API_UPLOAD_FILE(uploadUrl, x.data, {
                  onUploadProgress,
                  headers: { "Content-Type": "video/mp4" },
                });
                data.payload.type = "video";
                data.payload.url = resp.data.url;
                data.payload.previewUrl = resp.data.url;
                break;
              default:
                uploadPromise = API_UPLOAD_FILE(uploadUrl, x.data, {
                  onUploadProgress,
                  headers: { "Content-Type": x.data.type },
                });
                data.payload.type = "file";
                data.payload.previewUrl = resp.data.url;
                data.payload.url = resp.data.url;
                data.payload.text = resp.data.url;
                data.payload.filename = x.data.name;
                data.payload.size = x.data.size;
                break;
            }

            promises.push(uploadPromise.then(() => resp));
          }
          return data;
        })
      );

      const results = await Promise.all(promises);
      if (!controller.current) {
        return;
      } else {
        showclose.current = false;
      }

      let ticketId = user?.ticketId;
      for (let i = 0; i < dataArray.length; i++) {
        const data = dataArray[i];
        try {
          if (i === 0) {
            let resp;
            if (!closedTicketStatus) {
              resp = await API_SEND_MSG(ticketId, data);
            } else {
              resp = await API_START_CHAT(data);
              ticketId = resp?.data?.id;
              dispatch(changeTicketID(ticketId));
              dispatch(
                updateUserStatus({ ticketStatus: resp.data.ticketStatus })
              );
            }
          } else {
            await API_SEND_MSG(ticketId, data);
          }
        } catch (error) {
          console.error("API call failed:", error);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      handelCancelClick();
      dispatch(setFileSending(false));
    }
  }

  const addCaption = (e) => {
    setText((x) => {
      return { ...x, [index]: e.target.value };
    });
  };

  const showfiletoast = () => {};

  return (
    <div className="container">
      <SaveModel />
      <div className="flex flex-column justify-content-center align-items-center __Container">
        {!fileSending && (
          <div className="w-full absolute" style={{ top: "10px" }}>
            <i
              onClick={handelCancelClick}
              className={`pi pi-times cursor-pointer`}
              style={{ fontSize: "1.3rem" }}
            ></i>
          </div>
        )}
        <div>
          {fileSending ? (
            <div
              className="absolute top-0 flex flex-column justify-content-center align-items-center __spinner "
              style={{ right: "-14px" }}
            >
              <div className="w-full __crossIconCancel ">
                {showclose.current && (
                  <i
                    onClick={handelCancelClick}
                    className={`pi pi-times cursor-pointer `}
                    style={{ fontSize: "1.3rem" }}
                  ></i>
                )}
              </div>
              <i
                className="pi  pi-spin pi-spinner __spinnerIcon mt-6"
                style={{ fontSize: "4rem" }}
              ></i>
            </div>
          ) : (
            <div> </div>
          )}
          <div>
            {isFileSelect && (
              <div className="Body">
                <Fileviewer
                  index={index}
                  setIndex={setIndex}
                  fileuploadpercent={fileuploadpercent}
                  setFileuploadpercent={setFileuploadpercent}
                />
              </div>
            )}
          </div>
        </div>
        <div className="flex absolute bottom-0 align-items-center w-12 gap-2">
          <div className="w-12">
            <InputText
              className="w-12 textInput"
              type="text"
              placeholder="Type a message"
              onChange={addCaption}
              value={text[index] ? text[index] : ""}
            />
          </div>
          <div className="send">
            {fileSending ? (
              <button
                className="spinerButton"
                style={{ backgroundColor: "rgb(121, 181, 199)" }}
              >
                <i className="pi pi-spin pi-spinner"></i>
              </button>
            ) : !uploadlock ? (
              <button
                className="spinerButton"
                onClick={
                  closedTicketStatus ? () => setShowSave(true) : handleSend
                }
              >
                <SendButton />
              </button>
            ) : (
              <button
                className="spinerButton"
                onClick={showfiletoast}
                style={{ opacity: "0.3" }}
              >
                <SendButton />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileInputsComponenets;
