import "./customDialogStyle.css";
import { Dialog } from "primereact/dialog";
import { Text } from "../Text";

const CustomDialog = ({ header=()=>{}, visible, footer, onHide = () => {},className='',prevCss=true, children,style={} }) => {
  
  return (
    <Dialog
      header={header}
      visible={visible}
      onHide={onHide}
      footer={footer}
      className={`${prevCss?`custom-dialog`:``} ${className}`}
      style={style}
    >
      {children}
    </Dialog>
  );
};

export default CustomDialog;
