import { useState } from "react";
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../MultiInput/TextInput";
import QuickReply from "../MultiInput/QuickReply";
import { deleteConnectedNodes } from "../../reducers/canvas/canvasSlice";
import List from "../MultiInput/List";
import ActionInput from "../MultiInput/ActionInput";
import HttpInput from "../MultiInput/HttpInput";
import { Text } from "../Text";
import SklbtConfirmation from "../common/SklbtConfirmation";
import { CANVAS_NODE_TITLES } from "../CustomNodes/node_constant";
import TemplateNodeInput from "../MultiInput/TemplateNodeInput";
const SidePanel = ({ handleSave = () => {}, handleCancel = () => {} }) => {
  const dispatch = useDispatch();

  const { inputType } = useSelector((state) => state.multiInput);
  const { selectedNode, configScreenOpen } = useSelector(
    (state) => state.canvas
  );
  const [deleteNodesAlert, setDeleteNodesAlert] = useState(false);

  const renderConfigurationScreen = (nodeType) => {
    switch (nodeType) {
      case "quick_reply":
        return <QuickReply onSave={handleSave} onCancel={handleCancel} />;
      case "list":
        return <List onSave={handleSave} onCancel={handleCancel} />;
      case "action":
        return <ActionInput onCancel={handleCancel} />;
      case "http":
        return <HttpInput onSave={handleSave} onCancel={handleCancel} />;
      case "template":
        return (
          <TemplateNodeInput onSave={handleSave} onCancel={handleCancel} />
        );
      default:
        return (
          <TextInput
            inputType={nodeType}
            onSave={handleSave}
            onCancel={handleCancel}
          />
        );
    }
  };

  const handleDeleteConnectedNodes = () => {
    dispatch(deleteConnectedNodes({ deleteNode: selectedNode }));
    setDeleteNodesAlert(false);
  };

  return (
    <div
      className={`p-2 ${styles.sidePanel} ${
        configScreenOpen ? styles.showPanel : ""
      }`}
    >
      {deleteNodesAlert && (
        <SklbtConfirmation
          visible={deleteNodesAlert}
          handleConfirm={handleDeleteConnectedNodes}
          handleReject={() => setDeleteNodesAlert(false)}
          message={`Are you sure, You want to delete selected node?`}
        />
      )}
      <div className="flex flex-column justify-content-between h-full">
        <div>
          <header className="flex justify-content-between py-3 capitalize">
            <Text type="heading">
              Edit {CANVAS_NODE_TITLES[inputType]} node
            </Text>
            <div className="flex align-items-center gap-2">
              {selectedNode?.id && (
                <i
                  title="delete"
                  className="pi pi-trash cursor-pointer"
                  onClick={() => setDeleteNodesAlert(true)}
                ></i>
              )}
            </div>
          </header>
          <div className={`${styles["h-line"]}`}></div>
        </div>

        <div className="flex-grow-1 pt-3">
          {renderConfigurationScreen(inputType)}
        </div>
      </div>
    </div>
  );
};

export default SidePanel;
