import { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import TableActionsContainer from "../CustomTable/TableActionsContainer";
import { TabMenu } from "primereact/tabmenu";
import DebounceSearch from "../common/DebounceSearch";
import {
  changeSelectedGroup,
  getGroups,
  getsortedCustomers,
  schangePage,
  schangelimit,
  sgchangePage,
  sgchangelimit,
} from "../../reducers/customerTableSlice";
import CustomPaginator from "../../components/CustomPaginator";
import { useLayout } from "../../context/LayoutContext";
import {
  changeselectedCustomers,
  changeselectedGroupId,
} from "../../reducers/ShareMessage/messageSlice";
import { Text } from "../Text";
import SelectedItemList from "../SelectedItemsList";
import { formatMobNumber } from "../../utils/commonUtils";

export const ModSelectableCustomer = () => {
  const phoneBodyTemplate = (rowdata) => {
    let value = rowdata?.phone?.toString();
    return value ? formatMobNumber(value) : "";
  };
  const {
    customerData,
    spage,
    slimit,
    sgpage,
    sglimit,
    selectedCustomer,
    groups,
    totalGroupCount,
    loading,
    totalCustomerCount,
    selectedGroup,
  } = useSelector((state) => state.customerTable);
  const { selectedCustomers } = useSelector((state) => state.shareMessage);
  const [inputValue, setInputValue] = useState("");
  const [ginputValue, setgInputValue] = useState("");
  const { broadcastdetails, selectedGroupId } = useSelector(
    (state) => state.shareMessage
  );
  useEffect(() => {
    if (broadcastdetails?.configData?.groupIds?.length > 0) {
      setIndex(1);
    }
  }, [broadcastdetails]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (broadcastdetails) {
      if (broadcastdetails?.configData?.customerIds?.length > 0) {
        let cust = broadcastdetails?.configData?.customerIds.map((x) => {
          return { id: x };
        });
        // dispatch(updatecustomers({ selectedCust: cust, currCust: [] }));
        dispatch(changeselectedCustomers({ selectedCust: cust, currCust: [] }));
      } else {
        if (broadcastdetails?.configData?.groupIds?.length > 0) {
          let grp = broadcastdetails?.configData?.groupIds.map((x) => {
            return { id: x };
          });
          dispatch(changeSelectedGroup({ id: grp[0].id }));
          dispatch(changeselectedGroupId(grp[0].id));
        }
      }
    }
  }, [broadcastdetails]);

  useEffect(() => {
    dispatch(
      getsortedCustomers({
        page: spage,
        limit: slimit,
        globalFilterValue: inputValue,
        customerIds: broadcastdetails?.configData?.customerIds
          ? broadcastdetails?.configData?.customerIds
          : [],
      })
    );
  }, [spage, slimit, inputValue]);

  useEffect(() => {
    dispatch(
      getGroups({
        page: sgpage,
        limit: sglimit,
        globalFilterValue: ginputValue,
      })
    );
  }, [sgpage, sglimit, ginputValue]);

  const renderHeader1 = () => {
    return (
      <DebounceSearch
        placeholder={index === 0 ? "Search Customer" : "Search Groups"}
        inputValue={index === 0 ? inputValue : ginputValue}
        handleInputChange={index === 0 ? setInputValue : setgInputValue}
      />
    );
  };
  const selectGroups = (e) => {
    if (e) {
      dispatch(changeSelectedGroup(e));
      dispatch(changeselectedGroupId(e.id));
    } else {
      dispatch(changeSelectedGroup({ id: "all", groupName: "All Groups" }));
      dispatch(changeselectedGroupId("all"));
    }
  };
  const selectCustomers = (e) => {
    const selectedCust = e.value;
    const currCust = customerData;
    // dispatch(updatecustomers({ selectedCust, currCust }));
    dispatch(changeselectedCustomers({ selectedCust, currCust }));
  };
  useEffect(() => {
    setLoading(loading);
  }, [loading]);
  const { toast, setLoading } = useLayout();

  const [index, setIndex] = useState(0);
  const handleTabChange = (e) => {
    if (index === 0) {
      if (selectedCustomer.length > 0) {
        toast?.current?.show({
          severity: "error",
          detail: "Customer Is Selected",
        });
        return;
      }
    }
    if (index === 1) {
      if (selectedGroup.id !== "all") {
        toast?.current?.show({
          severity: "error",
          detail: "Groups Is Selected",
        });
        return;
      }
    }
    if (e.index !== index) {
      setIndex(e.index);
    }
  };
  const items = [
    {
      label: "All Customers",
      icon: "",
      value: "all",
      route: "/customers/list",
    },
    { label: "Groups", icon: "", value: "groups", route: "/customers/groups" },
  ];
  const totalCustomer = (groupData) => {
    return groupData?.customerIds?.length;
  };
  const isValidTemplate = (groupData) => {
    return groupData?.customerIds?.length ? true : false;
  };
  const rowClassName = (data) => {
    return isValidTemplate(data) ? "" : "not-selectable";
  };
  const isRowSelectable = ({ data }) => {
    return data?.customerIds?.length ? true : false;
  };

  return (
    <>
      <div className="mb-2 ">
        <TableActionsContainer>
          <div className="flex justify-content-between align-items-center flex-wrap gap-3">
            <TabMenu
              model={items}
              activeIndex={index}
              onTabChange={handleTabChange}
              className={`custom-tabmenu`}
            />
            <div className="flex gap-3 flex-wrap">
              <div className="flex gap-2 justify-content-end align-items-center flex-grow-1">
                {renderHeader1()}
              </div>
              {/* <div>
                <div className="flex  justify-content-end">
                  <CustomPaginator
                    page={index === 0 ? spage : sgpage}
                    limit={index === 0 ? slimit : sglimit}
                    changelimit={index === 0 ? schangelimit : sgchangelimit}
                    totalRecords={
                      index === 0 ? totalCustomerCount : totalGroupCount
                    }
                    changePage={index === 0 ? schangePage : sgchangePage}
                  />
                </div>
              </div> */}
            </div>
          </div>
        </TableActionsContainer>
      </div>

      <div>
        {index === 0 ? (
          <div className={`card overflow-y-auto`}>
            <div>
              {selectedCustomers.length !== 0 && (
                <div className="px-3 my-2 flex gap-2 align-items-center">
                  <Text type={"sub-heading"}>
                    Selected&nbsp;Customers&nbsp;[{selectedCustomers.length}
                    ]&nbsp;:
                  </Text>
                  <SelectedItemList
                    items={selectedCustomers}
                    itemLabel={"id"}
                    removeId={"id"}
                    target={"customer"}
                  />
                </div>
              )}
            </div>
            <DataTable
              value={[...customerData]}
              selection={[...selectedCustomers]}
              onSelectionChange={(e) => selectCustomers(e)}
              dataKey="id"
              className={`skalebot-table min-h-full border-round-left-xs ${
                selectedGroupId !== "all" ? "p-disabled" : ""
              }`}
            >
              <Column selectionMode="multiple" className=""></Column>
              <Column
                field="id"
                header={"Customer Id"}
                className="w-2 capitalize text-sm font-normal"
              ></Column>
              <Column
                field="name"
                header={"Name"}
                className="w-4 capitalize text-sm font-normal"
              ></Column>
              <Column
                field="phone"
                header={"Phone"}
                body={phoneBodyTemplate}
                className="w-5 text-sm font-normal"
              ></Column>
            </DataTable>
          </div>
        ) : (
          <div>
            <DataTable
              value={[...groups]}
              selection={[selectedGroup]}
              onSelectionChange={(e) => selectGroups(e.value)}
              dataKey="id"
              isDataSelectable={isRowSelectable}
              rowClassName={rowClassName}
              className={`skalebot-table min-h-full border-round-left-xs `}
            >
              <Column
                selectionMode="single"
                className="w-1"
                style={{ flex: "none" }}
                exportable={false}
              ></Column>
              <Column field="id" header="Id" className="text-sm font-normal" />
              <Column
                header="Group Name"
                field="groupName"
                className="text-sm font-normal"
                bodyStyle={{ height: "50px", textTransform: "capitalize" }}
              />
              <Column
                header="Total Customers"
                className="text-sm font-normal"
                field={totalCustomer}
              />
            </DataTable>
          </div>
        )}
        <div className="mt-3">
          <TableActionsContainer>
            <div className="flex  justify-content-end">
              <CustomPaginator
                page={index === 0 ? spage : sgpage}
                limit={index === 0 ? slimit : sglimit}
                changelimit={index === 0 ? schangelimit : sgchangelimit}
                totalRecords={
                  index === 0 ? totalCustomerCount : totalGroupCount
                }
                changePage={index === 0 ? schangePage : sgchangePage}
              />
            </div>
          </TableActionsContainer>
        </div>
      </div>
    </>
  );
};
