import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  API_GET_OTP,
  API_GET_SIGNUP_OTP,
  API_LOGIN,
  API_LOGOUT,
  API_RESET_PASS,
  API_SUBSCRIPTION,
  API_USER_ROLE,
  API_VERIFY_SIGNUP_OTP,
} from "../api/auth.service";
import parseJwt from "../utils/authUtils";
import axiosInstance, { setupRequestInterceptor } from "../api/axios.instance";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { db } from "../firebase"; // working as initiailising the firestore app

const auth = getAuth();

export const login = createAsyncThunk(
  "auth/login",
  async (credentials, thunkAPI) => {
    try {
      const data = await API_LOGIN(credentials);
      const company = parseJwt(data?.token);

      axiosInstance.defaults.headers.common["authorization"] = data.token;
      const resp = await axiosInstance.get("/firebase/token");

      if (company.childCompanyIds?.length > 0) {
        setupRequestInterceptor({
          companyId: company.companyId,
        });
      }

      const companySubscriptionData = await API_SUBSCRIPTION(
        company?.companyId
      );

      const firebaseToken = resp.data.token;
      await signInWithCustomToken(auth, firebaseToken)
        .then((userCredential) => {
          // const user = userCredential?.user;
        })
        .catch((error) => {
          console.log("Error signing in with custom token: ", error);
        });

      return {
        token: data.token,
        company: company,
        companySubscription: companySubscriptionData,
      };
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
  try {
    const data = await API_LOGOUT();
    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const subscription = createAsyncThunk(
  "auth/subscription ",
  async (customerId, thunkAPI) => {
    try {
      const data = await API_SUBSCRIPTION(customerId);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const signupSendOTP = createAsyncThunk(
  "auth/signupSendOTP",
  async (_data, thunkAPI) => {
    try {
      const data = await API_GET_SIGNUP_OTP(_data);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const signupVerifyOTP = createAsyncThunk(
  "auth/signupVerifyOTP",
  async (_data, thunkAPI) => {
    try {
      const data = await API_VERIFY_SIGNUP_OTP(_data);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const forgetSendOTP = createAsyncThunk(
  "auth/forgetSendOTP",
  async (_data, thunkAPI) => {
    try {
      const resp = await API_GET_OTP(_data);
      return resp;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const resetPass = createAsyncThunk(
  "auth/resetPass",
  async (data, thunkAPI) => {
    try {
      const resp = await API_RESET_PASS(data);
      return resp;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const roleAccess = createAsyncThunk(
  "auth/roleAccess",
  async (thunkAPI) => {
    try {
      const resp = await API_USER_ROLE();
      return resp;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  loading: false,
  subscriptionLoading: false,
  isLogedIn: false,
  user: null,
  userSub: {
    isUserSub: false,
    userSubType: "none",
    plan: "none",
  },
  accesskey: {},
  pause: true,
  conversation: {
    total: 0,
    used: 0,
    remain: 0,
    startTime: "",
    endTime: "",
    paymentType: "prepaid",
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser(state, { payload }) {
      state.isLogedIn = true;
      state.user = { ...payload };
    },
    resetUser(state) {
      state.isLogedIn = false;
      state.user = null;
      state.userSub = {
        isUserSub: false,
        userSubType: "none",
        plan: "none",
      };
      state.conversation = {
        total: 0,
        used: 0,
        remain: 0,
        startTime: "",
        endTime: "",
        paymentType: "prepaid",
      };
    },
    setUserSub(state, { payload }) {
      const { subscription } = payload;
      state.userSub = subscription;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, { payload }) => {
      const { token, company, companySubscription } = payload;
      localStorage.setItem("token", token);
      state.user = { ...company };
      const { subscription, totalConversation, usedConversation } =
        companySubscription;
      const companyConversations = {
        ...state.conversation,
        total: totalConversation,
        used: usedConversation,
        remain: totalConversation - usedConversation,
        startTime: subscription?.startTime,
        endTime: subscription?.endTime,
        paymentType: subscription?.paymentType,
      };

      state.conversation = { ...companyConversations };

      if (subscription && Object.keys(subscription).length != 0) {
        const { monthly, annual, name } = subscription.subscriptiontype;
        state.userSub = {
          ...state.userSub,
          plan: annual ? "annual" : monthly ? "monthly" : "none",
          isUserSub: annual || monthly,
          userSubType: name,
        };
        localStorage.setItem("userSub", JSON.stringify(state.userSub));
      }
      state.isLogedIn = true;
      state.loading = false;
    });
    builder.addCase(login.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(roleAccess.fulfilled, (state, action) => {
      state.accesskey = action.payload.roleDefinition;
      state.pause = false;
    });
    builder.addCase(roleAccess.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(roleAccess.rejected, (state) => {
      state.loading = false;
      state.pause = false;
    });
    builder.addCase(logout.fulfilled, (state) => {
      localStorage.clear();
      state.isLogedIn = false;
      state.user = null;
      state.loading = false;
      state.userSub = {
        isUserSub: false,
        userSubType: "none",
        plan: "none",
      };
      state.conversation = {
        total: 0,
        used: 0,
        remain: 0,
        startTime: "",
        endTime: "",
        paymentType: "prepaid",
      };
      state.accesskey = initialState.accesskey;
      state.pause = initialState.pause;
    });
    builder.addCase(logout.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(logout.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(subscription.fulfilled, (state, { payload }) => {
      const { subscription, totalConversation, usedConversation } = payload;
      const companyConversations = {
        ...state.conversation,
        total: totalConversation,
        used: usedConversation,
        remain: totalConversation - usedConversation,
        startTime: payload?.subscription?.startTime,
        endTime: payload?.subscription?.endTime,
        paymentType: payload?.subscription?.paymentType,
      };
      state.conversation = companyConversations;

      if (subscription != null) {
        const { monthly, annual, name } = subscription.subscriptiontype;
        state.userSub = {
          ...state.userSub,
          plan: annual ? "annual" : monthly ? "monthly" : "none",
          isUserSub: annual || monthly,
          userSubType: name,
        };
        localStorage.setItem("userSub", JSON.stringify(state.userSub));
      }
      state.subscriptionLoading = false;
    });

    builder.addCase(subscription.pending, (state) => {
      state.subscriptionLoading = true;
    });
    builder.addCase(subscription.rejected, (state) => {
      state.subscriptionLoading = false;
    });

    builder.addCase(signupSendOTP.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(signupSendOTP.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(signupSendOTP.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(signupVerifyOTP.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(signupVerifyOTP.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(signupVerifyOTP.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(forgetSendOTP.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(forgetSendOTP.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(forgetSendOTP.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(resetPass.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(resetPass.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(resetPass.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { setUser, resetUser, setUserSub } = authSlice.actions;
export default authSlice.reducer;
