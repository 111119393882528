import styles from "./style.module.css";
import { Text } from "../../components/Text";
import { useState, useRef, useEffect } from "react";
import PaymentGateway from "../../components/PaymentGateway";
import { ReactComponent as Wallet } from "../../assets/svgIcons/wallet.svg";

import { Toast } from "primereact/toast";
import { useSelector, useDispatch } from "react-redux";
import {
  getOverAllCount,
  setAnalyticalRange,
} from "../../reducers/analyticSlice";
import { Skeleton } from "primereact/skeleton";
import { ReactComponent as PrintIcon } from "../../svg/printicon.svg";
import DashboardCard from "../../components/Cards/DashboardCards";
import axiosInstance from "../../api/axios.instance";
import { ReactComponent as DropdownIcon } from "../../assets/svgIcons/dropup.svg";
import { ReactComponent as GreenTickIcon } from "../../assets/svgIcons/greentick.svg";
import { addOneDay, getDate } from "../../utils/datemaker";
import { dateCompiler, getCampaignCustomers } from "../../utils/tableUtils";
import { Calendar } from "primereact/calendar";
import { useNavigate } from "react-router-dom";
import OrderPreview from "../Orders/OrderPreview";
import modulesName from "../../roles/modulesName";
import permissions from "../../roles/permissions";
import PostpaidAccountInfo from "../../components/PostpaidAcctountInfo";
import parseJwt from "../../utils/authUtils";

const StatisticalRecord = () => {
  const [gateway, setGateway] = useState(false);
  const { selectedOrdersList } = useSelector((state) => state.orderTable);
  const { user, conversation, accesskey } = useSelector(
    (state) => state.authenticate
  );
  const { company } = useSelector((state) => state.company);
  const [putLogo, setPutLogo] = useState("");
  const [getLogo, setGetLogo] = useState("");
  const [previewOrder, setPreviewOrder] = useState(false);

  const navigate = useNavigate();
  const toast = useRef(null);
  const dispatch = useDispatch();
  // order printout
  const openPreview = () => {
    setPreviewOrder(true);
  };

  const orderPreviewModal = () => {
    return (
      <OrderPreview
        previewOrder={previewOrder}
        setPreviewOrder={setPreviewOrder}
        toast={toast}
      />
    );
  };

  const handlegateway = () => {
    //setGateway(true);
    navigate("/dashboard/recharge");
  };

  const { overAllCount, loading, dateRange } = useSelector(
    (state) => state.analytic
  );
  const loderSkaleton = () => {
    return (
      <Skeleton
        width={"5rem"}
        height={"3rem"}
        borderRadius={"4px"}
        className="mb-2"
      />
    );
  };
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  // useEffect(
  //   () => {
  //     let tzoffset = new Date().getTimezoneOffset() * 60000;
  //     const date = {
  //       startDate: fromDate
  //         ? new Date(fromDate - tzoffset).toISOString().substring(0, 10)
  //         : fromDate,
  //       endDate: toDate
  //         ? new Date(toDate - tzoffset).toISOString().substring(0, 10)
  //         : toDate,
  //     };
  //     console.log("company", company)

  //     dispatch(getOverAllCount(date))
  //       .unwrap()
  //       .catch(() => {
  //         //show toast from here
  //       });
  //   },
  //    toDate ? [toDate, fromDate] : [toDate]
  // );

  useEffect(() => {
    let tzoffset = new Date().getTimezoneOffset() * 60000;
    const date = {
      startDate: fromDate
        ? new Date(fromDate - tzoffset).toISOString().substring(0, 10)
        : fromDate,
      endDate: toDate
        ? new Date(toDate - tzoffset).toISOString().substring(0, 10)
        : toDate,
    };
    dispatch(getOverAllCount(date))
      .unwrap()
      .catch(() => {
        //show toast from here
      });
  }, []);

  const gatewayPopup = () => {
    return (
      <PaymentGateway
        gateway={gateway}
        setGateway={setGateway}
        category={"TOPUP"}
        toast={toast}
      />
    );
  };
  useEffect(() => {
    editRange("week");
    // var today = new Date()             // uncomment this for by default today's date
    // var yesterday = new Date(today)
    // yesterday.setHours(0, 0, 0, 0)
    // modifyRange([yesterday, null])
    axiosInstance
      .get(`company/${user.companyId}/image`)
      .then((resp) => {
        setGetLogo(resp.data.url);
        setPutLogo(resp.data.src);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      dispatch(setAnalyticalRange({ fromDate: null, toDate: null }));
      setGetLogo("");
      setPutLogo("");
    };
  }, []);
  const prepaidPlan = () => {
    return (
      <div
        className={`w-full lg:py-3 lg:px-4 p-2 ${styles["sub-cont"]}  mt-2 justify-content-center md:w-6  xl:w-5 `}
      >
        <div
          className={`flex flex-row justify-content-evenly align-items-center gap-2 ${styles["subscribe-container"]}`}
        >
          {/* <div className={`active-topup`}> */}
          {/* <div className="mb-2"> */}
          <div>
            <div className="flex flex-row gap-2">
              <div className="flex flex-column align-items-center lg:align-items-start ">
                <span style={{ fontWeight: "400", fontSize: "20px" }}>
                  {conversation?.used}&nbsp;
                </span>
                <Text type={"small-text"} color="grey">
                  Used
                </Text>
              </div>
              <span style={{ fontWeight: "600", fontSize: "20px" }}>|</span>
              <div className="flex flex-column align-items-center lg:align-items-start">
                <span
                  style={{
                    color: "#005068",
                    fontWeight: "600",
                    fontSize: "20px",
                  }}
                >
                  &nbsp;{conversation?.total}
                </span>{" "}
                <Text type={"small-text"}>Total</Text>
              </div>
            </div>
            <div className="mt-2">
              <Text type="small-text" color={"grey"}>
                Last 30 days
              </Text>
            </div>
          </div>

          <div
            className={`${styles["tv-line"]}`}
            style={{ background: "#ECEDF1" }}
          ></div>
          <div className={`${styles["top-up"]}`}>
            <div className="" onClick={handlegateway}>
              <div
                className="w-full flex justify-content-center align-items-center gap-1 lg:gap-2  p-1 py-2 lg:p-2 cursor-pointer flex-nowrap"
                style={{
                  background: "#005068",
                  border: " 0.5px dashed #30B3D5",
                  borderRadius: "4px",
                }}
              >
                <Wallet />
                <Text type={"sub-heading"} style={{ color: "#fff" }}>
                  Click here to recharge
                </Text>
              </div>
            </div>
            {/* <Text type={"sub-heading"}>
                      Last Top up on {dateCompiler(conversation.startTime)}
                  </Text> */}
          </div>
        </div>
      </div>
    );
  };
  const postpaidPlan = () => {
    return (
      <div
        className={`w-full lg:py-3 lg:px-4 p-2 ${styles["sub-cont"]} w-12   mt-2 justify-content-center xl:w-4`}
      >
        <div
          className={`flex flex-row justify-content-evenly align-items-center gap-2 ${styles["subscribe-container"]}`}
        >
          <div className={`active-topup`}>
            <div className="mb-2">
              <Text type="heading">
                <span style={{ fontWeight: "400", fontSize: "20px" }}>
                  <span style={{ color: "#30B3D5" }}>
                    Used :
                    <Text
                      type={"small-text"}
                      color="grey"
                      style={{ fontWeight: "600", fontSize: "0.8rem" }}
                    >
                      Last 30 Days
                    </Text>
                  </span>
                </span>
              </Text>
            </div>
          </div>

          <div className={`${styles["tv-line"]}`}></div>
          <div className={`${styles["top-up"]}`}>
            <Text type="heading">
              <span
                style={{
                  fontWeight: "400",
                  fontSize: "1.5rem",
                  marginBottom: ".8rem",
                }}
              >
                <span>
                  {conversation?.used}
                  <Text
                    type={"small-text"}
                    color="grey"
                    style={{ fontWeight: "600", fontSize: "0.6rem" }}
                  >
                    &nbsp;
                  </Text>
                </span>
              </span>
            </Text>
          </div>
        </div>
      </div>
    );
  };
  const timeline = [
    {
      id: "all",
      name: "Lifetime",
      value: "all",
    },
    {
      id: "month",
      name: "Month",
      value: "month",
    },
    {
      id: "week",
      name: "Last 7 days",
      value: "week",
    },
  ];

  const [range, setRange] = useState("all");
  const [dateValue, setdateValue] = useState(null);

  const editRange = (mode) => {
    let d = new Date();
    switch (mode) {
      case "month":
        setRange("month");
        var old = new Date();
        old.setDate(1);
        setdateValue([old, d]);
        dispatch(
          setAnalyticalRange({
            fromDate: old.toISOString(),
            toDate: d?.toISOString(),
            type: mode,
          })
        );
        return;
      case "week":
        setRange("week");
        var old = new Date();
        old.setDate(old.getDate() - 7);
        setdateValue([old, d]);
        dispatch(
          setAnalyticalRange({
            fromDate: old.toISOString(),
            toDate: d?.toISOString(),
            type: mode,
          })
        );
        return;
      default:
        setRange("all");
        setdateValue(null);
        dispatch(
          setAnalyticalRange({ fromDate: null, toDate: null, type: mode })
        );
        return;
    }
  };
  const modifyRange = (range) => {
    setdateValue(range);
    setRange("custom");
    dispatch(
      setAnalyticalRange({
        fromDate: addOneDay(range[0]?.toISOString()),
        toDate: addOneDay(range[1]?.toISOString()),
      })
    );
  };

  const [showAccDetails, setshowAccDetails] = useState(true);
  return (
    <div>
      <div className={` ${styles["record-wrap-stat"]}`}>
        {gateway && gatewayPopup()}
        {orderPreviewModal()}
        <Toast ref={toast} />
        <div
          className={`w-full px-3 lg:py-3  py-3 flex flex-column justify-content-between align-items-start  w-12 ${styles["record-cont"]}`}
        >
          <div className="flex flex-column-reverse  lg:flex-row justify-content-between w-full">
            <div className="text-xm font-semibold flex flex-row gap-2 align-items-center pr-2">
              <Text type={"heading"} style={{ fontSize: `1.2rem` }}>
                Account Information
              </Text>
              <div
                className={`pl-2 cursor-pointer `}
                onClick={() => {
                  setshowAccDetails((x) => !x);
                }}
              >
                <div className={` ${showAccDetails ? `rotate-180` : `pt-1`}  `}>
                  <DropdownIcon />
                </div>
              </div>
            </div>
            <div className="flex flex-row flex-wrap justify-content-start lg:justify-content-end gap-2 p-1 ">
              {timeline.map((x, index) => {
                return (
                  <div
                    key={index}
                    className={`flex cursor-pointer p-2 ${
                      styles["selected-range"]
                    } ${x.value === range ? `${styles["select-range"]}` : ""} `}
                    onClick={() => {
                      editRange(x.value);
                    }}
                  >
                    {x.name}
                  </div>
                );
              })}
              <div className="flex cursor-pointer flex-row align-items-center">
                <Calendar
                  showIcon={true}
                  placeholder="DD/MM/YYYY"
                  dateFormat="dd/mm/yy"
                  className={`${
                    dateValue !== null ? `${styles["selectedDate"]}` : ``
                  } ${styles["dashboardDate"]} `}
                  value={dateValue}
                  onChange={(e) => modifyRange(e.value)}
                  selectionMode="range"
                  maxDate={new Date()}
                ></Calendar>
                {/* <div className={`block cursor-pointer pl-2 pt-1 cursor-pointer ${!selectedOrdersList?.length?'opacity-60':''}`} onClick={()=>{(selectedOrdersList?.length)&&openPreview()}}>
                      <PrintIcon />
                    </div> */}
              </div>
            </div>
          </div>
          {showAccDetails && (
            <div className="w-12 flex  flex-row justify-content-between align-items-center flex-wrap w-12">
              <div
                className={`w-full flex flex-row justify-content-between align-items-center flex-wrap w-12 sm:w-12 md:w-6 `}
              >
                <div className="flex flex-row  align-items-center">
                  <div className="flex flex-row justify-content-between align-items-center w-6rem h-8rem">
                    <img
                      src={`${getLogo}?v=${new Date().getTime()}`}
                      onError={(e) =>
                        (e.target.src = "./svgIcons/UserIcon.svg")
                      }
                      alt="Company logo"
                      className="logoImg w-8rem h-8rem"
                      style={{ maxHeight: "80px" }}
                    />
                  </div>
                  <div>
                    <div className="text-xm font-semibold">Account Name</div>
                    <div className="font-semibold flex flex-row gap-2  align-items-center h-2rem">
                      <div className="pt-2 text-base">
                        {company?.companyName}
                      </div>
                      <div className="h-1rem ">
                        <GreenTickIcon />
                      </div>
                    </div>
                    <div className="flex flex-row  py-1 ">
                      <div className="font-normal mr-2">Plan validity :</div>
                      <div className=" font-semibold text-base">
                        {dateCompiler(conversation?.endTime)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {conversation?.paymentType === "postpaid"
                ? accesskey[modulesName.PAYMENT]?.includes(
                    permissions.CREATE
                  ) && postpaidPlan()
                : accesskey[modulesName.PAYMENT]?.includes(
                    permissions.CREATE
                  ) && prepaidPlan()}
            </div>
          )}
          {showAccDetails && (
            <div className="flex flex-row justify-content-between md:justify-content-start gap-2 pt-2 flex-wrap">
              {accesskey[modulesName.ORDER]?.includes(permissions.READ) && (
                <div>
                  Total&nbsp;Users&nbsp;&nbsp;
                  <span className="px-3 font-bold">
                    :&nbsp;{overAllCount?.usersCount | 0}
                  </span>
                </div>
              )}
              {/* <div>
              Business&nbsp;Numbers&nbsp;&nbsp;<span className="px-3 font-bold">:&nbsp;{overAllCount?.productCount|0}</span>
            </div> */}
              {accesskey[modulesName.PRODUCT]?.includes(permissions.READ) && (
                <div>
                  Products&nbsp;&nbsp;
                  <span className="px-3 font-bold">
                    :&nbsp;{overAllCount?.productCount | 0}
                  </span>
                </div>
              )}
              {accesskey[modulesName.CUSTOMER]?.includes(permissions.READ) && (
                <div>
                  Customers&nbsp;&nbsp;
                  <span className="px-3 font-bold">
                    :&nbsp;{overAllCount?.customerCount | 0}
                  </span>
                </div>
              )}
            </div>
          )}
          {conversation?.paymentType === "postpaid" && (
            <div className={`w-full mt-4`}>
              <PostpaidAccountInfo />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StatisticalRecord;
