import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getDate } from "../../utils/datemaker";
import CustomPaginator from "../../components/CustomPaginator";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Text } from "../../components/Text";
import {
  getCampaigns,
  changePage,
  changelimit,
} from "../../reducers/campaignSlice";
import DemoCampaigns from "./DemoCampaigns";
import DebounceSearch from "../../components/common/DebounceSearch";
import DateRangePicker from "../../components/common/DateRangePicker";
import "./tabStyles.css";
import { useNavigate } from "react-router";
import { CustomButton } from "../../components/CustomButton";
import { resetSelectedTemplate } from "../../reducers/templateReducer/templateSlice";
import { resetBroadcast } from "../../reducers/ShareMessage/messageSlice";
import { ReactComponent as ClockIcon } from "../../assets/svgIcons/clock.svg";
import { ReactComponent as ClockHoldIcon } from "../../assets/svgIcons/clockhold.svg";
import { resetSelectedProductBlist } from "../../reducers/productTableSlice";
import { DeleteAlert } from "../../components/Alert/DeleteAlert";
import TableActionsContainer from "../../components/CustomTable/TableActionsContainer";
import EllipsisText from "../../components/common/EllipsisText/indix";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { ReactComponent as DocIcon } from "../../assets/docPreview.svg";
import { ReactComponent as VideoPreview } from "../../assets/videoPreview.svg";
import { updatecustomers } from "../../reducers/customerTableSlice";
import { useLayout } from "../../context/LayoutContext";
import { refineMessage } from "../../utils/templateUtils";
import CampaignRowAnalytics from "./CampaignRowAnalytics";
import CampScheduleTime from "../../components/CampScheduleTime";
import ImageGallary from "../../components/common/ImageGallary";
import modulesName from "../../roles/modulesName";
import permissions from "../../roles/permissions";

ChartJS.register(ArcElement, Tooltip, Legend);
const CampaignTable = () => {
  const {
    campaignData,
    campaignLoading,
    page,
    limit,
    campaignType,
    campaignCount,
    selectedCampaignId,
    campaignTab,
  } = useSelector((state) => state.campaign);
  const { userSub, accesskey } = useSelector((state) => state.authenticate);
  const [dateRange, setDateRange] = useState({ fromDate: "", toDate: "" });
  const [inputValue, setInputValue] = useState("");
  const [displayAlertDelete, setDisplayAlertDelete] = useState(false);
  const { toast, setLoading } = useLayout(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const successRateBodyTemplate = (data) => {
    return (
      <div className="flex gap-2 justify-content-between align-items-center w-12 ">
        {data.Type === "ScheduledCampaign" && (
          <div>
            {!(data.hold === "1" || data.hold === true) ? (
              <ClockIcon />
            ) : (
              <ClockHoldIcon />
            )}
          </div>
        )}
        {data.Type === "ScheduledCampaign" && (
          <CampScheduleTime isHold={data?.hold} nextRun={data?.nextRun} />
        )}

        {data.Type == "Campaign" && (
          <CampaignRowAnalytics campaignDetails={data} />
        )}
      </div>
    );
  };

  const handleDateRangeFrom = (newDate) => {
    setDateRange((prev) => {
      return { ...prev, fromDate: newDate };
    });
  };
  const handleDateRangeTo = (newDate) => {
    setDateRange((prev) => {
      return { ...prev, toDate: newDate };
    });
  };

  const mediaBody = (rowData) => {
    const continerStyle = {
      width: "auto",
      maxHeight: "50px",
      aspectRatio: "16/9",
    };
    // const handleImageClick = (e) => {
    //   e.stopPropagation(); // Prevents the row click event from triggering
    //   // Add your logic for handling the image click here
    //   // For example, you can open a modal or navigate to a new page to show the image.
    //   console.log("Image clicked!", rowData);
    // };
    const { campaignType, templateType, variables } = rowData;

    if (campaignType === "product") {
      const { productItems } = rowData;
      let prodImages = [];
      productItems?.forEach((prod) => {
        const images = prod?.images;
        if (images) {
          prodImages = [...prodImages, ...images];
        }
      });
      return (
        <div
          className="flex justify-content-start align-items-center gap-2"
          style={continerStyle}
          onClick={(e) => e.stopPropagation()}
        >
          {prodImages.length > 0 ? <ImageGallary images={prodImages} /> : ""}
        </div>
      );
    } else {
      switch (templateType?.toLowerCase()) {
        case "video":
          return (
            <div className="flex flex-row align-items-center align-content-center  justify-content-between ">
              <div
                className={`flex flex-row align-items-center align-content-center  justify-content-center surface-100 cthumbnailContainer`}
              >
                <div className="flex flex-row justify-content-center align-items-center align-content-center surface-0">
                  <div
                    style={{
                      objectFit: "cover",
                      width: "60px",
                      height: "100%",
                      background: "#000",
                      top: 0,
                      left: 20,
                      position: "absolute",
                    }}
                  >
                    <VideoPreview
                      width="auto w-12"
                      height="30"
                      className="absolute"
                      style={{ top: 10, left: 0 }}
                    />
                  </div>
                </div>
                <Text>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>
              </div>
            </div>
          );
        case "image":
          return (
            <div
              className="flex justify-content-start z-5 "
              style={continerStyle}
              // onClick={(e)=>{handleTemplateImageClick(e,variables?.mediaUrl)}}
              onClick={(e) => e.stopPropagation()}
            >
              <ImageGallary images={variables} />
              <Text>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>
            </div>
          );
        case "document":
          return (
            <div className="flex justify-content-start " style={continerStyle}>
              <div className="flex justify-content-center pt-2 w-6 ">
                <a
                  href={`${variables?.mediaUrl}`}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  target="_blank"
                >
                  <DocIcon width="35" height="35" />
                </a>
              </div>
              {/* <iframe
                frameborder="0"
                src={`${variables?.mediaUrl}`}
                style={{
                  maxWidth: "100%",
                  overflow: "hidden",
                  // maxHeight: "120px",
                  height: "100%",
                }}
              /> */}
              <Text>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>
            </div>
          );
        default:
          return (
            <div
              className="flex justify-content-start align-items-end gap-2"
              style={continerStyle}
            >
              <div className="flex justify-content-center w-6 pb-2">
                <Text>_</Text>
              </div>
              <Text>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>
            </div>
          );
      }
    }
  };

  const handleCampaignSelect = (campaign) => {
    if (campaign.Type === "ScheduledCampaign") {
      navigate(`./s-${campaign.id}`);
    } else navigate(`./${campaign.id}`);
  };

  useEffect(() => {
    if (userSub.isUserSub) {
      let tzoffset = new Date().getTimezoneOffset() * 60000;
      const { fromDate, toDate } = dateRange;

      let paginationData = {
        page: page,
        limit: limit,
        campaignType,
        startDate: fromDate
          ? new Date(fromDate - tzoffset).toISOString().substring(0, 10)
          : fromDate,
        endDate: toDate
          ? new Date(toDate - tzoffset).toISOString().substring(0, 10)
          : toDate,
        globalFilterValue: inputValue,
        schedule: campaignTab === "schedule",
      };
      if ((fromDate && !toDate) || (!fromDate && toDate)) return;
      else {
        dispatch(getCampaigns(paginationData))
          .unwrap()
          .catch((err) => {
            //show toast here
          });
      }
    }
  }, [
    page,
    limit,
    inputValue,
    dateRange,
    userSub.isUserSub,
    displayAlertDelete,
  ]);

  const deleteModule = () => {
    return (
      <DeleteAlert
        item="schedule"
        displayAlertDelete={displayAlertDelete}
        setDisplayAlertDelete={setDisplayAlertDelete}
        toast={toast}
      />
    );
  };

  const runcampaign = () => {
    dispatch(resetBroadcast());
    dispatch(resetSelectedTemplate());
    dispatch(updatecustomers({ selectedCust: [] }));
    dispatch(resetSelectedProductBlist());
    navigate("/runcampaign/new");
  };

  useEffect(() => {
    setLoading(campaignLoading);
  }, [campaignLoading]);
  return (
    <div className="cp-10">
      {displayAlertDelete && deleteModule()}
      <TableActionsContainer>
        <div className="flex justify-content-between align-items-center flex-wrap gap-3">
          <div className="flex justify-content-between gap-3 flex-grow-1 align-items-center">
            <Text type={"heading"}>Campaign List</Text>
            {accesskey[modulesName.CAMPAIGN]?.includes(permissions.CREATE) && (
              <div className="block lg:hidden xl:hidden">
                <CustomButton
                  varient="filled"
                  label={"Start New Campaign"}
                  icon={"pi pi-plus"}
                  onClick={runcampaign}
                />
              </div>
            )}
          </div>

          <div className="flex gap-3 flex-wrap">
            <DateRangePicker
              fromDate={dateRange.fromDate}
              toDate={dateRange.toDate}
              handleFromDate={handleDateRangeFrom}
              handleToDate={handleDateRangeTo}
            />
            <DebounceSearch
              placeholder={"Search Campaign"}
              inputValue={inputValue}
              handleInputChange={(value) => setInputValue(value)}
            />
            {accesskey[modulesName.CAMPAIGN]?.includes(permissions.CREATE) && (
              <div className="hidden lg:block xl:block">
                <CustomButton
                  varient="filled"
                  label={"Start New Campaign"}
                  icon={"pi pi-plus"}
                  onClick={runcampaign}
                />
              </div>
            )}
          </div>
        </div>
      </TableActionsContainer>
      {userSub.isUserSub && userSub.userSubType === "standard" && (
        <DemoCampaigns />
      )}

      {userSub.isUserSub && (
        <>
          <div className="mt-3">
            <DataTable
              value={campaignData}
              key="id"
              selectionMode="single"
              selection={selectedCampaignId}
              onSelectionChange={(e) => handleCampaignSelect(e.value)}
              className="skalebot-table"
              responsiveLayout="scroll"
              emptyMessage="No data found."
              columnResizeMode="expand"
              globalFilterFields={["date"]}
            >
              <Column
                field="campaignName"
                header={<span>Campaign&nbsp;Name</span>}
                bodyStyle={{
                  textTransform: "capitalize",
                }}
                style={{ maxWidth: "140px" }}
                body={(rowData) => <EllipsisText text={rowData.campaignName} />}
              />
              <Column
                field="conversationsCount"
                className="text-sm font-normal"
                style={{
                  minWidth: "80px",
                  maxWidth: "100px",
                }}
                header={"Conversations"}
              />
              <Column
                field="createdAt"
                header={<span>Date&nbsp;&&nbsp;Time</span>}
                className="text-sm font-normal"
                style={{ minWidth: "110px" }}
                body={(rowData) => {
                  let date = getDate(rowData.createdAt)?.split(",");
                  return (
                    <div className="flex flex-column gap-2">
                      <div>{date[0]}</div>
                      <div>{date[1]}</div>
                    </div>
                  );
                }}
                bodyStyle={{ textTransform: "uppercase" }}
              />
              <Column
                field="message"
                header={"Message"}
                bodyStyle={{ minWidth: "200px", maxWidth: "250px" }}
                body={(rowData) => {
                  let message = refineMessage(
                    rowData?.variables,
                    rowData?.message
                  );
                  return <EllipsisText text={message} />;
                }}
                style={{ minWidth: "40%" }}
              />

              <Column
                header="Campaign Resource"
                body={mediaBody}
                headerStyle={{ whiteSpace: "nowrap" }}
                style={{ width: "30ch" }}
              ></Column>
              <Column
                header={() => <div className="pl-4">Status</div>}
                // field="successRate"
                style={{ width: "25ch" }}
                body={successRateBodyTemplate}
              ></Column>
            </DataTable>
          </div>

          <div className="mt-3">
            <TableActionsContainer>
              <div className="flex  justify-content-end">
                <CustomPaginator
                  page={page}
                  limit={limit}
                  changelimit={changelimit}
                  totalRecords={campaignCount}
                  changePage={changePage}
                />
              </div>
            </TableActionsContainer>
          </div>
        </>
      )}
    </div>
  );
};

export default CampaignTable;
