import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axiosInstance from "../../api/axios.instance";
import { Dropdown } from "primereact/dropdown";
import { tempCategories } from "../../utils/templateUtils";
import { CustomButton } from "../CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { updateNodePayload } from "../../reducers/canvas/canvasSlice";
import { v4 as uuidv4 } from "uuid";

const TemplateNodeInput = ({ onCancel = () => {}, onSave = () => {} }) => {
  const [templateList, setTemplateList] = useState([]);
  const [selecteTemplate, setTemplate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState(null);
  //const [searchKey, setSeachKey] = useState(null);
  const { selectedNode, nodes } = useSelector((state) => state.canvas);

  const dispatch = useDispatch();

  const isValidTemplate = (data) => {
    const { status } = data;
    return status == "APPROVED";
  };

  const rowClassName = (data) => {
    return isValidTemplate(data) ? "" : "not-selectable";
  };

  const fetchNextTemplates = async (category) => {
    try {
      var queryParams = `page=0&limit=100000&isActive=1`;
      // if (search) queryParams += `&global=${search}`;
      if (category) queryParams += `&category=${category}`;
      setLoading(true);
      const { data } = await axiosInstance.get(
        `/whatsappTemplate/gupshup?${queryParams}`
      );
      setTemplateList(data.rows);
      if (selectedNode && selectedNode.data.templateId) {
        let findSelectedTemplate = data?.rows.find(
          (item) => item.templateId == selectedNode.data.templateId
        );
        if (findSelectedTemplate) {
          setTemplate(findSelectedTemplate);
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleTemplateSelect = (templateData) => {
    setTemplate(templateData);

    let templateNodeData = {
      templateId: null,
      options: [],
      content: {
        header: "",
        data: "",
      },
    };

    if (templateData) {
      const { templateId, containerMeta } = templateData;
      const { data, buttons, header } = JSON.parse(containerMeta);

      templateNodeData = {
        ...templateNodeData,
        templateId: templateId,
        content: {
          ...templateNodeData.content,
          header,
          data,
        },
        options: buttons?.map((item) => {
          return {
            id: uuidv4(),
            title: item.text || "",
            type: "text",
            postbackText: "",
          };
        }),
      };
    } else {
      //no action
    }

    dispatch(
      updateNodePayload({
        selectedNodeId: selectedNode?.id,
        updatedData: { ...templateNodeData },
      })
    );
  };

  const isTemplateEditable = () => nodes.length < 2;

  useEffect(() => {
    fetchNextTemplates(category);
  }, [category]);

  return (
    <>
      <div className="m-2">
        <Dropdown
          value={category}
          onChange={(e) => setCategory(e.value)}
          options={tempCategories}
          optionLabel="key"
          showClear
          placeholder="Select a Category"
          className="w-full mb-2"
        />

        {/* <DebounceSearch
          placeholder={"Search Product"}
          inputValue={searchKey}
          handleInputChange={(value) => setSeachKey(value)}
        /> */}

        <DataTable
          value={templateList}
          selection={selecteTemplate}
          onSelectionChange={(e) => handleTemplateSelect(e.value)}
          dataKey="id"
          className="skalebot-table mt-3"
          rowClassName={rowClassName}
          removableSort
          loading={loading}
          scrollHeight="450px"
          isDataSelectable={isTemplateEditable}
        >
          <Column
            selectionMode="single"
            headerStyle={{ width: "3rem" }}
          ></Column>

          <Column
            field="elementName"
            header="Name"
            sortable
            className="text-sm font-normal"
            style={{ textTransform: "capitalize" }}
          />
        </DataTable>
      </div>

      <div className=" mt-6 mb-3 flex justify-content-end gap-3 align-items-center">
        <CustomButton varient="outline" label={"Cancel"} onClick={onCancel} />
        {isTemplateEditable() && (
          <CustomButton varient="filled" label={"Save"} onClick={onSave} />
        )}
      </div>
    </>
  );
};

export default TemplateNodeInput;
